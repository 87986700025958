import { combineReducers } from 'redux';
import classes from './classes';
import subjects from './subjects';
import exams from './exams';
import codingLanguages from './codingLanguages';
import countries from './countries';
import states from './states';
import cities from './cities';
import lgas from './lgas';
import codingTutorReq from './codingTutorReq';
import codingTutorReg from './codingTutorReg';
import schoolCodingReq from './schoolCodingReq';
import subjectTutorReq from './subjectTutorReq';
import subjectTutorReg from './subjectTutorReg';
import sendEmailForCode from './sendEmailForCode';
import verifyCode from './verifyCode';
import tutorFinalReg from './tutorFinalReg';
import tutors from './tutors';
import singleTutor from './singleTutor';
import searchTutors from './searchTutors';

export default combineReducers({
    classes, 
    subjects,
    exams,
    codingLanguages,
    countries,
    states,
    cities,
    lgas,
    codingTutorReq,
    codingTutorReg,
    schoolCodingReq,
    subjectTutorReq,
    subjectTutorReg,
    sendEmailForCode,
    verifyCode,
    tutorFinalReg,
    tutors,
    singleTutor,
    searchTutors
})