import * as React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import SubjectReqFormPage from '../components/SubjectReqFormPage';

const SubjectReqForm = () => {   

  return (
    
    <div className="page-container">
        <Header className='header bkg-green-light' />
        <SubjectReqFormPage />
        <Footer />
    </div>
    
  );
};

export default SubjectReqForm;