import * as React from 'react';

import Logo from '../assets/img/brand/logo.svg';

import { TextField, Button, Icon } from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin, faTwitter, faInstagram, faFacebookSquare } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {

  return (

    <div className="footer">

      <div className='innerWrap'>

        <div className='colSec'>
          <div className='footerLogo'>
            <img className="logo" src={Logo} alt="" />
          </div>
          <div className='subscribe'>
            <div className='hdr md'>Subscribe to our Newsletter</div>
            <div className='text md'>Get information and updates that will help you stay ahead of the curve.</div>
            <div className='inputWrap'>
              <TextField
                fullWidth
                size="small"
                id="newsletterEmail"
                name="newsletterEmail"
                type="email"
                placeholder="Enter email address"
                variant="outlined"
              />
              <Button
                fullWidth
                type="button"
                variant="contained"
                sx={{
                  width: 'auto',
                  fontSize: '0.9rem',
                  fontWeight: '700',
                  padding: "7.5px 35px",
                  textTransform: "capitalize",
                  backgroundColor: "#01C38E",
                  ':hover': {
                    backgroundColor: '#01C38E',
                    opacity: '0.8'
                  },
                }}
              >Subscribe</Button>
            </div>
          </div>
        </div>

        <div className='colSec'>

          <div className='footerMenu'>
            <div className='hdr sm'>Site Links</div>
            <ul>
              <li>
                <a href="/tutors">Home tutors</a>
              </li>
              <li>
                <a href="/coding-robotics">Coding and Robotics</a>
              </li>
              <li>
                <a href="/school-management-system">School Management System</a>
              </li>
              <li>
                <a href="/teaching-resources">Teaching Resources</a>
              </li>
              <li>
                <a href="/tutors">Tutor Sign Up</a>
              </li>
            </ul>
          </div>

          <div className='footerMenu'>
            <div className='hdr sm'>Other Links</div>
            <ul>
              <li>
                <a href="/contact">Contact Us</a>
              </li>
              <li>
                <a href="/about">About Us</a>
              </li>
              <li>
                <a href="">FAQs</a>
              </li>
              <li>
                <a href="/privacy-policy">Privacy Policy</a>
              </li>
              <li>
                <a href="">Terms of Service</a>
              </li>
            </ul>
          </div>

          <div className='footerMenu'>
            <div className='hdr sm'>Social Media</div>
            <ul>
              <li>
                <a href="">
                  <Icon>
                    <FontAwesomeIcon icon={faTwitter} />
                  </Icon>
                  <span>Twitter</span>
                </a>
              </li>
              <li>
                <a href="">
                  <Icon>
                    <FontAwesomeIcon icon={faFacebookSquare} />
                  </Icon>
                  <span>Facebook</span>
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/nigenius/" target='_blank'>
                  <Icon>
                    <FontAwesomeIcon icon={faInstagram} />
                  </Icon>
                  <span>Instagram</span>
                </a>
              </li>
              <li>
                <a href="">
                  <Icon>
                    <FontAwesomeIcon icon={faLinkedin} />
                  </Icon>
                  <span>LinkedIn</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

  );
};

export default Footer;