import { GET_CODING_LANGUAGES, GET_CODING_LANGUAGES_ERROR } from '../actions/types';

const initialState = {
    data: null,
    loading: true,
    error: ""
}

export default function(state = initialState, action) {

    const { type, payload } = action;

    switch(type) {

        case GET_CODING_LANGUAGES:
        return {
            ...state,
            data: payload.data,
            loading: false
        }

        case GET_CODING_LANGUAGES_ERROR:
        return {
            ...state,
            error: payload.error,
            loading: false
        }

        default:
        return state;
    }
   
}

