import * as React from 'react';
import { useState, useEffect } from "react";
import { createBrowserHistory } from 'history';
import { useDispatch, useSelector } from 'react-redux';
import { getClasses, getSubjects, getStates, getCities, getLgas, sendSchoolCodingReq } from '../actions/formdata';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useFormik } from "formik";
import * as yup from "yup";

import { TextField, MenuItem, Chip, Autocomplete, Checkbox, Box, Button, createFilterOptions, FormControlLabel } from "@mui/material";

import BackBtn from "../assets/img/brand/back-arrow.svg";

import { Countries } from '../content';
import { Languages } from '../content';
import { Exams } from '../content';
import { ClassCats } from '../content';
import { Days } from '../content';
import { Times } from '../content';
import { Goals } from '../content';



const validationSchema = yup.object({
    schoolName: yup.string().required("This is a required field"),
    email: yup.string().email("Please insert a valid email").required("This field is required"),
    phoneNo: yup.string().required("This is a required field"),
    state: yup.string().required("This is a required field"),
    city: yup.string().required("This is a required field"),
    address: yup.string().required("This is a required field"),
    schoolPopulation: yup.string().required("This is a required field"),
    tutoringService: yup.string().required("This is a required field"),
    tutoringMode: yup.string().required("This is a required field"),
    contactPersonName: yup.string().required("This is a required field"),
    contactPersonPhoneNo: yup.string().required("This is a required field"),
    message: yup.string(),
    awarenessMedium: yup.string(),
    referral: yup.string(),
});

const languages = Languages;
const exams = Exams;
const classCats = ClassCats;
const days = Days;
const times = Times;
const goals = Goals;


const CodingSchoolReqFormPage = () => { 

    //API CALLS

    const dispatch = useDispatch();
    //const stateValues = useSelector((state) => state);
    useEffect(() => {
        //console.log(stateValues);
        dispatch(getClasses())
        dispatch(getSubjects())
        dispatch(getStates('NG'))
        dispatch(getCities('NG', 'LA'));
        dispatch(getLgas('Lagos'));
    }, []);

    const classes = useSelector((state) => state.classes);
    let classesOptions = [];
    if(!classes.loading) {
        //console.log(classes)
        classesOptions = classes.data.sort((a, b) => parseFloat(a.orderNo) - parseFloat(b.orderNo));
    }

    const subjectOptions = useSelector((state) => state.subjects.data);

    const stateOptions = useSelector((state) => state.states.data);

    const cityOptions = useSelector((state) => state.cities.data);

    //API CALLS END

    const formik = useFormik({
        initialValues: {
            schoolName: "",
            email: "",
            phoneNo: "",
            state: "",
            city: "",
            address: "",
            schoolPopulation: "",
            tutoringService: "",
            tutoringTarget: "",
            contactPersonName: "",
            contactPersonPhoneNo: "",            
            message: "",
            awarenessMedium: "",
            referral: ""
        },
        onSubmit: (values) => {
            // console.log(formik.values);
        },
        validationSchema: validationSchema
    });

    let flagStyles = { marginRight: '10px'};

    const [valid, setValid] = React.useState(false);
    const checkValidity = () => {
        // do what you want like on submit
        if (formik.isValid) {
            setValid(true);
        }
        else if(!formik.isValid) {
            setValid(false);
        }     
    }

    const [languageVal, setLanguageVal] = useState([]);
    const onLanguageValDelete = (title) => () => {
        setLanguageVal((languageVal) => languageVal.filter((v) => v.title !== title));
    };

    const [lessonDays, setLessonDays] = useState([]);
    const onLessonDaysDelete = (title) => () => {
        setLessonDays((lessonDays) => lessonDays.filter((v) => v.title !== title));
    };

    const [classCat, setClassCat] = useState([]);
    const onClassCatDelete = (title) => () => {
        setClassCat((classCat) => classCat.filter((v) => v.title !== title));
    };

    const [lessonTimes, setLessonTimes] = useState([]);
    const onLessonTimesDelete = (title) => () => {
        setLessonTimes((lessonTimes) => lessonTimes.filter((v) => v.title !== title));
    };

    const [lessonGoals, setLessonGoals] = useState([]);
    const onLessonGoalsDelete = (title) => () => {
        setLessonGoals((lessonGoals) => lessonGoals.filter((v) => v.title !== title));
    };

    const [phone, setPhoneValue] = useState(null);

    const filter = createFilterOptions();
    const [stateValue, setStateValue] = useState(null);
    const [cityValue, setCityValue] = useState(null);

    const browserHistory = createBrowserHistory();

    //How did you hear about us field missing

    const submitForm = () => {
        let formData = {
            schoolName: formik.values.schoolName,
            email: formik.values.email,
            phone: Number(phone),
            state: stateValue.name && stateValue.name,
            city: cityValue.name && cityValue.name,
            schoolAddress: formik.values.address,
            classCategories: classCat.map( function( el ){ return el.title;}),
            schoolStudentPopulation: formik.values.schoolPopulation,
            tutorService: formik.values.tutoringService && formik.values.tutoringService,
            modeOfTeaching: formik.values.tutoringMode && formik.values.tutoringMode,
            schoolContactName: formik.values.contactPersonName && formik.values.contactPersonName,
            schoolContactPhone: Number(phone),
            extraInfo: formik.values.message,
            referredBy: formik.values.referral,
            tutorType: "coding and robotics"
        }

        dispatch(sendSchoolCodingReq(formData));

        formik.values.schoolName = "";
        formik.values.email = "";
        setPhoneValue("");
        setStateValue("");
        setCityValue("");
        formik.values.address = "";
        setClassCat([]);
        formik.values.schoolPopulation = "";
        formik.values.tutoringService = "";
        formik.values.tutoringMode = "";
        formik.values.contactPersonName = "";
        formik.values.referral = "";
        formik.values.message = "";
    }

    return (
    
        <div className="page-content bkg-green-light pgx pgy">

            {/* <div className='blur1'></div>
            <div className='blur2'></div> */}

            <div onClick={browserHistory.back} className='backBtn'>
                <img src={BackBtn} alt="" /><span>Go Back</span>
            </div>

            {/* <div className='row justify-content-center align-items-center'>
                <div className='col-lg-8'>
                    <div className='infoStrip'>
                        <img src={InfoIcon} alt="" />
                        <span>If you have previously completed stage one click the button below</span>
                        <a href=''>Continue to stage 2</a>
                    </div>
                </div>
            </div> */}


            <div className='row formCont no-pad justify-content-center align-items-center'>
                <div className='col-lg-7'>
                    {/* <div className='sec-title clr-orange mb-lg-1'>Stage 1</div> */}
                    <div className='hdr xl mb-lg-2'>Request for coding & robotics tutors for schools</div>
                    <p className='mt-3 mt-lg-3'>Simply fill this form to request tutors for your school</p>

                    <div className='formWrap'>
                        <form>
                            <div className="fieldRow">
                                <div className='fieldHld'>
                                    <div className='label req'>Name of School</div>
                                    <TextField
                                        required
                                        fullWidth
                                        className='textField'
                                        id="schoolName"
                                        name="schoolName" 
                                        type="text" 
                                        //placeholder="First Name"
                                        value={formik.values.schoolName}
                                        onBlur={checkValidity}
                                        onChange={formik.handleChange}
                                        onFocus={formik.handleBlur}
                                        variant="outlined"
                                        error={formik.touched.schoolName && Boolean(formik.errors.schoolName)}
                                        helperText={formik.touched.schoolName && formik.errors.schoolName}
                                        // sx={{
                                        //     '& .MuiInputBase-root.MuiInput-root:before': {
                                        //         borderBottom: '1px solid rgba(255, 255, 255, 0.74)'
                                        //     }
                                        // }}
                                    />
                                </div>

                            </div>

                            <div className='fieldRow'>
                                <div className='fieldHld'>
                                    <div className='label req'>Email</div>
                                    <TextField
                                        required
                                        fullWidth
                                        className='textField'
                                        id="email"
                                        name="email" 
                                        type="email" 
                                        //placeholder='Email'
                                        value={formik.values.email}
                                        onBlur={checkValidity}
                                        onChange={formik.handleChange}
                                        onFocus={formik.handleBlur}
                                        variant="outlined"
                                        error={formik.touched.email && Boolean(formik.errors.email)}
                                        helperText={formik.touched.email && formik.errors.email}
                                    />
                                </div>

                                <div className='fieldHld'>
                                    <div className='label req'>Phone Number (Whatsapp)</div>
                                    <PhoneInput
                                        country={'ng'}
                                        value={phone}
                                        id="phoneNo"
                                        onChange={phone => setPhoneValue(phone)}
                                    />
                                    {/* <TextField
                                        required
                                        fullWidth
                                        className='textField'
                                        id="phoneNo"
                                        name="phoneNo" 
                                        type="text"
                                        //placeholder='Phone Number' 
                                        value={formik.values.phoneNo}
                                        onBlur={checkValidity}
                                        onChange={formik.handleChange}
                                        onFocus={formik.handleBlur}
                                        variant="outlined"
                                        error={formik.touched.phoneNo && Boolean(formik.errors.phoneNo)}
                                        helperText={formik.touched.phoneNo && formik.errors.phoneNo}
                                    /> */}
                                </div>
                            </div>

                            <div className='fieldRow'>
                                <div className='fieldHld auto'>
                                    <div className='label req'>What class categories does your school cover ?</div>
                                    <Box>
                                        <Autocomplete
                                            multiple
                                            options={classCats}
                                            defaultValue={[classCats[1]]}
                                            getOptionLabel={(option) => option.title}
                                            value={classCat}
                                            onChange={(e, newValue) => setClassCat(newValue)}
                                            renderTags={() => null}
                                            renderInput={(params) => (
                                                <TextField {...params} variant="outlined" placeholder="You can pick multiple class options" />
                                            )}
                                            sx={{
                                                '& .MuiSvgIcon-root': {
                                                    color: "rgba(0, 0, 0, 0.74)"
                                                },
                                                '& .MuiInputBase-root': {
                                                    backgroundColor: '#FFF',
                                                    border: '1px solid rgba(0, 0, 0, 0.4)'
                                                },
                                            }}
                                        />
                                        <Box
                                            mt={2}
                                            sx={{
                                                '& > :not(:last-child)': { mr: 1 },
                                                '& > *': { mr: 1 },
                                            }}
                                        >
                                            {classCat.map((v) => (
                                                <Chip 
                                                    key={v.title} 
                                                    label={v.title} 
                                                    onDelete={onClassCatDelete(v.title)}
                                                    sx={{
                                                        '&.MuiButtonBase-root.MuiChip-root': {
                                                            backgroundColor: '#F6FFFC',
                                                            border: '0.1rem solid #01C38E',
                                                            marginBottom: '0.3rem'
                                                        },
                                                        '& .MuiSvgIcon-root': {
                                                            color: '#01C38E',
                                                        },
                                                    }}
                                                />
                                            ))}
                                        </Box>
                                    </Box>
                                </div>
                            </div>

                            <div className='fieldRow'>

                                <div className='fieldHld'>
                                    <div className='label req'>State of Residence</div>
                                    <Autocomplete
                                        value={stateValue}
                                        onChange={(event, newValue) => {
                                            // console.log(newValue);
                                            if (typeof newValue === 'string') {
                                                setStateValue({
                                                    title: newValue,
                                                });
                                            } else if (newValue && newValue.inputValue) {
                                                // Create a new value from the user input
                                                setStateValue({
                                                    title: newValue.inputValue,
                                                });
                                            } else {
                                                setStateValue(newValue);
                                            }
                                            dispatch(getCities('NG', newValue.isoCode));
                                        }}
                                        filterOptions={(stateOptions, params) => {
                                            const filtered = filter(stateOptions, params);

                                            const { inputValue } = params;
                                            // Suggest the creation of a new value
                                            const isExisting = stateOptions.some((state) => inputValue === state.name);
                                            // if (inputValue !== '' && !isExisting) {
                                            //     filtered.push({
                                            //         inputValue,
                                            //         title: `Add "${inputValue}"`,
                                            //     });
                                            // }
                                            return filtered;
                                        }}
                                        selectOnFocus
                                        clearOnBlur
                                        handleHomeEndKeys
                                        id="state-selection"
                                        options={stateOptions}
                                        getOptionLabel={(state) => {
                                            // Value selected with enter, right from the input
                                            if (typeof state === 'string') {
                                                return state;
                                            }
                                            // Add "xxx" option created dynamically
                                            if (state.inputValue) {
                                                return state.inputValue;
                                            }
                                            // Regular option
                                            return state.name;
                                        }}
                                        renderOption={(props, state) =><li {...props}>{state.name}</li>}
                                        sx={{ width: '100%' }}
                                        freeSolo
                                        renderInput={(params) => (
                                            <TextField
                                                variant="outlined"
                                                id="state"
                                                name="state" 
                                                {...params}
                                                error={formik.touched.state && Boolean(formik.errors.state)}
                                                helperText={formik.touched.state && formik.errors.state}
                                                sx={{
                                                    '& .MuiSvgIcon-root': {
                                                        color: "rgba(0, 0, 0, 0.74)"
                                                    },
                                                    '& .MuiInputBase-root': {
                                                        backgroundColor: '#FFF',
                                                        border: '1px solid rgba(0, 0, 0, 0.5)'
                                                    },
                                                    '& .MuiSelect-select': {
                                                        border: '1px solid rgba(0, 0, 0, 0.3)'
                                                    }
                                                }}
                                            />
                                        )}
                                    />

                                </div>

                                <div className='fieldHld'>
                                    <div className='label req'>City</div>
                                    <Autocomplete
                                        value={cityValue}
                                        onChange={(event, newValue) => {
                                            // console.log(newValue);
                                            if (typeof newValue === 'string') {
                                                setCityValue({
                                                    name: newValue,
                                                });
                                            } else if (newValue && newValue.inputValue) {
                                                // Create a new value from the user input
                                                setCityValue({
                                                    name: newValue.inputValue,
                                                });
                                                // console.log(cityValue);
                                            } else {
                                                setCityValue(newValue);
                                            }
                                            
                                        }}
                                        filterOptions={(cityOptions, params) => {
                                            const filtered = filter(cityOptions, params);

                                            const { inputValue } = params;
                                            // Suggest the creation of a new value
                                            //const isExisting = cityOptions.some((city) => inputValue === city.name);
                                            // if (inputValue !== '' && !isExisting) {
                                            //     filtered.push({
                                            //         inputValue,
                                            //         title: `Add "${inputValue}"`,
                                            //     });
                                            // }
                                            return filtered;
                                        }}
                                        onBlur={(e) => {
                                            setCityValue({
                                                name: e.target.value
                                            });
                                        }}
                                        selectOnFocus
                                        //clearOnBlur
                                        handleHomeEndKeys
                                        id="city-selection"
                                        options={cityOptions}
                                        getOptionLabel={(city) => {
                                            // Value selected with enter, right from the input
                                            if (typeof city === 'string') {
                                                return city;
                                            }
                                            // Add "xxx" option created dynamically
                                            if (city.inputValue) {
                                                return city.inputValue;
                                            }
                                            // Regular option
                                            // const isExisting = cityOptions.some((city) => city.inputValue === city.name);
                                            // if (city.inputValue != '' && !isExisting) {
                                            //     return city.title
                                            // }
                                            // else {
                                            //     return city.name;
                                            // }
                                            return city.name;
                                        }}
                                        renderOption={(props, city) =><li {...props}>{city.name}</li>}
                                        sx={{ width: '100%' }}
                                        freeSolo
                                        renderInput={(params) => (
                                            <TextField
                                                variant="outlined"
                                                id="city"
                                                name="city" 
                                                {...params}
                                                error={formik.touched.city && Boolean(formik.errors.city)}
                                                helperText={formik.touched.city && formik.errors.city}
                                                sx={{
                                                    '& .MuiSvgIcon-root': {
                                                        color: "rgba(0, 0, 0, 0.74)"
                                                    },
                                                    '& .MuiInputBase-root': {
                                                        backgroundColor: '#FFF',
                                                        border: '1px solid rgba(0, 0, 0, 0.5)'
                                                    },
                                                    '& .MuiSelect-select': {
                                                        border: '1px solid rgba(0, 0, 0, 0.3)'
                                                    }
                                                }}
                                            />
                                        )}
                                    />

                                </div>

                            </div>

                            <div className="fieldRow">
                                <div className='fieldHld'>
                                    <div className='label req'>Address</div>
                                    <TextField
                                        required
                                        fullWidth
                                        className='textField'
                                        id="address"
                                        name="address" 
                                        type="text" 
                                        value={formik.values.address}
                                        onBlur={checkValidity}
                                        onChange={formik.handleChange}
                                        onFocus={formik.handleBlur}
                                        variant="outlined"
                                        error={formik.touched.address && Boolean(formik.errors.address)}
                                        helperText={formik.touched.address && formik.errors.address}
                                        // sx={{
                                        //     '& .MuiInputBase-root.MuiInput-root:before': {
                                        //         borderBottom: '1px solid rgba(255, 255, 255, 0.74)'
                                        //     }
                                        // }}
                                    />
                                </div>

                            </div>

                            <div className='fieldRow'>

                                <div className='fieldHld'>
                                    <div className='label req'>School Student Population</div>
                                    <TextField
                                        required
                                        fullWidth
                                        select
                                        variant="outlined"
                                        id="schoolPopulation"
                                        name="schoolPopulation" 
                                        value={formik.values.schoolPopulation}
                                        onBlur={checkValidity}
                                        onChange={formik.handleChange}
                                        onFocus={formik.handleBlur}
                                        error={formik.touched.schoolPopulation && Boolean(formik.errors.schoolPopulation)}
                                        helperText={formik.touched.schoolPopulation && formik.errors.schoolPopulation}
                                        sx={{
                                            '& .MuiSvgIcon-root': {
                                                color: "rgba(0, 0, 0, 0.74)"
                                            },
                                            '& .MuiInputBase-root': {
                                                backgroundColor: '#FFF',
                                            },
                                            '& .MuiSelect-select': {
                                                border: '1px solid rgba(0, 0, 0, 0.3)'
                                            }
                                        }}
                                    >
                                        <MenuItem key={"1-50"} value={"1-50"}>1-50</MenuItem>
                                        <MenuItem key={"51-100"} value={"51-100"}>51-100</MenuItem>
                                        <MenuItem key={"101-300"} value={"101-300"}>101-300</MenuItem>
                                        <MenuItem key={"301-500"} value={"301-500"}>301-500</MenuItem>
                                        <MenuItem key={"500 and above"} value={"500 and above"}>500 and above</MenuItem>
                                    </TextField> 
                                </div>

                                <div className='fieldHld'>
                                    <div className='label req'>What Tutoring service do you require ?</div>
                                    <TextField
                                        required
                                        fullWidth
                                        select
                                        variant="outlined"
                                        id="tutoringService"
                                        name="tutoringService" 
                                        value={formik.values.tutoringService}
                                        onBlur={checkValidity}
                                        onChange={formik.handleChange}
                                        onFocus={formik.handleBlur}
                                        error={formik.touched.tutoringService && Boolean(formik.errors.tutoringService)}
                                        helperText={formik.touched.tutoringService && formik.errors.tutoringService}
                                        sx={{
                                            '& .MuiSvgIcon-root': {
                                                color: "rgba(0, 0, 0, 0.74)"
                                            },
                                            '& .MuiInputBase-root': {
                                                backgroundColor: '#FFF',
                                            },
                                            '& .MuiSelect-select': {
                                                border: '1px solid rgba(0, 0, 0, 0.3)'
                                            }
                                        }}
                                    >
                                        <MenuItem key={"Coding and Robotics"} value={"Coding and Robotics"}>Coding and Robotics</MenuItem>
                                        <MenuItem key={"Coding as a standalone course"} value={"Coding as a standalone course"}>Coding as a standalone course</MenuItem>
                                    </TextField> 
                                </div>

                            </div>

                            <div className='fieldRow'>
                                <div className='fieldHld'>
                                    <div className='label req'>How would you like the coding and Robotics tutoring to be delivered at your school ?</div>
                                    <TextField
                                        required
                                        fullWidth
                                        select
                                        variant="outlined"
                                        id="tutoringMode"
                                        name="tutoringMode" 
                                        value={formik.values.tutoringMode}
                                        onBlur={checkValidity}
                                        onChange={formik.handleChange}
                                        onFocus={formik.handleBlur}
                                        error={formik.touched.tutoringMode && Boolean(formik.errors.tutoringMode)}
                                        helperText={formik.touched.tutoringMode && formik.errors.tutoringMode}
                                        sx={{
                                            '& .MuiSvgIcon-root': {
                                                color: "rgba(0, 0, 0, 0.74)"
                                            },
                                            '& .MuiInputBase-root': {
                                                backgroundColor: '#FFF',
                                            },
                                            '& .MuiSelect-select': {
                                                border: '1px solid rgba(0, 0, 0, 0.3)'
                                            }
                                        }}
                                    >
                                        <MenuItem key={"1"} value={"As a Club/Extra- curricular activity"}>As a Club/Extra- curricular activity</MenuItem>
                                        <MenuItem key={"2"} value={"To be taught to all classes in the school"}>To be taught to all classes in the school</MenuItem>
                                        <MenuItem key={"3"} value={"To be taught to selected classes in the school"}>To be taught to selected classes in the school</MenuItem>
                                        <MenuItem key={"4"} value={"Coding and Robotics Summercamp"}>Coding and Robotics Summercamp</MenuItem>
                                    </TextField> 
                                </div>
                            </div>

                            <div className='fieldRow'>
                                <div className='fieldHld'>
                                    <div className='label req'>School contact person’s name</div>
                                    <TextField
                                        required
                                        fullWidth
                                        className='textField'
                                        id="contactPersonName"
                                        name="contactPersonName" 
                                        type="text"
                                        //placeholder='Phone Number' 
                                        value={formik.values.contactPersonName}
                                        onBlur={checkValidity}
                                        onChange={formik.handleChange}
                                        onFocus={formik.handleBlur}
                                        variant="outlined"
                                        error={formik.touched.contactPersonName && Boolean(formik.errors.contactPersonName)}
                                        helperText={formik.touched.contactPersonName && formik.errors.contactPersonName}
                                    />
                                </div>
                            </div>

                            {/* <div className='fieldRow'>
                                <div className='fieldHld'>
                                    <div className='label req'>School contact person’s phone number ( whatsapp preferrable)</div>
                                    <TextField
                                        required
                                        fullWidth
                                        className='textField'
                                        id="contactPersonPhoneNo"
                                        name="contactPersonPhoneNo" 
                                        type="text"
                                        //placeholder='Phone Number' 
                                        value={formik.values.contactPersonPhoneNo}
                                        onBlur={checkValidity}
                                        onChange={formik.handleChange}
                                        onFocus={formik.handleBlur}
                                        variant="outlined"
                                        error={formik.touched.contactPersonPhoneNo && Boolean(formik.errors.contactPersonPhoneNo)}
                                        helperText={formik.touched.contactPersonPhoneNo && formik.errors.contactPersonPhoneNo}
                                    />
                                </div>
                            </div> */}

                            <div className='fieldRow'>

                                <div className='fieldHld lg'>
                                    <div className='label'>Is there any extra important information you would like to share with us ?</div>
                                    <TextField
                                        fullWidth
                                        multiline
                                        rows={3}
                                        maxRows={7}
                                        className='textField'
                                        id="message"
                                        name="message" 
                                        type="text"
                                        placeholder='Enter Message' 
                                        value={formik.values.message}
                                        onBlur={checkValidity}
                                        onChange={formik.handleChange}
                                        onFocus={formik.handleBlur}
                                        variant="outlined"
                                        error={formik.touched.message && Boolean(formik.errors.message)}
                                        helperText={formik.touched.message && formik.errors.message}
                                        sx={{
                                            '& .MuiInputBase-root': {
                                                backgroundColor: '#FFF',
                                                padding: '0px',
                                                //border: '1px solid rgba(0, 0, 0, 0.3)'
                                            }
                                        }}
                                    />
                                </div>
                            </div>

                            <div className='fieldRow'>
                                <div className='fieldHld'>
                                    <div className='label'>How did you hear about us ?</div>
                                    <TextField
                                        fullWidth
                                        select
                                        variant="outlined"
                                        id="awarenessMedium"
                                        name="awarenessMedium" 
                                        value={formik.values.awarenessMedium}
                                        onBlur={checkValidity}
                                        onChange={formik.handleChange}
                                        onFocus={formik.handleBlur}
                                        error={formik.touched.awarenessMedium && Boolean(formik.errors.awarenessMedium)}
                                        helperText={formik.touched.awarenessMedium && formik.errors.awarenessMedium}
                                        sx={{
                                            '& .MuiSvgIcon-root': {
                                                color: "rgba(0, 0, 0, 0.74)"
                                            },
                                            '& .MuiInputBase-root': {
                                                backgroundColor: '#FFF',
                                            },
                                            '& .MuiSelect-select': {
                                                border: '1px solid rgba(0, 0, 0, 0.3)'
                                            }
                                        }}
                                    >
                                        <MenuItem key={"Facebook"} value={"Facebook"}>Facebook</MenuItem>
                                        <MenuItem key={"Instagram"} value={"Instagram"}>Instagram</MenuItem>
                                        <MenuItem key={"Linkedin"} value={"Linkedin"}>Linkedin</MenuItem>
                                        <MenuItem key={"Email"} value={"Email"}>Email</MenuItem>
                                        <MenuItem key={"Word of Mouth/Referral"} value={"Word of Mouth/Referral"}>Word of Mouth/Referral</MenuItem>
                                    </TextField> 
                                </div>
                            </div>

                            <div className='fieldRow'>
                                <div className='fieldHld'>
                                    <div className='label'>Were you referred to us by someone ?, if yes please indicate their name below</div>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        id="referral"
                                        name="referral" 
                                        value={formik.values.referral}
                                        onBlur={checkValidity}
                                        onChange={formik.handleChange}
                                        onFocus={formik.handleBlur}
                                        error={formik.touched.referral && Boolean(formik.errors.referral)}
                                        helperText={formik.touched.referral && formik.errors.referral}
                                        sx={{
                                            '& .MuiSvgIcon-root': {
                                                color: "rgba(0, 0, 0, 0.74)"
                                            },
                                            '& .MuiInputBase-root': {
                                                backgroundColor: '#FFF',
                                            },
                                            '& .MuiSelect-select': {
                                                border: '1px solid rgba(0, 0, 0, 0.3)'
                                            }
                                        }}
                                    >
                                    </TextField> 
                                </div>
                            </div>

                            <div className='fieldRow'>
                                <Button
                                    onClick={submitForm}
                                    className="hasIcon mainCta extra"
                                    fullWidth 
                                    type="button"
                                    variant="contained"
                                >Submit Form
                                </Button>
                                <ToastContainer
                                    position="top-center"
                                    autoClose={5000}
                                    hideProgressBar={false}
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnFocusLoss
                                    draggable
                                    pauseOnHover
                                    theme="light"
                                />
                            </div>
                        </form>

                    </div>
                </div>
                
            </div>



        </div>
    
    );
};

export default CodingSchoolReqFormPage;