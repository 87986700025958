import * as React from 'react';

import Header from '../components/Header';
import Footer from '../components/Footer';
import SmsDemoPage from '../components/SmsDemoPage';

const SmsDemo = () => {   

  return (
    
    <div className="page-container">
      <Header className='header bkg-pale-purple' />
      <SmsDemoPage />
      <Footer />
    </div>
    
  );
};

export default SmsDemo;