import * as React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import ContactPage from '../components/ContactPage';

const Contact = () => {   

  return (
    
    <div className="page-container">
      <Header className='header bkg-green-light' />
      <ContactPage />
      <Footer />
    </div>
    
  );
};

export default Contact;