import React, { useState } from 'react';
import { useRef } from "react";
import {Routes, Route, useNavigate} from 'react-router-dom';

import HomeTutors from '../assets/img/home/home-tutors-icon.svg';
import CodingIcon from '../assets/img/home/coding-icon.svg';
import TeachingIcon from '../assets/img/home/teaching-icon.svg';
import SmsIcon from '../assets/img/home/sms-icon.svg';
import TutorSignupIcon from '../assets/img/home/tutor-signup-icon.svg';

import HomeTutorsClr from '../assets/img/home/home-tutors-clr-icon.svg';
import CodingIconClr from '../assets/img/home/coding-clr-icon.svg';
import TeachingIconClr from '../assets/img/home/teaching-clr-icon.svg';
import SmsIconClr from '../assets/img/home/schools-clr-icon.svg';
import TutorSignupIconClr from '../assets/img/home/tutor-signup-clr-icon.svg';

import { Button, Popper, Modal, Box, FormControlLabel, Checkbox } from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faChevronDown } from "@fortawesome/free-solid-svg-icons";


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    //border: '1px solid #CFCFCF',
    //boxShadow: 24,
    p: 4,
};

const Navbar = () => {

    //Change burger classes
    const [burgerClass, setBurgerClass] = useState("burgerBar unclicked");
    const [menuClass, setMenuClass] = useState("mobileMenu hidden");
    const [isMenuClicked, setIsMenuClicked] = useState(false);

    //Toggle Burger menu
    const updateMenu = () => {
        if(!isMenuClicked) {
            setBurgerClass("burgerBar clicked")
            setMenuClass("mobileMenu visible")
        }
        else {
            setBurgerClass("burgerBar unclicked");
            setMenuClass("mobileMenu hidden");
        }

        setIsMenuClicked(!isMenuClicked);
    }

    const [solutionsClicked, setSolutionsClicked] = useState(false);
    const contentEl5 = useRef();
    const handleSolutionsToggle = () => {
        //alert(solutionsClicked);
        setSolutionsClicked(!solutionsClicked);
    };

    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };
    const open = Boolean(anchorEl);
    //const id = open ? 'simple-popper' : undefined;


    //Modal Use State Function
    const [modalOpen, setModalOpen] = React.useState(false);
    const handleOpen = () => setModalOpen(true);
    const handleClose = () => setModalOpen(false);


    //Set Subject Coding Tutor Value
    const [subjectChecked, setSubjectChecked] = React.useState(false);
    const handleSubjectChange = (event) => {
        setSubjectChecked(event.target.checked);
        setCodingChecked(!event.target.checked);
    };

    //Set Coding Coding Tutor Value
    const [codingChecked, setCodingChecked] = React.useState(false);
    const handleCodingChange = (event) => {
        setCodingChecked(event.target.checked);
        setSubjectChecked(!event.target.checked);
    };

    const navigate = useNavigate();
    const handleSelection = () => {
        if(subjectChecked) {
            navigate('/subject-tutor-registration-form');
        }
        else if(codingChecked) {
            navigate('/coding-tutor-registration-form');
        }
    }

    return (

        <div className='nav'>
            <div className='burgerMenu' onClick={updateMenu}>
                <div className={burgerClass}></div>
                <div className={burgerClass}></div>
                <div className={burgerClass}></div>
            </div>

            <div className={menuClass}>
                <li className='menuItem'><a href="/">Home</a></li>
                <li className='menuItem'><a href="/about">About Us</a></li>
                <li className={`menuItem ${solutionsClicked ? "active" : ""}`}>
                    <button className="button" onClick={handleSolutionsToggle}>
                        <span>Solutions</span>
                        <span className="control"><FontAwesomeIcon icon={faChevronDown} /></span>
                    </button>

                    <div
                        ref={contentEl5}
                        className="dropWrapper"
                        style={
                            solutionsClicked
                            ? { display: "block" }
                            : { display: "none" }
                        }
                    >
                        <div className='dropDown'>
                            <ul>
                                <li>
                                    <a className='hover-orange' href="/tutors">
                                        <span>
                                            <img src={HomeTutors} alt="" />
                                            <img src={HomeTutorsClr} alt="" />
                                        </span>
                                        <span>Home Tutors <FontAwesomeIcon icon={faArrowRight} /></span>
                                    </a>
                                </li>
                                <li>
                                    <a className='hover-purple' href="/coding-robotics">
                                        <span>
                                            <img src={CodingIcon} alt="" />
                                            <img src={CodingIconClr} alt="" />
                                        </span>                                          
                                        <span>Coding & Robotics <FontAwesomeIcon icon={faArrowRight} /></span>
                                    </a>
                                </li>
                                <li>
                                    <a className='hover-pink' href="/teaching-resources">
                                        <span>
                                            <img src={TeachingIcon} alt="" />
                                            <img src={TeachingIconClr} alt="" />
                                        </span>  
                                        <span>Teaching Resources <FontAwesomeIcon icon={faArrowRight} /></span>
                                    </a>
                                </li>
                                <li>
                                    <a className='hover-blue' href="/school-management-system">
                                        <span>
                                            <img src={SmsIcon} alt="" />
                                            <img src={SmsIconClr} alt="" />
                                        </span>  
                                        <span>School Management System <FontAwesomeIcon icon={faArrowRight} /></span>
                                    </a>
                                </li>
                                <li>
                                    <a onClick={handleOpen} className='hover-green' href="#">
                                        <span>
                                            <img src={TutorSignupIcon} alt="" />
                                            <img src={TutorSignupIconClr} alt="" />
                                        </span>  
                                        <span>Tutor’s Sign up <FontAwesomeIcon icon={faArrowRight} /></span>
                                    </a>

                                    <Modal
                                        open={modalOpen}
                                        onClose={handleClose}
                                        aria-labelledby="modal-modal-title"
                                        aria-describedby="modal-modal-description"
                                    >
                                        <Box sx={style}>
                                            <div onClick={handleClose} className='closeModal'>
                                                <div className='closeBtn'></div>
                                            </div>
                                            <div className='modalDetails'>
                                                <div className='hdr lg dark'>What type of tutor are you?</div>
                                                <div className='modalAction' id="modal-modal-description">
                                                    <FormControlLabel className='modalCheck' control={<Checkbox checked={subjectChecked} onChange={handleSubjectChange}  />} label="Subject Tutors" />
                                                    <FormControlLabel className='modalCheck' control={<Checkbox checked={codingChecked} onChange={handleCodingChange} />}  label="Coding Tutors" />
                                                </div>
                                                <Button 
                                                    className="hasIcon mainCta"
                                                    fullWidth 
                                                    type="button"
                                                    variant="contained"
                                                    onClick={handleSelection}
                                                >Continue to Tutor Registration <FontAwesomeIcon icon={faArrowRight} /></Button>
                                            </div>
                                        </Box>
                                    </Modal>
                                </li>
                            </ul>
                        </div>
                    </div>
                </li> 
                <li className='menuItem'><a href="/contact">Contact Us</a></li>
            </div>
        </div>

    )

    
}

export default Navbar