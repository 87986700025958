import * as React from 'react';
import { useFormik } from "formik";
import * as yup from "yup";
import { TextField, Button } from "@mui/material";


const validationSchema = yup.object({
    firstName: yup.string().required("This is a required field"),
    lastName: yup.string().required("This is a required field"),
    email: yup.string().email("Please insert a valid email").required("This field is required"),
    phoneNo: yup.string().required("This is a required field"),
    message: yup.string().required("This is a required field")
})


const ContactPage = () => { 

    const formik = useFormik({
        initialValues: {
            firstName: "",
            lastName: "",
            email: "",
            phoneNo: "",
            message: "",
        },
        onSubmit: (values) => {
            // console.log(formik.values);
        },
        validationSchema: validationSchema
    });

    const [valid, setValid] = React.useState(false);
    const checkValidity = () => {
        // do what you want like on submit
        if (formik.isValid) {
            setValid(true);
        }
        else if(!formik.isValid) {
            setValid(false);
        }     
    }

    return (
    
        <div className="page-content bkg-green-light pgx pgy">

            <div className='subPages about'>
                <div className='hdr xl mb-3 mb-lg-2'>Got a question, an enquiry? Reach us.</div>
                <p>Please leave a message & you will get a prompt response from our team.</p>

                <div className='row justify-content-between align-items-start'>
                    <div className='col-lg-7'>
                        <div className='formWrap'>
                            <form>
                                <div className="fieldRow">
                                    <div className='fieldHld sm'>
                                        <TextField
                                            required
                                            fullWidth
                                            className='textField'
                                            id="firstName"
                                            name="firstName" 
                                            type="text" 
                                            placeholder='First Name'
                                            value={formik.values.firstName}
                                            onBlur={checkValidity}
                                            onChange={formik.handleChange}
                                            onFocus={formik.handleBlur}
                                            variant="outlined"
                                            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                                            helperText={formik.touched.firstName && formik.errors.firstName}
                                        />
                                    </div>

                                    <div className='fieldHld sm'>
                                        <TextField
                                            required
                                            fullWidth
                                            className='textField'
                                            id="lastName"
                                            name="lastName" 
                                            type="text" 
                                            placeholder='Last Name'
                                            value={formik.values.lastName}
                                            onBlur={checkValidity}
                                            onChange={formik.handleChange}
                                            onFocus={formik.handleBlur}
                                            variant="outlined"
                                            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                                            helperText={formik.touched.lastName && formik.errors.lastName}
                                        />
                                    </div>
                                </div>

                                <div className='fieldRow'>
                                    <div className='fieldHld sm'>
                                        <TextField
                                            required
                                            fullWidth
                                            className='textField'
                                            id="email"
                                            name="email" 
                                            type="email" 
                                            placeholder='Email'
                                            value={formik.values.email}
                                            onBlur={checkValidity}
                                            onChange={formik.handleChange}
                                            onFocus={formik.handleBlur}
                                            variant="outlined"
                                            error={formik.touched.email && Boolean(formik.errors.email)}
                                            helperText={formik.touched.email && formik.errors.email}
                                        />
                                    </div>

                                    <div className='fieldHld sm'>
                                        <TextField
                                            required
                                            fullWidth
                                            className='textField'
                                            id="phoneNo"
                                            name="phoneNo" 
                                            type="text"
                                            placeholder='Phone Number' 
                                            value={formik.values.phoneNo}
                                            onBlur={checkValidity}
                                            onChange={formik.handleChange}
                                            onFocus={formik.handleBlur}
                                            variant="outlined"
                                            error={formik.touched.phoneNo && Boolean(formik.errors.phoneNo)}
                                            helperText={formik.touched.phoneNo && formik.errors.phoneNo}
                                        />
                                    </div>
                                </div>

                                {/* <div className="fieldRow">
                                    <div className="fieldHld sm">
                                    <div className="label req">Gender</div>
                                    <TextField
                                        required
                                        fullWidth
                                        select
                                        variant="outlined"
                                        id="gender"
                                        name="gender"
                                        value={formik.values.gender}
                                        onBlur={checkValidity}
                                        onChange={formik.handleChange}
                                        onFocus={formik.handleBlur}
                                        error={formik.touched.lga && Boolean(formik.errors.gender)}
                                        helperText={formik.touched.lga && formik.errors.gender}
                                        sx={{
                                        "& .MuiSvgIcon-root": {
                                            color: "rgba(0, 0, 0, 0.74)",
                                        },
                                        "& .MuiInputBase-root": {
                                            backgroundColor: "#FFF",
                                        },
                                        "& .MuiSelect-select": {
                                            border: "1px solid rgba(0, 0, 0, 0.3)",
                                        },
                                        }}
                                    >
                                        <MenuItem key={"Male"} value={"Male"}>
                                        Male
                                        </MenuItem>
                                        <MenuItem key={"Female"} value={"Female"}>
                                        Female
                                        </MenuItem>
                                        <MenuItem
                                        key={"Prefer not to say"}
                                        value={"Prefer not to say"}
                                        >
                                        Prefer not to say
                                        </MenuItem>
                                    </TextField>
                                    </div>
                                </div> */}

                                <div className='fieldRow'>

                                    <div className='fieldHld lg'>
                                        <TextField
                                            required
                                            fullWidth
                                            multiline
                                            rows={3}
                                            maxRows={7}
                                            className='textField mb-lg-0 mb-5'
                                            id="message"
                                            name="message" 
                                            type="text"
                                            placeholder='Message' 
                                            value={formik.values.message}
                                            onBlur={checkValidity}
                                            onChange={formik.handleChange}
                                            onFocus={formik.handleBlur}
                                            variant="outlined"
                                            error={formik.touched.message && Boolean(formik.errors.message)}
                                            helperText={formik.touched.message && formik.errors.message}
                                            sx={{
                                                '& .MuiInputBase-root': {
                                                    backgroundColor: '#FFF',
                                                    padding: '0px',
                                                    //border: '1px solid rgba(0, 0, 0, 0.3)'
                                                }
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className='fieldRow'>
                                    <Button 
                                        className="hasIcon mainCta"
                                        fullWidth 
                                        type="button"
                                        variant="contained"
                                    >Send Message
                                    </Button>
                                </div>
                            </form>

                        </div>
                    </div>

                    <div className='col-lg-4 hasBlur d-flex justify-content-between align-items-center'>

                        <div className='contactWrap'>
                            <div className='contactHld'>
                                <div className='title'>Working Hours</div>
                                <div className='details'>
                                    <p>(Mon to Fri): 10.00 AM to 05.00 PM</p>
                                </div>
                            </div>

                            <div className='contactHld'>
                                <div className='title'>Office Address</div>
                                <div className='details'>
                                    <p>Plot B2, Block 21, Fatai Arobieke Street,</p>
                                    <p>Lekki Phase 1, Lagos, Nigeria</p>
                                </div>
                            </div>

                            <div className='contactHld'>
                                <div className='title'>Contact Details</div>
                                <div className='details'>
                                    <p><span>Phone:</span> <a href="tel:+2348173436415">+2348173436415</a> <a href="tel:+2348173436415">+2348035519014</a></p>
                                    <p><span>Support:</span> <a href="mailto:info@nigenius.ng">info@nigenius.ng</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>

        </div>
        
    );
};

export default ContactPage;