import { POST_CODE, POST_CODE_ERROR } from '../actions/types';

const initialState = {
    data: null,
    loading: true,
    error: ""
}

// export default function(state = initialState, action) {

//     const { type, payload } = action;

//     switch(type) {

//         case POST_SUBJECT_TUTOR_REG:
//         return {
//             ...state,
//             data: payload,
//             loading: false
//         }

//         case POST_SUBJECT_TUTOR_REG_ERROR:
//         return {
//             ...state,
//             error: payload,
//             loading: false
//         }

//         default:
//         return state;
//     }
   
// }

export default function(state = initialState, action) {

    const { type, payload } = action;

    switch(type) {

        case POST_CODE:
        return {
            ...state,
            data: payload,
            loading: false
        }

        case POST_CODE_ERROR:
        return {
            ...state,
            error: payload,
            loading: false
        }

        default:
        return state;
    }
   
}

