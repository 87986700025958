import { useState } from "react";
import { useRef } from "react";

const AccordionItem = ({ faq }) => {

  const [clicked, setClicked] = useState(false);
  const contentEl = useRef();

  const { question, answer } = faq;

  const handleToggle = () => {
    setClicked((prev) => !prev);
  };

  return (
    <li className={`accordionItem ${clicked ? "active" : ""}`}>
        <button className="button" onClick={handleToggle}>
          <span>{question}</span>
          <span className="control">{clicked ? "—" : "+"} </span>
        </button>

        <div
            ref={contentEl}
            className="answerWrapper"
            style={
            clicked
                ? { height: contentEl.current.scrollHeight }
                : { height: "0px" }
            }
        >
            <div className="answer">{answer}</div>
        </div>
    </li>
  );
};

export default AccordionItem;