import * as React from 'react';
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { verifyCode, sendEmailForCode } from '../actions/formdata';
import { Button, TextField, Icon, Modal, Box, FormControlLabel, Checkbox } from "@mui/material";

import { useFormik } from "formik";
import * as yup from "yup";

import HeroImage1 from "../assets/img/tutors/tutorHero-1.png";
import HeroImage2 from "../assets/img/tutors/tutorHero-2.png";


const validationSchema = yup.object({
    email: yup.string().email("Please insert a valid email").required("This field is required"),
    verifyCode: yup.string().required("This field is required"),
})

const style = {
    width: '100%',
    display: 'inline-block',
};


const VerificationPage = () => {

    const dispatch = useDispatch();
    // const verifyStatus = useSelector((state) => state.verifyCode);
    // console.log(verifyStatus);

    // useEffect(() => {
    //     console.log(params);
    //     if(params != "") {
    //         dispatch(verifyCode(params.codeId)).then(() => {
    //             console.log(verifyStatus);
    //             if (verifyStatus.data != null) {
    //                 if (verifyStatus.data.data.type == 'coding') {
    //                     navigate("/coding-tutor-registration-form-stage-2");
    //                 }
    //                 else if (verifyStatus.data.data.type == 'subject') {
    //                     navigate("/subject-tutor-registration-form-stage-2");
    //                 }                 
    //             }
    //         })
    //     }
        
    // }, [verifyStatus]);


    const formik = useFormik({
        initialValues: {
            email: "",
        },
        validationSchema: validationSchema
    });

    const [valid, setValid] = React.useState(false);
    const checkValidity = () => {
        // do what you want like on submit
        if (formik.isValid) {
            setValid(true);
        }
        else if(!formik.isValid) {
            setValid(false);
        }     
    }

    const submitForm = () => {
        let formData = {
            email: formik.values.email,
        }
        //console.log(lgaValue);

        dispatch(sendEmailForCode(formData));
        
        formik.values.email = "";
    }


    return (
        
        <div className="page-content bkg-green-light pgx pgy">

            {/* <div className='blur1'></div> */}

            <div className='subPages row justify-content-between align-items-center'>
                <div className='col-lg-6'>
                    <div className='hdr xl mb-lg-3'>Continue Registration</div>
                    <div className='text pe-lg-5'>
                        <p>Please enter your mail to receive a verification link to continue to the second stage of your tutor registration on Nigenius.</p>
                    </div>

                    <div className='formWrap'>
                        <form>
                            <div className='fieldRow'>
                                <div className='fieldHld sm'>
                                    {/* <div className='label req'>Email</div> */}
                                    <TextField
                                        required
                                        fullWidth
                                        className='textField'
                                        id="email"
                                        name="email" 
                                        type="email" 
                                        placeholder='Enter your email'
                                        value={formik.values.email}
                                        onBlur={checkValidity}
                                        onChange={formik.handleChange}
                                        onFocus={formik.handleBlur}
                                        variant="outlined"
                                        error={formik.touched.email && Boolean(formik.errors.email)}
                                        helperText={formik.touched.email && formik.errors.email}
                                    />
                                </div>

                            </div>

                            {/* <div className='fieldRow'>
                                <div className='fieldHld sm'>
                                    <TextField
                                        required
                                        fullWidth
                                        className='textField'
                                        id="verifyCode"
                                        name="verifyCode" 
                                        type="verifyCode" 
                                        placeholder='Enter verification code'
                                        value={formik.values.verifyCode}
                                        onBlur={checkValidity}
                                        onChange={formik.handleChange}
                                        onFocus={formik.handleBlur}
                                        variant="outlined"
                                        error={formik.touched.verifyCode && Boolean(formik.errors.verifyCode)}
                                        helperText={formik.touched.verifyCode && formik.errors.verifyCode}
                                    />
                                </div>

                            </div> */}

                            <div className='fieldRow'>
                                <a className='noFormat' style={style}>
                                    <Button 
                                        onClick={submitForm}
                                        className="hasIcon"
                                        fullWidth 
                                        type="button"
                                        variant="contained"
                                        sx={{
                                            width: '100%',
                                            fontSize: '1.1rem',
                                            gap: '15px',
                                            fontWeight: '700',
                                            padding: "9px 35px",
                                            textTransform: "capitalize",
                                            backgroundColor: "#01C38E",
                                            ':hover': {
                                                backgroundColor: '#01C38E',
                                                opacity: '0.8'
                                            },
                                        }}
                                    >Send Verification Link
                                    </Button>
                                </a>
                                
                            </div>
                        </form>

                    </div>

                </div>
                <div className='col-lg-5 double hasBlur d-flex justify-content-between align-items-center'>
                    <div className='box-card md clr-pink'>
                        <img src={HeroImage1} alt="" />
                    </div>
                    <div className='box-card lg clr-purple'>
                        <img src={HeroImage2} alt="" />
                    </div>
                </div>
            </div>

        </div>
        
    );
};

export default VerificationPage;