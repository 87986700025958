import * as React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import PrivacyPage from '../components/PrivacyPage';

const Contact = () => {

  return (

    <div className="page-container">
      <Header className='header bkg-green-light' />
      <PrivacyPage />
      <Footer />
    </div>

  );
};

export default Contact;