export const GET_CLASSES = "GET_CLASSES";
export const GET_CLASSES_ERROR = "GET_CLASSES_ERROR";

export const GET_SUBJECTS = "GET_SUBJECTS";
export const GET_SUBJECTS_ERROR = "GET_SUBJECTS_ERROR";

export const GET_EXAMS = "GET_EXAMS";
export const GET_EXAMS_ERROR = "GET_EXAMS_ERROR";

export const GET_CODING_LANGUAGES = "GET_CODING_LANGUAGES";
export const GET_CODING_LANGUAGES_ERROR = "GET_CODING_LANGUAGES_ERROR";

export const GET_COUNTRIES = "GET_COUNTRIES";
export const GET_COUNTRIES_ERROR = "GET_COUNTRIES_ERROR";

export const GET_STATES = "GET_STATES";
export const GET_STATES_ERROR = "GET_STATES_ERROR";

export const GET_CITIES = "GET_CITIES";
export const GET_CITIES_ERROR = "GET_CITIES_ERROR";

export const GET_LGAS = "GET_LGAS";
export const GET_LGAS_ERROR = "GET_LGAS_ERROR";

export const POST_CODING_TUTOR_REQ = "POST_CODING_TUTOR_REQ";
export const POST_CODING_TUTOR_REQ_ERROR = "POST_CODING_TUTOR_REQ_ERROR";

export const POST_CODING_TUTOR_REG = "POST_CODING_TUTOR_REG";
export const POST_CODING_TUTOR_REG_ERROR = "POST_CODING_TUTOR_REG_ERROR";

export const POST_SCHOOL_CODING_REQ = "POST_SCHOOL_CODING_REQ";
export const POST_SCHOOL_CODING_REQ_ERROR = "POST_SCHOOL_CODING_REQ_ERROR";

export const POST_SUBJECT_TUTOR_REQ = "POST_SUBJECT_TUTOR_REQ";
export const POST_SUBJECT_TUTOR_REQ_ERROR = "POST_SUBJECT_TUTOR_REQ_ERROR";

export const POST_SUBJECT_TUTOR_REG = "POST_SUBJECT_TUTOR_REG";
export const POST_SUBJECT_TUTOR_REG_ERROR = "POST_SUBJECT_TUTOR_REG_ERROR";

export const POST_TUTOR_FINAL_REG = "POST_TUTOR_FINAL_REG";
export const POST_TUTOR_FINAL_REG_ERROR = "POST_TUTOR_FINAL_REG_ERROR";

export const POST_EMAIL_FOR_CODE = "POST_EMAIL_FOR_CODE";
export const POST_EMAIL_FOR_CODE_ERROR = "POST_EMAIL_FOR_CODE_ERROR";

export const POST_CODE = "POST_CODE";
export const POST_CODE_ERROR = "POST_CODE_ERROR";

export const GET_TUTORS = "GET_TUTORS";
export const GET_TUTORS_ERROR = "GET_TUTORS_ERROR";

export const GET_SINGLE_TUTOR = "GET_SINGLE_TUTOR";
export const GET_SINGLE_TUTOR_ERROR = "GET_SINGLE_TUTOR_ERROR";

export const SEARCH_TUTORS = "SEARCH_TUTORS";
export const SEARCH_TUTORS_ERROR = "SEARCH_TUTORS_ERROR";

export const GET_GALLARY = "GET_GALLARY";
export const GET_GALLARY_ERROR = "GET_GALLARY_ERROR";

export const GET_STATS = "GET_STATS";
export const GET_STATS_ERROR = "GET_STATS_ERROR";
