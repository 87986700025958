import { useState } from "react";
import { useRef } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

import { TextField,  FormControl, Checkbox, Box, Slider, Button, FormControlLabel } from "@mui/material";




const TutorAccordion = () => {

    const [keywordClicked, setKeywordClicked] = useState(false);
    const contentEl1 = useRef();
    const handleKeyWordToggle = () => {
        setKeywordClicked(!keywordClicked);
    };

    const [sortClicked, setSortClicked] = useState(false);
    const contentEl2 = useRef();
    const handleSortToggle = () => {
        setSortClicked(!sortClicked);
    };

    const [locationClicked, setLocationClicked] = useState(false);
    const contentEl3 = useRef();
    const handleLocationToggle = () => {
        setLocationClicked(!locationClicked);
    };

    const [priceClicked, setPriceClicked] = useState(false);
    const contentEl4 = useRef();
    const handlePriceToggle = () => {
        setPriceClicked(!priceClicked);
    };

    const [popularClicked, setPopularClicked] = useState(false);
    const contentEl5 = useRef();
    const handlePopularToggle = () => {
        setPopularClicked(!popularClicked);
    };


    const [sliderValue, setSliderValue] = useState([0, 10000]);
    const handleSliderChange = (event, newValue) => {
        setSliderValue(newValue);
    };


    return (
      <ul className="accordion tutorFilter">
        {/* <li className={`accordionItem ${keywordClicked ? "active" : ""}`}> */}
        <li className="accordionItem active">
            <button className="button" onClick={handleKeyWordToggle}>
                <span>Search by Subject or Class</span>
                <span className="control"></span>
            </button>

            <div
                ref={contentEl1}
                className="dropWrapper"
                style={
                    keywordClicked
                    ? { height: contentEl1.current.scrollHeight }
                    : { height: "130px" }
                }
            >
                <div className="dropdown">
                    <div className="fieldInput">
                        <TextField
                            fullWidth
                            className='textField'
                            id="keySearch"
                            name="firstName" 
                            type="text" 
                            placeholder="e.g Mathematics"
                            variant="outlined"
                            //error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                        />
                    </div>
                    <div className="supportText">Search for a specific word or character to help you find your perfect online tutor.</div>
                </div>
            </div>
        </li>

        {/* <li className={`accordionItem ${sortClicked ? "active" : ""}`}>
            <button className="button" onClick={handleSortToggle}>
                <span>Sort</span>
                <span className="control"><FontAwesomeIcon icon={faChevronDown} /></span>
            </button>

            <div
                ref={contentEl2}
                className="dropWrapper"
                style={
                    sortClicked
                    ? { height: contentEl2.current.scrollHeight }
                    : { height: "0px" }
                }
            >
                <div className="dropdown">
                    <ul className="subList">
                        <li>Recommended</li>
                        <li>Most Popular</li>
                        <li>Price: Lowest First</li>
                        <li>Price: Highest First</li>
                        <li>Most Reviews</li>
                        <li>Most Lessons Taught</li>
                    </ul>
                </div>
            </div>
        </li>

        <li className={`accordionItem ${locationClicked ? "active" : ""}`}>
            <button className="button" onClick={handleLocationToggle}>
                <span>Lesson Location</span>
                <span className="control"><FontAwesomeIcon icon={faChevronDown} /></span>
            </button>

            <div
                ref={contentEl3}
                className="dropWrapper"
                style={
                    locationClicked
                    ? { height: contentEl3.current.scrollHeight }
                    : { height: "0px" }
                }
            >
                <div className="dropdown">
                    <FormControlLabel sx={{marginTop: '1rem'}} control={<Checkbox />}  label="Online" />
                    <FormControlLabel sx={{width: '100%'}} control={<Checkbox />} label="In-person" />
                </div>
            </div>
        </li>

        <li className={`accordionItem ${priceClicked ? "active" : ""}`}>
            <button className="button" onClick={handlePriceToggle}>
                <span>Price</span>
                <span className="control"><FontAwesomeIcon icon={faChevronDown} /></span>
            </button>

            <div
                ref={contentEl4}
                className="dropWrapper"
                style={
                    priceClicked
                    ? { height: contentEl4.current.scrollHeight }
                    : { height: "0px" }
                }
            >
                <div className="dropdown">
                    <Box sx={{ width: '90%', margin: '2.6rem auto 0' }}>
                        <Slider
                            getAriaLabel={() => 'Price range'}
                            value={sliderValue}
                            onChange={handleSliderChange}
                            valueLabelDisplay="on"
                            //getAriaValueText={valuetext}
                        />
                    </Box>
                </div>
            </div>
        </li>

        <li className={`accordionItem ${popularClicked ? "active" : ""}`}>
            <button className="button" onClick={handlePopularToggle}>
                <span>Popular Filters</span>
                <span className="control"><FontAwesomeIcon icon={faChevronDown} /></span>
            </button>

            <div
                ref={contentEl5}
                className="dropWrapper"
                style={
                    popularClicked
                    ? { height: contentEl5.current.scrollHeight }
                    : { height: "0px" }
                }
            >
                <div className="dropdown">
                    <div className="controlWrap">
                        <div className="controlHld">
                            <FormControlLabel control={<Checkbox />}  label="Qualified Teacher" />
                            <div className="supportText">Tutor has self-reported that they have obtained TRCN (Teacher's Registration Council of Nigeria) license to teach in a school in Nigeria.</div>
                        </div>
                        <div className="controlHld">
                            <FormControlLabel control={<Checkbox />}  label="Background Checks" />
                            <div className="supportText">Tutor has a clean enhanced PCC (Police Clearance Check) check dated within the last two years and proof has been provided to Nigenius</div>
                        </div>
                        <div className="controlHld">
                            <FormControlLabel control={<Checkbox />}  label="Free Video Chat" />
                            <div className="supportText">Free video chats are the opportunity to get a 15 minute introduction with the tutor and discuss your needs with them.</div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </li> */}

      </ul>
    );
};
  
export default TutorAccordion;