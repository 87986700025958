import * as React from 'react';
import { styled } from '@mui/system';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

import {Routes, Route, useNavigate} from 'react-router-dom';

import { Button, Modal, Box, FormControlLabel, Checkbox } from "@mui/material";

import TabsUnstyled from '@mui/base/TabsUnstyled';
import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled';
import { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled';
import TabUnstyled, { tabUnstyledClasses } from '@mui/base/TabUnstyled';

import Service1 from "../../assets/img/home/service1.jpg";
import Service2 from "../../assets/img/home/teaching-resources2.png";
import Service3 from "../../assets/img/home/coding-robotics.png";
import Service4 from "../../assets/img/home/service4.png";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    //border: '1px solid #CFCFCF',
    //boxShadow: 24,
    p: 4,
};

const blue = {
  50: '#F0F7FF',
  100: '#C2E0FF',
  200: '#80BFFF',
  300: '#66B2FF',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  700: '#0059B2',
  800: '#004C99',
  900: '#003A75',
};

const grey = {
  50: '#f6f8fa',
  100: '#eaeef2',
  200: '#d0d7de',
  300: '#afb8c1',
  400: '#8c959f',
  500: '#6e7781',
  600: '#57606a',
  700: '#424a53',
  800: '#32383f',
  900: '#24292f',
};

const Tab = styled(TabUnstyled)`
  font-family: 'Poppins', sans-serif;
  color: white;
  cursor: pointer;
  font-size: 0.75rem;
  font-weight: bold;
  background-color: transparent;
  width: 46%;
  padding: 9px;
  margin: 6px 3px;
  border: none;
  border-radius: 7px;
  display: flex;
  justify-content: center;

  @media only screen and (min-width: 768px) {
    width: 24%;
    padding: 12px;
    font-size: 0.875rem;
  }

  &:hover {
    background-color: #33cfa4;
  }

  &:focus {
    color: #fff;
    outline: 3px solid #66dbbb;
  }

  &.${tabUnstyledClasses.selected} {
    background-color: #fff;
    color: #33cfa4;
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

// const Tab = styled(TabUnstyled)`
//   font-family: 'Poppins', sans-serif;
//   color: #292D32;
//   cursor: pointer;
//   font-size: 0.875rem;
//   font-weight: 500;
//   background-color: transparent;
//   width: 100%;
//   padding: 12px 0;
//   margin: 6px 6px;
//   outline: 0;
//   border: none;
//   border-radius: 7px;
//   display: flex;
//   justify-content: center;
//   transition : all 0.4s ease;
//   position: relative;

//   &:nth-child(n+2):after {
//     content: '';
//     width: 1px;
//     height: 125%;
//     position: absolute;
//     background-color: #486166;
//     top: 50%;
//     transform: translateY(-50%);
//     left: 0;
//   }

//   &:hover {
//     color: #33cfa4;
//   }

//   &.${tabUnstyledClasses.selected} {
//     color: #33cfa4;
//   }
  

//   &.${buttonUnstyledClasses.disabled} {
//     opacity: 0.5;
//     cursor: not-allowed;
//   }
// `;

const TabPanel = styled(TabPanelUnstyled)`
  width: 100%;
  font-family: 'Poppins', sans-serif;
  font-size: 0.875rem;
`;

const TabsList = styled(TabsListUnstyled)(
    ({ theme }) => `
    min-width: auto;
    background-color: #01C38E;
    border-radius: 12px;
    margin-bottom: 16px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    align-content: space-between;
    box-shadow: 0px 4px 8px ${theme.palette.mode === 'dark' ? grey[900] : grey[200]};

    @media only screen and (min-width: 768px) {
        min-width: 400px;
    }
    `,
);

// const TabsList = styled(TabsListUnstyled)(
//     ({ theme }) => `
//     width: 85%;
//     min-width: 400px;
//     margin: 0 auto 16px;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     align-content: space-between;
//     border-top: 1px solid #486166;
//     border-bottom: 1px solid #486166;
//     `,
// );

export default function UnstyledTabsCustomized() {

    //Modal Use State Function
    const [modalOpen, setModalOpen] = React.useState(false);
    const handleOpen = () => setModalOpen(true);
    const handleClose = () => setModalOpen(false);


    //Set Subject Coding Tutor Value
    const [subjectChecked, setSubjectChecked] = React.useState(false);
    const handleSubjectChange = (event) => {
        setSubjectChecked(event.target.checked);
        setCodingChecked(!event.target.checked);
    };

    //Set Coding Coding Tutor Value
    const [codingChecked, setCodingChecked] = React.useState(false);
    const handleCodingChange = (event) => {
        setCodingChecked(event.target.checked);
        setSubjectChecked(!event.target.checked);
    };

    const navigate = useNavigate();
    const handleSelection = () => {
        if(subjectChecked) {
            navigate('/subject-tutor-request-form');
        }
        else if(codingChecked) {
            navigate('/coding-tutor-request-form');
        }
    }

  return (
    <TabsUnstyled defaultValue={0}>
        <TabsList>
            <Tab>Home Tutors</Tab>
            <Tab>Teaching Resources</Tab>
            <Tab>Coding & Robotics</Tab>
            <Tab>School Management System</Tab>
        </TabsList>

        <TabPanel value={0}>
            <div className='tabSec row justify-content-between align-items-start'>
                <div className='col-lg-6'>
                    <div className='hdr lg mb-2 mb-lg-3'>Home Tutors</div>
                    <div className='text'>
                        <p>Nigenius tutors are passionate about improving their teaching skills by leveraging technology inside and outside of the classroom.</p>
                        <h4>Tutors are highly qualified</h4>
                        <p>Our Tutors are tech savvy, passionate about teaching and come from top schools across Nigeria and Africa.</p>
                        <h4>Tutors are Well trained</h4>
                        <p>Our tutors have access to world class digital teaching resources for your kids on the Nigenius platform.</p>
                        <h4>Safe and Secure Learning Environment</h4>
                        <p>Our tutors work with students in a safe and secure learning environment that ensures academic success.</p>
                    </div>
                    <div className='btnHld mt-4 mt-lg-5'>
                        <Button 
                            className="hasIcon mainCta caps"
                            fullWidth 
                            type="button"
                            variant="contained"
                            onClick={handleOpen}
                            sx={{
                                textTransform: "uppercase",
                            }}
                        >Request Now <FontAwesomeIcon icon={faArrowRight} /></Button>

                        <a className='noFormat' href='/tutors'>
                            <Button 
                                className='transCta'
                                fullWidth 
                                type="button"
                                variant="outlined"
                            >Learn More</Button>
                        </a>
                        
                    </div>

                    <Modal
                        open={modalOpen}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box className='modalBox' sx={style}>
                            <div onClick={handleClose} className='closeModal'>
                                <div className='closeBtn'></div>
                            </div>
                            <div className='modalDetails'>
                                <div className='hdr lg dark'>What type of tutor do you need?</div>
                                <div className='modalAction' id="modal-modal-description">
                                    <FormControlLabel control={<Checkbox checked={codingChecked} onChange={handleCodingChange} />}  label="Coding Tutor" />
                                    <FormControlLabel control={<Checkbox checked={subjectChecked} onChange={handleSubjectChange}  />} label="Subject Tutor" />                                 
                                </div>
                                <Button 
                                    className="hasIcon mainCta caps"
                                    fullWidth 
                                    type="button"
                                    variant="contained"
                                    onClick={handleSelection}
                                >Continue to tutor request <FontAwesomeIcon icon={faArrowRight} /></Button>
                            </div>
                        </Box>
                    </Modal>
                </div>
                <div className='col-lg-5'>
                    <div className='tabImage'>
                        <img src={Service1} alt="" />
                    </div>
                </div>
            </div>
        </TabPanel>
        <TabPanel value={1}>
            <div className='tabSec row justify-content-between align-items-start'>
                <div className='col-lg-6'>
                    <div className='hdr lg mb-2 mb-lg-3'>Teaching Resources</div>
                    <div className='text'>
                        <p>Using Nigenius , teachers are able to get quick and easy access to quality lesson plans and teaching resources on their computers and mobile devices.</p>
                        <p>Here’s how Nigenius can help you as a teacher</p>
                        <ul>
                            <li>Vetted and curriculum aligned lesson plans and teaching resources</li>
                            <li>Spend less time, data and effort searching for teaching resources online</li>
                            <li>Search for resources by category and by specific teaching objective</li>
                        </ul>
                    </div>
                    <div className='btnHld mt-4 mt-lg-5'>
                        <a className='noFormat' href='https://ap.nigenius.com.ng/#/auth/register' target='_blank'>
                            <Button 
                                className="hasIcon mainCta caps"
                                fullWidth 
                                type="button"
                                variant="contained"
                            >Create Account <FontAwesomeIcon icon={faArrowRight} /></Button>
                        </a>
                        <a className='noFormat' href='https://www.youtube.com/watch?v=LiqMj1nZv5k' target='_blank'>
                            <Button
                                className='transCta'
                                fullWidth 
                                type="button"
                                variant="outlined"
                            >Watch Demo</Button>
                        </a>
                    </div>
                </div>
                <div className='col-lg-5'>
                    <div className='tabImage22'>
                        <img src={Service2} className='' alt="" />
                    </div>
                </div>
            </div>
        </TabPanel>
        <TabPanel value={2}>
            <div className='tabSec row justify-content-between align-items-start'>
                <div className='col-lg-6'>
                    <div className='hdr lg mb-2 mb-lg-3'>Coding & Robotics</div>
                    <div className='text'>
                        <p>Nigenius provides schools with qualified and experienced STEAM tutors for specialized courses like Coding and Robotics.</p>
                        <h4>Why choose us as your coding and Robotics partner ?</h4>
                        <ul>
                            <li>Well trained and experienced Coding and Robotics tutors.</li>
                            <li>World, class Future - ready STEAM Curriculum</li>
                            <li>We guarantee one project exhibition at the end of every term.</li>
                            <li>State of the Art Robotics kits.</li>
                        </ul>
                    </div>
                    <div className='btnHld mt-4 mt-lg-5'>
                        <a className='noFormat' href='/coding-robotics'>
                            <Button 
                                className="hasIcon mainCta caps"
                                fullWidth 
                                type="button"
                                variant="contained"
                            >Request Tutors <FontAwesomeIcon icon={faArrowRight} /></Button>
                        </a>
                        <a className='noFormat' href='/coding-robotics'>
                            <Button 
                                className='transCta'
                                fullWidth 
                                type="button"
                                variant="outlined"
                            >View Gallery</Button>
                        </a>
                    </div>
                </div>
                <div className='col-lg-5'>
                    <div className='tabImage22'>
                        <img src={Service3} alt="" />
                    </div>
                </div>
            </div>
        </TabPanel>
        <TabPanel value={3}>
            <div className='tabSec row justify-content-between align-items-start'>
                <div className='col-lg-6'>
                    <div className='hdr lg mb-2 mb-lg-3'>School Management system</div>
                    <div className='text'>
                        <p>Introducing Nebula 1.0 by Nigenius. The Nigenius/Nebula school management system is an end-to-end integrated school software that helps schools automate their most difficult administrative and academic tasks.</p>
                        <h4>Why choose the Nigenius School Management System</h4>
                        <ul>
                            <li>Flexible and Adaptable</li>
                            <li>Quick and Easy Onboarding</li>
                            <li>Desktop and Mobile friendly</li>
                            <li>Real Time Customer support</li>
                        </ul>
                    </div>
                    <div className='btnHld mt-4 mt-lg-5'>
                        <a className='noFormat' href='/school-management-system-demo'>
                            <Button 
                                className="hasIcon mainCta caps"
                                fullWidth 
                                type="button"
                                variant="contained"
                            >Request a Demo <FontAwesomeIcon icon={faArrowRight} /></Button>
                        </a>
                        <a className='noFormat' href='/school-management-system'>
                            <Button 
                                className='transCta'
                                fullWidth 
                                type="button"
                                variant="outlined"
                            >How it works</Button>
                        </a>
                        
                    </div>
                </div>
                <div className='col-lg-5'>
                    <div className='tabImage'>
                        <img src={Service4}  className="img-fluid" alt="" />
                    </div>
                </div>
            </div>
        </TabPanel>
    </TabsUnstyled>
  );
}