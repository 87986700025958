import * as React from 'react';

import { Button, Icon } from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

import HeroImage1 from "../assets/img/tutors/teaching1.png";
import HeroImage2 from "../assets/img/tutors/teaching2.png";
import HeroImage3 from "../assets/img/home/teaching-resources.png";

const Teaching = () => {   

  return (
    
    <div className="page-content bkg-pale-yellow pgx pgy">

        <div className='subPages row justify-content-between align-items-start'>
            <div className='col-lg-6'>
                <div className='hdr xl mb-3 mb-lg-3'>Teaching Resources</div>
                <div className='only-mobile double py-3 hasBlur justify-content-between align-items-center'>
                    <div className='box-card md clr-pink'>
                        <img className='bkg' src={HeroImage1} alt="" />
                    </div>
                    <div className='box-card lg clr-purple'>
                        <img className='bkg' src={HeroImage2} alt="" />
                    </div>
                </div>
                <div className='text'>
                    <p>Using Nigenius , teachers are able to get quick and easy access to quality lesson plans and teaching resources on their computers and mobile devices.</p>
                    <h4>Here’s how Nigenius can help you as a teacher</h4>
                    <ul>
                        <li>Vetted and curriculum aligned lesson plans and teaching</li>
                        <li>Spend less time, data and effort searching for teaching resources online</li>
                        <li>Search for resources by category and by specific teaching objective</li>
                    </ul>
                </div>
                <div className='btnHld mt-4 mt-lg-5'>
                    <a className='noFormat' href='https://ap.nigenius.com.ng/#/auth/register' target='_blank'>
                        <Button 
                            className="hasIcon mainCta caps"
                            fullWidth 
                            type="button"
                            variant="contained"
                            sx={{
                                textTransform: "uppercase",
                            }}
                        >Create Account <FontAwesomeIcon icon={faArrowRight} /></Button>
                    </a>
                    <a className='noFormat' href='https://www.youtube.com/watch?v=LiqMj1nZv5k' target='_blank'>
                        <Button 
                            className='transCta'
                            fullWidth 
                            type="button"
                            variant="outlined"
                        >Watch Demo</Button>
                    </a>
                </div>
            </div>
            <div className='col-lg-5 only-desktop double hasBlur justify-content-between align-items-center'>
                <div className=' md clr-pink my-5'>
                    <img className='img-fluid' src={HeroImage3} alt="" />
                </div>
                {/* <div className='box-card md clr-pink'>
                    <img className='bkg' src={HeroImage1} alt="" />
                </div>
                <div className='box-card lg clr-purple'>
                    <img className='bkg' src={HeroImage2} alt="" />
                </div> */}
            </div>
        </div>
    </div>
    
  );
};

export default Teaching;