import * as React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Teaching from '../components/Teaching';

const TeachingResources = () => {   

  return (
    
    <div className="page-container">
        <Header className='header bkg-pale-yellow' />
        <Teaching />
        <Footer />
    </div>
    
  );
};

export default TeachingResources;