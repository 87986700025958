import * as React from 'react';
import { useState, useEffect } from "react";
import { createBrowserHistory } from 'history';
import { useDispatch, useSelector } from 'react-redux';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getClasses, getSubjects, getCodingLanguages, getCountries, getStates, getCities, getLgas, sendCodingTutorReg } from '../actions/formdata';
import CircularProgress from '@mui/material/CircularProgress';
import { useFormik } from "formik";
import * as yup from "yup";

import BackBtn from "../assets/img/brand/back-arrow.svg";

import { TextField, MenuItem, Select, ListSubheader, ListItemText, Chip, FormControl, InputAdornment, ClickAwayListener, InputIcon, InputLabel, Autocomplete, Checkbox, Box, Slider, Button, createFilterOptions, FormControlLabel } from "@mui/material";

import InfoIcon from "../assets/img/tutors/info-icon.svg";

import { Countries } from '../content';
import { Subjects } from '../content';
import { Exams } from '../content';
import { Classes } from '../content';
import { Languages } from '../content';



const validationSchema = yup.object({
    firstName: yup.string().required("This is a required field"),
    lastName: yup.string().required("This is a required field"),
    email: yup.string().email("Please insert a valid email").required("This field is required"),
    phoneNo: yup.string().required("This is a required field"),
    country: yup.string().required("This is a required field"),
    state: yup.string().required("This is a required field"),
    lga: yup.string().required("This is a required field"),
    city: yup.string().required("This is a required field"),
    gender: yup.string().required("This is a required field"),
    address: yup.string().required("This is a required field"),
    educationalQualification: yup.string().required("This is a required field"),
    codingSkillsRating: yup.string().required("This is a required field"),
    onlineCodingTest: yup.string().required("This is a required field"),
    onlineTutoringExperience: yup.string().required("This is a required field"),
    personalLaptop: yup.string().required("This is a required field"),
    currentEmployment: yup.string().required("This is a required field"),
    expectations: yup.string()
});

const subjects = Subjects;
const exams = Exams;
const classes = Classes;
const languages = Languages;


const CodingTutorRegFormPage = () => {

    //API CALLS

    const dispatch = useDispatch();
    // const stateValues = useSelector((state) => state);
    useEffect(() => {
        dispatch(getClasses())
        dispatch(getSubjects())
        dispatch(getCodingLanguages())
        dispatch(getCountries())
        dispatch(getStates('NG'));
        dispatch(getCities('NG', 'LA'));
        dispatch(getLgas('Lagos'));
    }, []);
    // useEffect(() => {
    //     console.log(stateValues.codingTutorReg);
    // }, [stateValues]);

    const classes = useSelector((state) => state.classes);
    let classesOptions = [];
    if(!classes.loading) {
        //console.log(classes)
        classesOptions = classes.data.sort((a, b) => parseFloat(a.orderNo) - parseFloat(b.orderNo));
    }

    const subjects = useSelector((state) => state.subjects);
    let subjectOptions = [];
    if(!subjects.loading) {
        subjectOptions = subjects.data;
    }

    const codingLanguages = useSelector((state) => state.codingLanguages);
    let codingLanguageOptions = [];
    if(!codingLanguages.loading) {
        //console.log(classes)
        codingLanguageOptions = codingLanguages.data;
    }

    //const subjectOptions = useSelector((state) => state.subjects.data);

    const countryOptions = useSelector((state) => state.countries.data);

    const stateOptions = useSelector((state) => state.states.data);

    const cityOptions = useSelector((state) => state.cities.data);

    const lgaOptions = useSelector((state) => state.lgas.data);

    //API CALLS END

    const formik = useFormik({
        initialValues: {
            firstName: "",
            lastName: "",
            email: "",
            phoneNo: "",
            country: "",
            state: "",
            lga: "",
            city: "",
            gender: "",
            address: "",
            educationalQualification: "",
            codingSkillsRating: "",
            onlineCodingTest: "",
            onlineTutoringExperience: "",
            personalLaptop: "",
            currentEmployment: "",
            expectations: ""
        },
        onSubmit: (values) => {
            // console.log(formik.values);
        },
        validationSchema: validationSchema
    });

    let flagStyles = { marginRight: '10px'};

    const [valid, setValid] = React.useState(false);
    const checkValidity = () => {
        // do what you want like on submit
        if (formik.isValid) {
            setValid(true);
        }
        else if(!formik.isValid) {
            setValid(false);
        }     
    }

    const [languageVal, setLanguageVal] = useState([]);
    const onLanguageValDelete = (title) => () => {
        setLanguageVal((languageVal) => languageVal?.filter((v) => v.name !== title));
    };

    const [classCat, setClassCat] = useState([]);
    const onClassCatDelete = (title) => () => {
        setClassCat((classCat) => classCat.filter((v) => v.title !== title));
    };

    const browserHistory = createBrowserHistory();

    const filter = createFilterOptions();
    const [countryValue, setCountryValue] = useState(null);
    const [stateValue, setStateValue] = useState(null);
    const [cityValue, setCityValue] = useState(null);
    const [lgaValue, setLgaValue] = useState(null);

    const [phone, setPhoneValue] = useState(null);

    const [loading, setLoading] = useState(false);


    //LESSON DAYS TO BE ARRAY & CLASSES ARRAY OF STRINGS & GOALS
    const submitForm = async () => {
        let formData = {
            firstName: formik.values.firstName,
            surname: formik.values.lastName,
            email: formik.values.email,
            phone: Number(phone),
            country: countryValue.name,
            state: stateValue.name,
            city: cityValue.name,
            lga: lgaValue ? lgaValue.title : "",
            address: formik.values.address,
            gender: formik.values.gender,
            qualification: formik.values.educationalQualification,
            codingSkill: formik.values.codingSkillsRating,
            onlineTest: formik.values.onlineCodingTest,
            codingExperience: formik.values.onlineTutoringExperience,
            ownComputer: formik.values.personalLaptop,
            codingClasses: languageVal.map( function( el ){ return el.id;}),
            expectations: formik.values.expectations,
            tutorType: "coding"
            // referredBy: formik.values.referral
        }
        //console.log(lgaValue);
        setLoading(true);

       await dispatch(sendCodingTutorReg(formData));

        setLoading(false);
        
        // formik.values.firstName = "";
        // formik.values.lastName = "";
        // formik.values.email = "";
        // setPhoneValue("");
        // setCountryValue("");
        // setStateValue("");
        // setCityValue("");
        // setLgaValue("");
        // formik.values.address = "";
        // formik.values.gender = "";
        // setLanguageVal([]);
        // formik.values.educationalQualification = "";
        // formik.values.codingSkillsRating = "";
        // formik.values.onlineCodingTest = "";
        // formik.values.onlineTutoringExperience = "";
        // formik.values.expectations = "";
    }

    return (
    
        <div className="page-content bkg-green-light pgx pgy">

            {/* <div className='blur1'></div>
            <div className='blur2'></div> */}

            <div onClick={browserHistory.back} className='backBtn extend'>
                <img src={BackBtn} alt="" /><span>Go Back</span>
            </div>

            <div className='row justify-content-center align-items-center'>
                <div className='col-lg-8'>
                    <div className='infoStrip'>
                        <img src={InfoIcon} alt="" />
                        <span>If you have previously completed stage 1 registration, click this button</span>
                        <a href='/verification'>Continue to stage 2</a>
                    </div>
                </div>
            </div>


            <div className='row formCont justify-content-center align-items-center'>
                <div className='col-lg-7'>
                    <div className='sec-title clr-orange mb-3 mb-lg-1'>Stage 1</div>
                    <div className='hdr xl mb-3 mb-lg-2'>Coding Tutor Registration Form</div>
                    <p>Simply fill this form to complete the first stage of your coding tutor registration on Nigenius.</p>

                    <div className='formWrap'>
                        <form>
                            <div className="fieldRow">
                                <div className='fieldHld'>
                                    <div className='label req'>First Name</div>
                                    <TextField
                                        required
                                        fullWidth
                                        className='textField'
                                        id="firstName"
                                        name="firstName" 
                                        type="text" 
                                        //placeholder="First Name"
                                        value={formik.values.firstName}
                                        onBlur={checkValidity}
                                        onChange={formik.handleChange}
                                        onFocus={formik.handleBlur}
                                        variant="outlined"
                                        error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                                        helperText={formik.touched.firstName && formik.errors.firstName}
                                    />
                                </div>

                                <div className='fieldHld'>
                                    <div className='label req'>Last Name</div>
                                    <TextField
                                        required
                                        fullWidth
                                        className='textField'
                                        id="lastName"
                                        name="lastName" 
                                        type="text" 
                                        //placeholder='Last Name'
                                        value={formik.values.lastName}
                                        onBlur={checkValidity}
                                        onChange={formik.handleChange}
                                        onFocus={formik.handleBlur}
                                        variant="outlined"
                                        error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                                        helperText={formik.touched.lastName && formik.errors.lastName}
                                        // sx={{
                                        //     '& .MuiInputBase-root.MuiInput-root:before': {
                                        //         borderBottom: '1px solid rgba(255, 255, 255, 0.74)'
                                        //     }
                                        // }}
                                    />
                                </div>
                            </div>

                            <div className='fieldRow'>
                                <div className='fieldHld'>
                                    <div className='label req'>Email</div>
                                    <TextField
                                        required
                                        fullWidth
                                        className='textField'
                                        id="email"
                                        name="email" 
                                        type="email" 
                                        //placeholder='Email'
                                        value={formik.values.email}
                                        onBlur={checkValidity}
                                        onChange={formik.handleChange}
                                        onFocus={formik.handleBlur}
                                        variant="outlined"
                                        error={formik.touched.email && Boolean(formik.errors.email)}
                                        helperText={formik.touched.email && formik.errors.email}
                                    />
                                </div>

                                <div className='fieldHld'>
                                    <div className='label req'>Phone Number(Whatsapp)</div>
                                    <PhoneInput
                                        country={'ng'}
                                        value={phone}
                                        onChange={phone => setPhoneValue(phone)}
                                    />
                                </div>
                            </div>

                            <div className='fieldRow'>

                                <div className="fieldHld cFull">
                                    <div className='label req'>Gender</div>
                                    <TextField
                                        required
                                        fullWidth
                                        select
                                        variant="outlined"
                                        id="gender"
                                        name="gender" 
                                        value={formik.values.gender}
                                        onBlur={checkValidity}
                                        onChange={formik.handleChange}
                                        onFocus={formik.handleBlur}
                                        error={formik.touched.lga && Boolean(formik.errors.gender)}
                                        helperText={formik.touched.lga && formik.errors.gender}
                                        sx={{
                                            '& .MuiSvgIcon-root': {
                                                color: "rgba(0, 0, 0, 0.74)"
                                            },
                                            '& .MuiInputBase-root': {
                                                backgroundColor: '#FFF',
                                            },
                                            '& .MuiSelect-select': {
                                                border: '1px solid rgba(0, 0, 0, 0.3)'
                                            }
                                        }}
                                    >
                                        <MenuItem key={"Male"} value={"Male"}>Male</MenuItem>
                                        <MenuItem key={"Female"} value={"Female"}>Female</MenuItem>
                                        <MenuItem key={"Prefer not to say"} value={"Prefer not to say"}>Prefer not to say</MenuItem>
                                    </TextField> 
                                </div>

                            </div>

                            <div className='fieldRow'>
                                <div className='fieldHld'>
                                    <div className='label req'>Country</div>
                                    <Autocomplete
                                        value={countryValue}
                                        onChange={(event, newValue) => {
                                            // console.log(newValue);
                                            if (typeof newValue === 'string') {
                                                setCountryValue({
                                                    title: newValue,
                                                });
                                            } else if (newValue && newValue.inputValue) {
                                                // Create a new value from the user input
                                                setCountryValue({
                                                    title: newValue.inputValue,
                                                });
                                            } else {
                                                setCountryValue(newValue);
                                            }
                                            dispatch(getStates(newValue.isoCode));
                                        }}
                                        filterOptions={(countryOptions, params) => {
                                            const filtered = filter(countryOptions, params);

                                            const { inputValue } = params;
                                            // Suggest the creation of a new value
                                            const isExisting = countryOptions.some((country) => inputValue === country.label);
                                            if (inputValue !== '' && !isExisting) {
                                                filtered.push({
                                                    inputValue,
                                                    title: `Add "${inputValue}"`,
                                                });
                                            }
                                            return filtered;
                                        }}
                                        selectOnFocus
                                        clearOnBlur
                                        handleHomeEndKeys
                                        id="country-selection"
                                        options={countryOptions}
                                        getOptionLabel={(country) => {
                                            // Value selected with enter, right from the input
                                            if (typeof country === 'string') {
                                                return country;
                                            }
                                            // Add "xxx" option created dynamically
                                            if (country.inputValue) {
                                                return country.inputValue;
                                            }
                                            // Regular option
                                            return country.name;
                                        }}
                                        renderOption={(props, country) =><li {...props}>{country.name}</li>}
                                        sx={{ width: '100%' }}
                                        freeSolo
                                        renderInput={(params) => (
                                            <TextField
                                                variant="outlined"
                                                id="country"
                                                name="country" 
                                                {...params}
                                                error={formik.touched.country && Boolean(formik.errors.country)}
                                                helperText={formik.touched.country && formik.errors.country}
                                                sx={{
                                                    '& .MuiSvgIcon-root': {
                                                        color: "rgba(0, 0, 0, 0.74)"
                                                    },
                                                    '& .MuiInputBase-root': {
                                                        backgroundColor: '#FFF',
                                                        border: '1px solid rgba(0, 0, 0, 0.5)'
                                                    },
                                                    '& .MuiSelect-select': {
                                                        border: '1px solid rgba(0, 0, 0, 0.3)'
                                                    }
                                                }}
                                            />
                                        )}
                                    />

                                </div>
                            </div>

                            <div className='fieldRow'>

                                <div className='fieldHld'>
                                    <div className='label req'>State of Residence</div>
                                    <Autocomplete
                                        value={stateValue}
                                        onChange={(event, newValue) => {
                                            // console.log(newValue);
                                            if (typeof newValue === 'string') {
                                                setStateValue({
                                                    title: newValue,
                                                });
                                            } else if (newValue && newValue.inputValue) {
                                                // Create a new value from the user input
                                                setStateValue({
                                                    title: newValue.inputValue,
                                                });
                                            } else {
                                                setStateValue(newValue);
                                            }
                                            dispatch(getCities(countryValue.isoCode, newValue.isoCode));
                                            cityOptions.push({
                                                name: 'Other',
                                                stateCode: '',
                                                countryCode: ''
                                            })
                                            if(countryValue.isoCode == 'NG') {
                                                if(newValue.name == 'Abuja Federal Capital Territory') {
                                                    dispatch(getLgas('Federal Capital Territory'));
                                                }
                                                else {
                                                    dispatch(getLgas(newValue.name));
                                                }
                                            }
                                        }}
                                        filterOptions={(stateOptions, params) => {
                                            const filtered = filter(stateOptions, params);

                                            const { inputValue } = params;
                                            // Suggest the creation of a new value
                                            const isExisting = stateOptions.some((state) => inputValue === state.name);
                                            // if (inputValue !== '' && !isExisting) {
                                            //     filtered.push({
                                            //         inputValue,
                                            //         title: `Add "${inputValue}"`,
                                            //     });
                                            // }
                                            return filtered;
                                        }}
                                        selectOnFocus
                                        clearOnBlur
                                        handleHomeEndKeys
                                        id="state-selection"
                                        options={stateOptions}
                                        getOptionLabel={(state) => {
                                            // Value selected with enter, right from the input
                                            if (typeof state === 'string') {
                                                return state;
                                            }
                                            // Add "xxx" option created dynamically
                                            if (state.inputValue) {
                                                return state.inputValue;
                                            }
                                            // Regular option
                                            return state.name;
                                        }}
                                        renderOption={(props, state) =><li {...props}>{state.name}</li>}
                                        sx={{ width: '100%' }}
                                        freeSolo
                                        renderInput={(params) => (
                                            <TextField
                                                variant="outlined"
                                                id="state"
                                                name="state" 
                                                {...params}
                                                error={formik.touched.state && Boolean(formik.errors.state)}
                                                helperText={formik.touched.state && formik.errors.state}
                                                sx={{
                                                    '& .MuiSvgIcon-root': {
                                                        color: "rgba(0, 0, 0, 0.74)"
                                                    },
                                                    '& .MuiInputBase-root': {
                                                        backgroundColor: '#FFF',
                                                        border: '1px solid rgba(0, 0, 0, 0.5)'
                                                    },
                                                    '& .MuiSelect-select': {
                                                        border: '1px solid rgba(0, 0, 0, 0.3)'
                                                    }
                                                }}
                                            />
                                        )}
                                    />

                                </div>

                                <div className='fieldHld'>
                                    <div className='label req'>City</div>
                                    <Autocomplete
                                        value={cityValue}
                                        onChange={(event, newValue) => {
                                            // console.log(newValue);
                                            if (typeof newValue === 'string') {
                                                setCityValue({
                                                    name: newValue,
                                                });
                                            } else if (newValue && newValue.inputValue) {
                                                // Create a new value from the user input
                                                setCityValue({
                                                    name: newValue.inputValue,
                                                });
                                            } else {
                                                setCityValue(newValue);
                                            }                                           
                                        }}
                                        onBlur={(e) => {
                                            setCityValue({
                                                name: e.target.value
                                            });
                                        }}
                                        filterOptions={(cityOptions, params) => {
                                            const filtered = filter(cityOptions, params);

                                            const { inputValue } = params;
                                            // Suggest the creation of a new value
                                            // const isExisting = cityOptions.some((city) => inputValue === city.name);
                                            // if (inputValue !== '' && !isExisting) {
                                            //     filtered.push({
                                            //         inputValue,
                                            //         title: `Add "${inputValue}"`,
                                            //     });
                                            // }
                                            return filtered;
                                        }}
                                        selectOnFocus
                                        //clearOnBlur
                                        handleHomeEndKeys
                                        id="city-selection"
                                        options={cityOptions}
                                        getOptionLabel={(city) => {
                                            // Value selected with enter, right from the input
                                            if (typeof city === 'string') {
                                                return city;
                                            }
                                            // Add "xxx" option created dynamically
                                            if (city.inputValue) {
                                                return city.inputValue;
                                            }
                                            // Regular option
                                            return city.name;
                                        }}
                                        renderOption={(props, city) =><li {...props}>{city.name}</li>}
                                        sx={{ width: '100%' }}
                                        freeSolo
                                        renderInput={(params) => (
                                            <TextField
                                                variant="outlined"
                                                id="city"
                                                name="city" 
                                                {...params}
                                                error={formik.touched.city && Boolean(formik.errors.city)}
                                                helperText={formik.touched.city && formik.errors.city}
                                                sx={{
                                                    '& .MuiSvgIcon-root': {
                                                        color: "rgba(0, 0, 0, 0.74)"
                                                    },
                                                    '& .MuiInputBase-root': {
                                                        backgroundColor: '#FFF',
                                                        border: '1px solid rgba(0, 0, 0, 0.5)'
                                                    },
                                                    '& .MuiSelect-select': {
                                                        border: '1px solid rgba(0, 0, 0, 0.3)'
                                                    }
                                                }}
                                            />
                                        )}
                                    />

                                </div>

                            </div>

                            <div className='fieldRow'>

                                <div className='fieldHld' style={{display: countryValue?.isoCode=='NG' ? 'block' : 'none' }}>
                                    <div className='label req'>LGA</div>
                                    <Autocomplete
                                        value={lgaValue}
                                        onChange={(event, newValue) => {
                                            // console.log(newValue);
                                            if (typeof newValue === 'string') {
                                                setLgaValue({
                                                    title: newValue,
                                                });
                                            } else if (newValue && newValue.inputValue) {
                                                // Create a new value from the user input
                                                setLgaValue({
                                                    title: newValue.inputValue,
                                                });
                                            } else {
                                                setLgaValue(newValue);
                                            }
                                            
                                        }}
                                        filterOptions={(lgaOptions, params) => {
                                            const filtered = filter(lgaOptions, params);

                                            const { inputValue } = params;
                                            // Suggest the creation of a new value
                                            const isExisting = lgaOptions.some((lga) => inputValue === lga.name);
                                            // if (inputValue !== '' && !isExisting) {
                                            //     filtered.push({
                                            //         inputValue,
                                            //         title: `Add "${inputValue}"`,
                                            //     });
                                            // }
                                            return filtered;
                                        }}
                                        selectOnFocus
                                        clearOnBlur
                                        handleHomeEndKeys
                                        id="lga-selection"
                                        options={lgaOptions}
                                        getOptionLabel={(lga) => {
                                            // Value selected with enter, right from the input
                                            if (typeof lga === 'string') {
                                                return lga;
                                            }
                                            // Add "xxx" option created dynamically
                                            if (lga.inputValue) {
                                                return lga.inputValue;
                                            }
                                            // Regular option
                                            return lga.title;
                                        }}
                                        renderOption={(props, lga) =><li {...props}>{lga}</li>}
                                        sx={{ width: '100%' }}
                                        freeSolo
                                        renderInput={(params) => (
                                            <TextField
                                                variant="outlined"
                                                id="lga"
                                                name="lga" 
                                                {...params}
                                                error={formik.touched.lga && Boolean(formik.errors.lga)}
                                                helperText={formik.touched.lga && formik.errors.lga}
                                                sx={{
                                                    '& .MuiSvgIcon-root': {
                                                        color: "rgba(0, 0, 0, 0.74)"
                                                    },
                                                    '& .MuiInputBase-root': {
                                                        backgroundColor: '#FFF',
                                                        border: '1px solid rgba(0, 0, 0, 0.5)'
                                                    },
                                                    '& .MuiSelect-select': {
                                                        border: '1px solid rgba(0, 0, 0, 0.3)'
                                                    }
                                                }}
                                            />
                                        )}
                                    />
                                </div>

                            </div>

                            <div className="fieldRow">
                                <div className='fieldHld'>
                                    <div className='label req'>Address</div>
                                    <TextField
                                        required
                                        fullWidth
                                        className='textField'
                                        id="address"
                                        name="address" 
                                        type="text" 
                                        value={formik.values.address}
                                        onBlur={checkValidity}
                                        onChange={formik.handleChange}
                                        onFocus={formik.handleBlur}
                                        variant="outlined"
                                        error={formik.touched.address && Boolean(formik.errors.address)}
                                        helperText={formik.touched.address && formik.errors.address}
                                        // sx={{
                                        //     '& .MuiInputBase-root.MuiInput-root:before': {
                                        //         borderBottom: '1px solid rgba(255, 255, 255, 0.74)'
                                        //     }
                                        // }}
                                    />
                                </div>

                            </div>

                            <div className='fieldRow'>
                                <div className='fieldHld'>
                                    <div className='label req'>What is your educational qualification?</div>
                                    <TextField
                                        required
                                        fullWidth
                                        select
                                        variant="outlined"
                                        id="educationalQualification"
                                        name="educationalQualification" 
                                        value={formik.values.educationalQualification}
                                        onBlur={checkValidity}
                                        onChange={formik.handleChange}
                                        onFocus={formik.handleBlur}
                                        error={formik.touched.educationalQualification && Boolean(formik.errors.educationalQualification)}
                                        helperText={formik.touched.educationalQualification && formik.errors.educationalQualification}
                                        sx={{
                                            '& .MuiSvgIcon-root': {
                                                color: "rgba(0, 0, 0, 0.74)"
                                            },
                                            '& .MuiInputBase-root': {
                                                backgroundColor: '#FFF',
                                            },
                                            '& .MuiSelect-select': {
                                                border: '1px solid rgba(0, 0, 0, 0.3)'
                                            }
                                        }}
                                    >
                                        <MenuItem key={"Bsc/Hnd/Ond"} value={"Bsc/Hnd/Ond"}>Bsc/Hnd/Ond</MenuItem>
                                        <MenuItem key={"Masters"} value={"Masters"}>Masters</MenuItem>
                                        <MenuItem key={"PhD"} value={"PhD"}>PhD</MenuItem>
                                        <MenuItem key={"Still in school"} value={"Still in school"}>Still in school</MenuItem>
                                        <MenuItem key={"None of the above"} value={"None of the above"}>None of the above</MenuItem>
                                    </TextField> 
                                </div>
                            </div>

                            <div className='fieldRow'>
                                <div className='fieldHld auto'>
                                    <div className='label req'>Select the coding languages you can teach excellently.</div>
                                    <Box>
                                        <Autocomplete
                                            multiple
                                            options={codingLanguageOptions}
                                            defaultValue={[codingLanguageOptions[0]]}
                                            getOptionLabel={(option) => option.name}
                                            value={languageVal}
                                            onChange={(e, newValue) => setLanguageVal(newValue)}
                                            renderTags={() => null}
                                            renderInput={(params) => (
                                                <TextField {...params} variant="outlined" placeholder="Pick coding language(s)" />
                                            )}
                                            sx={{
                                                '& .MuiSvgIcon-root': {
                                                    color: "rgba(0, 0, 0, 0.74)"
                                                },
                                                '& .MuiInputBase-root': {
                                                    backgroundColor: '#FFF',
                                                    border: '1px solid rgba(0, 0, 0, 0.4)'
                                                },
                                            }}
                                        />
                                        <Box
                                            mt={2}
                                            sx={{
                                                '& > :not(:last-child)': { mr: 1 },
                                                '& > *': { mr: 1 },
                                            }}
                                        >
                                            {languageVal.map((v) => (
                                                <Chip 
                                                    key={v.name} 
                                                    label={v.name} 
                                                    onDelete={onLanguageValDelete(v.name)}
                                                    sx={{
                                                        '&.MuiButtonBase-root.MuiChip-root': {
                                                            backgroundColor: '#F6FFFC',
                                                            border: '0.1rem solid #01C38E',
                                                            marginBottom: '0.3rem'
                                                        },
                                                        '& .MuiSvgIcon-root': {
                                                            color: '#01C38E',
                                                        },
                                                    }}
                                                />
                                            ))}
                                        </Box>
                                    </Box>
                                </div>
                            </div>

                            <div className='fieldRow'>
                                <div className='fieldHld'>
                                    <div className='label req'>How would you rate your Coding skills ?</div>
                                    <TextField
                                        required
                                        fullWidth
                                        select
                                        variant="outlined"
                                        id="codingSkillsRating"
                                        name="codingSkillsRating" 
                                        value={formik.values.codingSkillsRating}
                                        onBlur={checkValidity}
                                        onChange={formik.handleChange}
                                        onFocus={formik.handleBlur}
                                        error={formik.touched.codingSkillsRating && Boolean(formik.errors.codingSkillsRating)}
                                        helperText={formik.touched.codingSkillsRating && formik.errors.codingSkillsRating}
                                        sx={{
                                            '& .MuiSvgIcon-root': {
                                                color: "rgba(0, 0, 0, 0.74)"
                                            },
                                            '& .MuiInputBase-root': {
                                                backgroundColor: '#FFF',
                                            },
                                            '& .MuiSelect-select': {
                                                border: '1px solid rgba(0, 0, 0, 0.3)'
                                            }
                                        }}
                                    >
                                        <MenuItem key={"Beginner"} value={"Beginner"}>Beginner</MenuItem>
                                        <MenuItem key={"Intermediate"} value={"Intermediate"}>Intermediate</MenuItem>
                                        <MenuItem key={"Expert"} value={"Expert"}>Expert</MenuItem>
                                    </TextField> 
                                </div>
                            </div>

                            <div className='fieldRow'>
                                <div className='fieldHld'>
                                    <div className='label req'>Are you currently employed ?</div>
                                    <TextField
                                        required
                                        fullWidth
                                        select
                                        variant="outlined"
                                        id="currentEmployment"
                                        name="currentEmployment" 
                                        value={formik.values.currentEmployment}
                                        onBlur={checkValidity}
                                        onChange={formik.handleChange}
                                        onFocus={formik.handleBlur}
                                        error={formik.touched.currentEmployment && Boolean(formik.errors.currentEmployment)}
                                        helperText={formik.touched.currentEmployment && formik.errors.currentEmployment}
                                        sx={{
                                            '& .MuiSvgIcon-root': {
                                                color: "rgba(0, 0, 0, 0.74)"
                                            },
                                            '& .MuiInputBase-root': {
                                                backgroundColor: '#FFF',
                                            },
                                            '& .MuiSelect-select': {
                                                border: '1px solid rgba(0, 0, 0, 0.3)'
                                            }
                                        }}
                                    >
                                        <MenuItem key={"Yes"} value={"SSCE"}>Yes</MenuItem>
                                        <MenuItem key={"No"} value={"No"}>No</MenuItem>
                                    </TextField> 
                                </div>
                            </div>

                            <div className='fieldRow'>
                                <div className='fieldHld'>
                                    <div className='label req'>Do you have online coding tutoring experience with kids ?</div>
                                    <TextField
                                        required
                                        fullWidth
                                        select
                                        variant="outlined"
                                        id="onlineTutoringExperience"
                                        name="onlineTutoringExperience" 
                                        value={formik.values.onlineTutoringExperience}
                                        onBlur={checkValidity}
                                        onChange={formik.handleChange}
                                        onFocus={formik.handleBlur}
                                        error={formik.touched.onlineTutoringExperience && Boolean(formik.errors.onlineTutoringExperience)}
                                        helperText={formik.touched.onlineTutoringExperience && formik.errors.onlineTutoringExperience}
                                        sx={{
                                            '& .MuiSvgIcon-root': {
                                                color: "rgba(0, 0, 0, 0.74)"
                                            },
                                            '& .MuiInputBase-root': {
                                                backgroundColor: '#FFF',
                                            },
                                            '& .MuiSelect-select': {
                                                border: '1px solid rgba(0, 0, 0, 0.3)'
                                            }
                                        }}
                                    >
                                        <MenuItem key={"Yes"} value={"SSCE"}>Yes</MenuItem>
                                        <MenuItem key={"No"} value={"No"}>No</MenuItem>
                                    </TextField> 
                                </div>
                            </div>

                            <div className='fieldRow'>
                                <div className='fieldHld'>
                                    <div className='label req'>Do you have your own personal laptop ?</div>
                                    <TextField
                                        required
                                        fullWidth
                                        select
                                        variant="outlined"
                                        id="personalLaptop"
                                        name="personalLaptop" 
                                        value={formik.values.personalLaptop}
                                        onBlur={checkValidity}
                                        onChange={formik.handleChange}
                                        onFocus={formik.handleBlur}
                                        error={formik.touched.personalLaptop && Boolean(formik.errors.personalLaptop)}
                                        helperText={formik.touched.personalLaptop && formik.errors.personalLaptop}
                                        sx={{
                                            '& .MuiSvgIcon-root': {
                                                color: "rgba(0, 0, 0, 0.74)"
                                            },
                                            '& .MuiInputBase-root': {
                                                backgroundColor: '#FFF',
                                            },
                                            '& .MuiSelect-select': {
                                                border: '1px solid rgba(0, 0, 0, 0.3)'
                                            }
                                        }}
                                    >
                                        <MenuItem key={"Yes"} value={"SSCE"}>Yes</MenuItem>
                                        <MenuItem key={"No"} value={"No"}>No</MenuItem>
                                    </TextField> 
                                </div>
                            </div>

                            <div className='fieldRow'>
                                <div className='fieldHld'>
                                    <div className='label req'>Would you be willing to take an Online Coding Test to prove your competency ?</div>
                                    <TextField
                                        required
                                        fullWidth
                                        select
                                        variant="outlined"
                                        id="onlineCodingTest"
                                        name="onlineCodingTest" 
                                        value={formik.values.onlineCodingTest}
                                        onBlur={checkValidity}
                                        onChange={formik.handleChange}
                                        onFocus={formik.handleBlur}
                                        error={formik.touched.onlineCodingTest && Boolean(formik.errors.onlineCodingTest)}
                                        helperText={formik.touched.onlineCodingTest && formik.errors.onlineCodingTest}
                                        sx={{
                                            '& .MuiSvgIcon-root': {
                                                color: "rgba(0, 0, 0, 0.74)"
                                            },
                                            '& .MuiInputBase-root': {
                                                backgroundColor: '#FFF',
                                            },
                                            '& .MuiSelect-select': {
                                                border: '1px solid rgba(0, 0, 0, 0.3)'
                                            }
                                        }}
                                    >
                                        <MenuItem key={"Yes"} value={"SSCE"}>Yes</MenuItem>
                                        <MenuItem key={"No"} value={"No"}>No</MenuItem>
                                    </TextField> 
                                </div>
                            </div>

                            <div className='fieldRow'>

                                <div className='fieldHld lg'>
                                    <div className='label'>What are your expectations from signing up as a tutor on Nigenius ?</div>
                                    <TextField
                                        fullWidth
                                        multiline
                                        rows={3}
                                        maxRows={7}
                                        className='textField'
                                        id="expectations"
                                        name="expectations" 
                                        type="text"
                                        placeholder='Enter Message' 
                                        value={formik.values.expectations}
                                        onBlur={checkValidity}
                                        onChange={formik.handleChange}
                                        onFocus={formik.handleBlur}
                                        variant="outlined"
                                        error={formik.touched.expectations && Boolean(formik.errors.expectations)}
                                        helperText={formik.touched.expectations && formik.errors.expectations}
                                        sx={{
                                            '& .MuiInputBase-root': {
                                                backgroundColor: '#FFF',
                                                padding: '0px',
                                                //border: '1px solid rgba(0, 0, 0, 0.3)'
                                            }
                                        }}
                                    />
                                </div>
                            </div>

                            <div className='fieldRow'>
                                {/* <Button
                                    onClick={submitForm}
                                    className="hasIcon mainCta extra"
                                    fullWidth 
                                    type="button"
                                    variant="contained"
                                >Continue to Stage 2 Form
                                </Button> */}
                                 <Button
                                    onClick={submitForm}
                                    className="hasIcon mainCta extra"
                                    fullWidth 
                                    type="submit"
                                    variant="contained"
                                    disabled={loading} // Disable the button while loading
                                >
                                    {loading ? <CircularProgress size={24} color='primary' /> : 'Continue to Stage 2 Form'}
                                </Button>
                                <ToastContainer
                                    position="top-center"
                                    autoClose={5000}
                                    hideProgressBar={false}
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnFocusLoss
                                    draggable
                                    pauseOnHover
                                    theme="light"
                                />
                            </div>
                        </form>

                    </div>
                </div>
                
            </div>



        </div>
    
    );
};

export default CodingTutorRegFormPage;