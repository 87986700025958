import * as React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import CodingReqFormPage from '../components/CodingReqFormPage';

const CodingReqForm = () => {   

  return (
    
    <div className="page-container">
        <Header className='header bkg-green-light' />
        <CodingReqFormPage />
        <Footer />
    </div>
    
  );
};

export default CodingReqForm;