import { POST_SCHOOL_CODING_REQ, POST_SCHOOL_CODING_REQ_ERROR } from '../actions/types';

const initialState = {
    data: null,
    loading: true,
    error: ""
}

export default function(state = initialState, action) {

    const { type, payload } = action;

    switch(type) {

        case POST_SCHOOL_CODING_REQ:
            return {
                ...state,
                data: payload,
                loading: false,
            }

        case POST_SCHOOL_CODING_REQ_ERROR:
            return {
                ...state,
                error: payload,
                loading: false
            }

        default:
            return state;
    }
   
}

