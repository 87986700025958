import React from "react";
import { Routes, Route } from "react-router-dom";
import { createBrowserHistory } from 'history';
import ScrollToTop from "../components/ScrollToTop";

import Home from "../pages/Home";
import About from "../pages/About";
import CodingRobotics from "../pages/CodingRobotics";
import Tutor from "../pages/Tutor";
import Contact from "../pages/Contact";
import Privacy from "../pages/Privacy";
import TeachingResources from "../pages/TeachingResources";
import Sms from "../pages/Sms";
import SubjectTutorRegForm from "../pages/SubjectTutorRegForm";
import SubjectTutorRegForm2 from "../pages/SubjectTutorRegForm2";
import SubjectReqForm from "../pages/SubjectReqForm";
import CodingTutorRegForm from "../pages/CodingTutorRegForm";
import CodingReqForm from "../pages/CodingReqForm";
import CodingSchoolReqForm from "../pages/CodingSchoolReqForm";
import CodingTutorRegForm2 from "../pages/CodingTutorRegForm2";
import Verification from "../pages/Verification";
import CheckVerification from "../pages/CheckVerification";
import SmsDemo from "../pages/SmsDemo";


const browserHistory = createBrowserHistory();

const PageRoutes = () => {

  return (

    <Routes history={browserHistory}>

      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/coding-robotics" element={<CodingRobotics />} />
      <Route path="/tutors" element={<Tutor />} />
      <Route path="/teaching-resources" element={<TeachingResources />} />
      <Route path="/school-management-system" element={<Sms />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/privacy-policy" element={<Privacy />} />

      <Route path="/subject-tutor-registration-form" element={<SubjectTutorRegForm />} />
      <Route path="/coding-tutor-registration-form" element={<CodingTutorRegForm />} />
      <Route path="/subject-tutor-request-form" element={<SubjectReqForm />} />
      <Route path="/coding-tutor-request-form" element={<CodingReqForm />} />
      <Route path="/coding-tutor-school-request-form" element={<CodingSchoolReqForm />} />

      <Route path="/verification" element={<Verification />} />
      <Route path="/subject-tutor-registration-form-stage-2" element={<SubjectTutorRegForm2 />} />
      <Route path="/coding-tutor-registration-form-stage-2" element={<CodingTutorRegForm2 />} />

      <Route path="/tutor-registration-form-stage-2/:codeId" element={<CheckVerification />} />
      <Route path="/school-management-system-demo" element={<SmsDemo />} />

    </Routes>
  );
};

export default PageRoutes;