import { GET_CITIES, GET_CITIES_ERROR } from '../actions/types';

const initialState = {
    data: null,
    loading: true,
    error: ""
}

export default function(state = initialState, action) {

    const { type, payload } = action;

    switch(type) {

        case GET_CITIES:
        return {
            ...state,
            data: payload.data,
            loading: false
        }

        case GET_CITIES_ERROR:
        return {
            ...state,
            error: payload.error,
            loading: false
        }

        default:
        return state;
    }
   
}

