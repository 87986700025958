import logo from './logo.svg';
import './assets/css/styles';
import  store  from './store';
import { Provider } from 'react-redux';
import PageRoutes from "./routes/Routes";

function App() {
  return (
    <Provider store={store}>
      <div>
        <PageRoutes />
      </div>
      </Provider>
  );
}

export default App;
