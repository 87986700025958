import * as React from "react";
import { useState, useEffect } from "react";
import { createBrowserHistory } from "history";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import {
  getClasses,
  getSubjects,
  getExams,
  getCountries,
  getStates,
  getCities,
  getLgas,
  sendSubjectTutorReq,
} from "../actions/formdata";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useFormik } from "formik";
import * as yup from "yup";

import BackBtn from "../assets/img/brand/back-arrow.svg";

import {
  TextField,
  MenuItem,
  Chip,
  Autocomplete,
  Checkbox,
  Box,
  Button,
  createFilterOptions,
  FormControlLabel,
} from "@mui/material";

import { Countries } from "../content";
import { Subjects } from "../content";
import { Exams } from "../content";
import { Classes } from "../content";
import { Days } from "../content";
import { Times } from "../content";
import { Goals } from "../content";

const validationSchema = yup.object({
  firstName: yup.string().required("This is a required field"),
  lastName: yup.string().required("This is a required field"),
  email: yup
    .string()
    .email("Please insert a valid email")
    .required("This field is required"),
  // phoneNo: yup.string().required("This is a required field"),
  classType: yup.string().required("This is a required field"),
  // country: yup.string().required("This is a required field"),
  // state: yup.string().required("This is a required field"),
  // lga: yup.string().required("This is a required field"),
  // city: yup.string().required("This is a required field"),
  noOfChildren: yup.number().required("This is a required field"),
  gender: yup.string().required("This is a required field"),
  // specialExamServices: yup.string().required("This is a required field"),
  address: yup.string().required("This is a required field"),
  lessonTimes: yup.string().required("This is a required field"),
  message: yup.string(),
  referredBy: yup.string(),
});

//const subjects = Subjects;
//const exams = Exams;
const classes = Classes;
const days = Days;
const times = Times;
const goals = Goals;

const SubjectReqFormPage = () => {
  //API CALLS

  const dispatch = useDispatch();
  // const stateValues = useSelector((state) => state);
  useEffect(() => {
    dispatch(getClasses());
    dispatch(getSubjects());
    dispatch(getExams());
    dispatch(getCountries());
    dispatch(getStates("NG"));
    dispatch(getCities("NG", "LA"));
    dispatch(getLgas("Lagos"));
  }, []);
  // useEffect(() => {
  //     console.log(stateValues.subjectTutorReq);
  // }, [stateValues]);

  const classes = useSelector((state) => state.classes);
  let classesOptions = [];
  if (!classes.loading) {
    //console.log(classes)
    classesOptions = classes.data.sort(
      (a, b) => parseFloat(a.orderNo) - parseFloat(b.orderNo)
    );
  }

  const subjects = useSelector((state) => state.subjects);
  let subjectOptions = [];
  if (!subjects.loading) {
    //console.log(classes)
    subjectOptions = subjects.data;
  }

  const exams = useSelector((state) => state.exams);
  let examOptions = [];
  if (!exams.loading) {
    //console.log(classes)
    examOptions = exams.data;
  }

  //const subjectOptions = useSelector((state) => state.subjects.data);

  const countryOptions = useSelector((state) => state.countries.data);

  const stateOptions = useSelector((state) => state.states.data);

  const cityOptions = useSelector((state) => state.cities.data);

  const lgaOptions = useSelector((state) => state.lgas.data);

  //API CALLS END

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      // phoneNo: "",
      classType: "",
      // country: "",
      // state: "",
      // lga: "",
      // city: "",
      noOfChildren: "",
      gender: "",
      specialExamServices: "",
      address: "",
      lessonTimes: "",
      message: "",
      referredBy: "",
    },
    onSubmit: (values) => {
      //   console.log(formik.values);
    },
    validationSchema: validationSchema,
  });

  let flagStyles = { marginRight: "10px" };

  const [valid, setValid] = React.useState(false);
  const checkValidity = () => {
    // do what you want like on submit
    if (formik.isValid) {
      setValid(true);
    } else if (!formik.isValid) {
      setValid(false);
    }
  };

  const [subjectVal, setSubjectVal] = useState([]);
  const onDelete = (title) => () => {
    setSubjectVal((subjectVal) => subjectVal.filter((v) => v.name !== title));
  };

  const [lessonDays, setLessonDays] = useState([]);
  const onLessonDaysDelete = (title) => () => {
    setLessonDays((lessonDays) => lessonDays.filter((v) => v.title !== title));
  };

  const [classCat, setClassCat] = useState([]);
  const onClassCatDelete = (title) => () => {
    setClassCat((classCat) => classCat.filter((v) => v.name !== title));
  };

  const [lessonTimes, setLessonTimes] = useState([]);
  const onLessonTimesDelete = (title) => () => {
    setLessonTimes((lessonTimes) =>
      lessonTimes.filter((v) => v.title !== title)
    );
  };

  const [examServices, setExamServices] = useState([]);
  const onExamServicesDelete = (title) => () => {
    setExamServices((examServices) =>
      examServices.filter((v) => v.name !== title)
    );
  };

  const [lessonGoals, setLessonGoals] = useState([]);
  const onLessonGoalsDelete = (title) => () => {
    setLessonGoals((lessonGoals) =>
      lessonGoals.filter((v) => v.title !== title)
    );
  };

  const filter = createFilterOptions();
  const [countryValue, setCountryValue] = useState(null);
  const [stateValue, setStateValue] = useState(null);
  const [cityValue, setCityValue] = useState(null);
  const [lgaValue, setLgaValue] = useState(null);

  const [phone, setPhoneValue] = useState(null);

  const browserHistory = createBrowserHistory();

  //LESSON DAYS TO BE ARRAY & CLASSES ARRAY OF STRINGS & GOALS
  const submitForm = () => {
    let errorcount = false;
    for (const key in formik.errors) {
      toast.error(`${key} : ${formik.errors[key]}`, {
        position: "top-right",
        theme: "colored",
      });
      errorcount = true;
    }

    if (countryValue == null) {
      toast.error("Select a country", {
        position: "top-right",
        theme: "colored",
      });
      errorcount = true;
    }

    if (stateValue == null) {
      toast.error("Select a state", {
        position: "top-right",
        theme: "colored",
      });
      errorcount = true;
    }

    if (cityValue == null) {
      toast.error("Select a city", {
        position: "top-right",
        theme: "colored",
      });
      errorcount = true;
    }

    if (phone == null) {
      toast.error("Enter a phone number", {
        position: "top-right",
        theme: "colored",
      });
      errorcount = true;
    }

    if (!subjectVal.length) {
      toast.error("Select a subject or subjects", {
        position: "top-right",
        theme: "colored",
      });
      errorcount = true;
    }

    if (!classCat.length) {
      toast.error("Select a class", {
        position: "top-right",
        theme: "colored",
      });
      errorcount = true;
    }

    if (!lessonDays.length) {
      toast.error("Select lesson days", {
        position: "top-right",
        theme: "colored",
      });
      errorcount = true;
    }

    if (!lessonGoals.length) {
      toast.error("Select lesson goals", {
        position: "top-right",
        theme: "colored",
      });
      errorcount = true;
    }

    if (!errorcount) {
      let formData = {
        firstName: formik.values.firstName,
        lastName: formik.values.lastName,
        email: formik.values.email,
        phone: Number(phone),
        classType: formik.values.classType,
        country: countryValue.name,
        state: stateValue.name,
        city: cityValue.name,
        lga: lgaValue ? lgaValue.title : "",
        address: formik.values.address,
        tutorGenderPrefer: formik.values.gender,
        //codingClasses: languageVal.map( function( el ){ return el.name;}),
        classes: classCat.map(function (el) {
          return el.classId;
        }),
        subjects: subjectVal.map(function (el) {
          return el.subjectId;
        }),
        childrenCount: formik.values.noOfChildren,
        exams: examServices.map(function (el) {
          return el.id;
        }),
        tutoringDays: lessonDays.map(function (el) {
          return el.title;
        }),
        goals: lessonGoals.map(function (el) {
          return el.title;
        }),
        tutoringTime: formik.values.lessonTimes,
        extraInfo: formik.values.message,
        referredBy: formik.values.referredBy,
        tutorType: "subject",
      };
      // console.log(formik);

      const disp = dispatch(sendSubjectTutorReq(formData));
      // console.log("Server response");
      // console.log(disp.status);

      formik.values.firstName = "";
      formik.values.lastName = "";
      formik.values.email = "";
      setPhoneValue("");
      setCountryValue("");
      setStateValue("");
      setCityValue("");
      setLgaValue("");
      formik.values.address = "";
      formik.values.gender = "";
      setClassCat([]);
      setSubjectVal([]);
      formik.values.classType = "";
      formik.values.noOfChildren = "";
      setLessonDays([]);
      setExamServices([]);
      setLessonGoals([]);
      formik.values.lessonTimes = "";
      formik.values.referredBy = "";
      formik.values.message = "";
    }
  };

  return (
    <div className="page-content bkg-green-light pgx pgy">
      {/* <div className="blur1"></div>
      <div className="blur2"></div> */}

      <div onClick={browserHistory.back} className="backBtn">
        <img src={BackBtn} alt="" />
        <span>Go Back</span>
      </div>

      {/* <div className='row justify-content-center align-items-center'>
                <div className='col-lg-8'>
                    <div className='infoStrip'>
                        <img src={InfoIcon} alt="" />
                        <span>If you have previously completed stage one click the button below</span>
                        <a href=''>Continue to stage 2</a>
                    </div>
                </div>
            </div> */}

      <div className="row formCont no-pad justify-content-center align-items-center">
        <div className="col-lg-7">
          {/* <div className='sec-title clr-orange mb-lg-1'>Stage 1</div> */}
          <div className="hdr xl mb-2 mb-lg-2">Subject Tutor Request Form</div>
          <p>Simply fill this form to request a tutor.</p>

          <div className="formWrap">
            <form>
              <div className="fieldRow">
                <div className="fieldHld">
                  <div className="label req">First Name</div>
                  <TextField
                    required
                    fullWidth
                    className="textField"
                    id="firstName"
                    name="firstName"
                    type="text"
                    placeholder="First Name"
                    // value={formik.values.firstName}
                    onBlur={checkValidity}
                    onChange={formik.handleChange}
                    onFocus={formik.handleBlur}
                    variant="outlined"
                    error={
                      formik.touched.firstName &&
                      Boolean(formik.errors.firstName)
                    }
                    helperText={
                      formik.touched.firstName && formik.errors.firstName
                    }
                    sx={{
                      '& .MuiInputBase-root.MuiInput-root:before': {
                        borderBottom: '1px solid rgba(255, 255, 255, 0.74)'
                      }
                    }}
                  />
                </div>

                <div className="fieldHld">
                  <div className="label req">Last Name</div>
                  <TextField
                    required
                    fullWidth
                    className="textField"
                    id="lastName"
                    name="lastName"
                    type="text"
                    placeholder='Last Name'
                    // value={formik.values.lastName}
                    onBlur={checkValidity}
                    onChange={formik.handleChange}
                    onFocus={formik.handleBlur}
                    variant="outlined"
                    error={
                      formik.touched.lastName && Boolean(formik.errors.lastName)
                    }
                    helperText={
                      formik.touched.lastName && formik.errors.lastName
                    }
                    sx={{
                      '& .MuiInputBase-root.MuiInput-root:before': {
                        borderBottom: '1px solid rgba(255, 255, 255, 0.74)'
                      }
                    }}
                  />
                </div>
              </div>

              <div className="fieldRow">
                <div className="fieldHld">
                  <div className="label req">Email</div>
                  <TextField
                    required
                    fullWidth
                    className="textField"
                    id="email"
                    name="email"
                    type="email"
                    placeholder='Email'
                    // value={formik.values.email}
                    onBlur={checkValidity}
                    onChange={formik.handleChange}
                    onFocus={formik.handleBlur}
                    variant="outlined"
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                </div>

                <div className="fieldHld">
                  <div className="label req">Phone Number (Whatsapp)</div>
                  <PhoneInput
                    country={"ng"}
                    value={phone}
                    required={true}
                    defaultErrorMessage={"Enter Phone Number"}
                    onChange={(phone) => setPhoneValue(phone)}
                  />
                  {/* <TextField
                                        required
                                        fullWidth
                                        className='textField'
                                        id="phoneNo"
                                        name="phoneNo" 
                                        type="text"
                                        //placeholder='Phone Number' 
                                        value={formik.values.phoneNo}
                                        onBlur={checkValidity}
                                        onChange={formik.handleChange}
                                        onFocus={formik.handleBlur}
                                        variant="outlined"
                                        error={formik.touched.phoneNo && Boolean(formik.errors.phoneNo)}
                                        helperText={formik.touched.phoneNo && formik.errors.phoneNo}
                                    /> */}
                </div>
              </div>

              <div className="fieldRow">
                <div className="fieldHld">
                  <div className="label req">Class Type</div>
                  <TextField
                    required
                    fullWidth
                    select
                    variant="outlined"
                    id="classType"
                    name="classType"
                    // value={formik.values.classType}
                    onBlur={checkValidity}
                    onChange={formik.handleChange}
                    onFocus={formik.handleBlur}
                    error={
                      formik.touched.classType &&
                      Boolean(formik.errors.classType)
                    }
                    helperText={
                      formik.touched.classType && formik.errors.classType
                    }
                    sx={{
                      "& .MuiSvgIcon-root": {
                        color: "rgba(0, 0, 0, 0.74)",
                      },
                      "& .MuiInputBase-root": {
                        backgroundColor: "#FFF",
                      },
                      "& .MuiSelect-select": {
                        border: "1px solid rgba(0, 0, 0, 0.3)",
                      },
                    }}
                  >
                    <MenuItem key={"Online"} value={"Online"}>
                      Online
                    </MenuItem>
                    <MenuItem key={"Physical"} value={"Physical"}>
                      Physical
                    </MenuItem>
                    <MenuItem key={"Hybrid"} value={"Hybrid"}>
                      Hybrid
                    </MenuItem>
                  </TextField>
                </div>

                <div className="fieldHld">
                  <div className="label req">Country</div>
                  <Autocomplete
                    value={countryValue}
                    onChange={(event, newValue) => {
                      //console.log(newValue);
                      if (typeof newValue === "string") {
                        setCountryValue({
                          title: newValue,
                        });
                      } else if (newValue && newValue.inputValue) {
                        // Create a new value from the user input
                        setCountryValue({
                          title: newValue.inputValue,
                        });
                      } else {
                        setCountryValue(newValue);
                      }
                      dispatch(getStates(newValue.isoCode));
                    }}
                    filterOptions={(countryOptions, params) => {
                      const filtered = filter(countryOptions, params);

                      const { inputValue } = params;
                      // Suggest the creation of a new value
                      const isExisting = countryOptions.some(
                        (country) => inputValue === country.label
                      );
                      if (inputValue !== "" && !isExisting) {
                        filtered.push({
                          inputValue,
                          title: `Add "${inputValue}"`,
                        });
                      }
                      return filtered;
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    id="country-selection"
                    options={countryOptions}
                    getOptionLabel={(country) => {
                      // Value selected with enter, right from the input
                      if (typeof country === "string") {
                        return country;
                      }
                      // Add "xxx" option created dynamically
                      if (country.inputValue) {
                        return country.inputValue;
                      }
                      // Regular option
                      return country.name;
                    }}
                    renderOption={(props, country) => (
                      <li {...props}>{country.name}</li>
                    )}
                    sx={{ width: "100%" }}
                    freeSolo
                    renderInput={(params) => (
                      <TextField
                        variant="outlined"
                        id="country"
                        name="country"
                        {...params}
                        error={
                          formik.touched.country &&
                          Boolean(formik.errors.country)
                        }
                        helperText={
                          formik.touched.country && formik.errors.country
                        }
                        sx={{
                          "& .MuiSvgIcon-root": {
                            color: "rgba(0, 0, 0, 0.74)",
                          },
                          "& .MuiInputBase-root": {
                            backgroundColor: "#FFF",
                            border: "1px solid rgba(0, 0, 0, 0.5)",
                          },
                          "& .MuiSelect-select": {
                            border: "1px solid rgba(0, 0, 0, 0.3)",
                          },
                        }}
                      />
                    )}
                  />
                </div>
              </div>

              <div className="fieldRow">
                <div className="fieldHld">
                  <div className="label req">State of Residence</div>
                  <Autocomplete
                    value={stateValue}
                    onChange={(event, newValue) => {
                      //console.log(newValue);
                      if (typeof newValue === "string") {
                        setStateValue({
                          title: newValue,
                        });
                      } else if (newValue && newValue.inputValue) {
                        // Create a new value from the user input
                        setStateValue({
                          title: newValue.inputValue,
                        });
                      } else {
                        setStateValue(newValue);
                      }
                      dispatch(
                        getCities(countryValue.isoCode, newValue.isoCode)
                      );
                      cityOptions.push({
                        name: "Other",
                        stateCode: "",
                        countryCode: "",
                      });
                      if (countryValue.isoCode == "NG") {
                        if (
                          newValue.name == "Abuja Federal Capital Territory"
                        ) {
                          dispatch(getLgas("Federal Capital Territory"));
                        } else {
                          dispatch(getLgas(newValue.name));
                        }
                      }
                    }}
                    filterOptions={(stateOptions, params) => {
                      const filtered = filter(stateOptions, params);

                      const { inputValue } = params;
                      // Suggest the creation of a new value
                      const isExisting = stateOptions.some(
                        (state) => inputValue === state.name
                      );
                      // if (inputValue !== '' && !isExisting) {
                      //     filtered.push({
                      //         inputValue,
                      //         title: `Add "${inputValue}"`,
                      //     });
                      // }
                      return filtered;
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    id="state-selection"
                    options={stateOptions}
                    getOptionLabel={(state) => {
                      // Value selected with enter, right from the input
                      if (typeof state === "string") {
                        return state;
                      }
                      // Add "xxx" option created dynamically
                      if (state.inputValue) {
                        return state.inputValue;
                      }
                      // Regular option
                      return state.name;
                    }}
                    renderOption={(props, state) => (
                      <li {...props}>{state.name}</li>
                    )}
                    sx={{ width: "100%" }}
                    freeSolo
                    renderInput={(params) => (
                      <TextField
                        variant="outlined"
                        id="state"
                        name="state"
                        {...params}
                        // error={
                        //   formik.touched.state && Boolean(formik.errors.state)
                        // }
                        // helperText={formik.touched.state && formik.errors.state}
                        sx={{
                          "& .MuiSvgIcon-root": {
                            color: "rgba(0, 0, 0, 0.74)",
                          },
                          "& .MuiInputBase-root": {
                            backgroundColor: "#FFF",
                            border: "1px solid rgba(0, 0, 0, 0.5)",
                          },
                          "& .MuiSelect-select": {
                            border: "1px solid rgba(0, 0, 0, 0.3)",
                          },
                        }}
                      />
                    )}
                  />
                </div>

                <div className="fieldHld">
                  <div className="label req">City</div>
                  <Autocomplete
                    value={cityValue}
                    onChange={(event, newValue) => {
                      //console.log(newValue);
                      if (typeof newValue === "string") {
                        setCityValue({
                          name: newValue,
                        });
                      } else if (newValue && newValue.inputValue) {
                        // Create a new value from the user input
                        setCityValue({
                          name: newValue.inputValue,
                        });
                      } else {
                        setCityValue(newValue);
                      }
                    }}
                    filterOptions={(cityOptions, params) => {
                      const filtered = filter(cityOptions, params);

                      const { inputValue } = params;
                      // Suggest the creation of a new value
                      // const isExisting = cityOptions.some((city) => inputValue === city.name);
                      // if (inputValue !== '' && !isExisting) {
                      //     filtered.push({
                      //         inputValue,
                      //         title: `Add "${inputValue}"`,
                      //     });
                      // }
                      return filtered;
                    }}
                    onBlur={(e) => {
                      setCityValue({
                        name: e.target.value,
                      });
                    }}
                    selectOnFocus
                    //clearOnBlur
                    handleHomeEndKeys
                    id="city-selection"
                    options={cityOptions}
                    getOptionLabel={(city) => {
                      // Value selected with enter, right from the input
                      if (typeof city === "string") {
                        return city;
                      }
                      // Add "xxx" option created dynamically
                      if (city.inputValue) {
                        return city.inputValue;
                      }
                      // Regular option
                      return city.name;
                    }}
                    renderOption={(props, city) => (
                      <li {...props}>{city.name}</li>
                    )}
                    sx={{ width: "100%" }}
                    freeSolo
                    renderInput={(params) => (
                      <TextField
                        variant="outlined"
                        id="city"
                        name="city"
                        {...params}
                        error={
                          formik.touched.city && Boolean(formik.errors.city)
                        }
                        helperText={formik.touched.city && formik.errors.city}
                        sx={{
                          "& .MuiSvgIcon-root": {
                            color: "rgba(0, 0, 0, 0.74)",
                          },
                          "& .MuiInputBase-root": {
                            backgroundColor: "#FFF",
                            border: "1px solid rgba(0, 0, 0, 0.5)",
                          },
                          "& .MuiSelect-select": {
                            border: "1px solid rgba(0, 0, 0, 0.3)",
                          },
                        }}
                      />
                    )}
                  />
                </div>
              </div>

              <div className="fieldRow">
                <div
                  className="fieldHld"
                  style={{
                    display: countryValue?.isoCode == "NG" ? "block" : "none",
                  }}
                >
                  <div className="label req">LGA</div>
                  <Autocomplete
                    value={lgaValue}
                    onChange={(event, newValue) => {
                      // console.log(newValue);
                      if (typeof newValue === "string") {
                        setLgaValue({
                          title: newValue,
                        });
                      } else if (newValue && newValue.inputValue) {
                        // Create a new value from the user input
                        setLgaValue({
                          title: newValue.inputValue,
                        });
                      } else {
                        setLgaValue(newValue);
                      }
                    }}
                    filterOptions={(lgaOptions, params) => {
                      const filtered = filter(lgaOptions, params);

                      const { inputValue } = params;
                      // Suggest the creation of a new value
                      const isExisting = lgaOptions.some(
                        (lga) => inputValue === lga.name
                      );
                      // if (inputValue !== '' && !isExisting) {
                      //     filtered.push({
                      //         inputValue,
                      //         title: `Add "${inputValue}"`,
                      //     });
                      // }
                      return filtered;
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    id="lga-selection"
                    options={lgaOptions}
                    getOptionLabel={(lga) => {
                      // Value selected with enter, right from the input
                      if (typeof lga === "string") {
                        return lga;
                      }
                      // Add "xxx" option created dynamically
                      if (lga.inputValue) {
                        return lga.inputValue;
                      }
                      // Regular option
                      return lga.title;
                    }}
                    renderOption={(props, lga) => <li {...props}>{lga}</li>}
                    sx={{ width: "100%" }}
                    freeSolo
                    renderInput={(params) => (
                      <TextField
                        variant="outlined"
                        id="lga"
                        name="lga"
                        {...params}
                        error={formik.touched.lga && Boolean(formik.errors.lga)}
                        helperText={formik.touched.lga && formik.errors.lga}
                        sx={{
                          "& .MuiSvgIcon-root": {
                            color: "rgba(0, 0, 0, 0.74)",
                          },
                          "& .MuiInputBase-root": {
                            backgroundColor: "#FFF",
                            border: "1px solid rgba(0, 0, 0, 0.5)",
                          },
                          "& .MuiSelect-select": {
                            border: "1px solid rgba(0, 0, 0, 0.3)",
                          },
                        }}
                      />
                    )}
                  />
                </div>

                <div
                  className={
                    countryValue?.isoCode == "NG"
                      ? "fieldHld cHalf"
                      : "fieldHld cFull"
                  }
                >
                  <div className="label req">
                    How many Children will be tutored ?
                  </div>
                  <TextField
                    required
                    fullWidth
                    select
                    variant="outlined"
                    id="noOfChildren"
                    name="noOfChildren"
                    // value={formik.values.noOfChildren}
                    onBlur={checkValidity}
                    onChange={formik.handleChange}
                    onFocus={formik.handleBlur}
                    error={
                      formik.touched.noOfChildren &&
                      Boolean(formik.errors.noOfChildren)
                    }
                    helperText={
                      formik.touched.noOfChildren && formik.errors.noOfChildren
                    }
                    sx={{
                      "& .MuiSvgIcon-root": {
                        color: "rgba(0, 0, 0, 0.74)",
                      },
                      "& .MuiInputBase-root": {
                        backgroundColor: "#FFF",
                      },
                      "& .MuiSelect-select": {
                        border: "1px solid rgba(0, 0, 0, 0.3)",
                      },
                    }}
                  >
                    <MenuItem key={"1"} value={"1"}>
                      1
                    </MenuItem>
                    <MenuItem key={"2"} value={"2"}>
                      2
                    </MenuItem>
                    <MenuItem key={"3"} value={"3"}>
                      3
                    </MenuItem>
                    <MenuItem key={"4"} value={"4"}>
                      4
                    </MenuItem>
                    <MenuItem key={"5"} value={"5"}>
                      5
                    </MenuItem>
                    <MenuItem key={"More"} value={"More"}>
                      More
                    </MenuItem>
                  </TextField>
                </div>
              </div>

              <div className="fieldRow">
                <div className="fieldHld">
                  <div className="label req">Address</div>
                  <TextField
                    required
                    fullWidth
                    className="textField"
                    id="address"
                    name="address"
                    type="text"
                    // value={formik.values.address}
                    onBlur={checkValidity}
                    onChange={formik.handleChange}
                    onFocus={formik.handleBlur}
                    variant="outlined"
                    error={
                      formik.touched.address && Boolean(formik.errors.address)
                    }
                    helperText={formik.touched.address && formik.errors.address}
                    sx={{
                      '& .MuiInputBase-root.MuiInput-root:before': {
                        borderBottom: '1px solid rgba(255, 255, 255, 0.74)'
                      }
                    }}
                  />
                </div>
              </div>

              <div className="fieldRow">
                <div className="fieldHld">
                  <div className="label req">Tutor Gender Preference</div>
                  <TextField
                    required
                    fullWidth
                    select
                    variant="outlined"
                    id="gender"
                    name="gender"
                    // value={formik.values.gender}
                    onBlur={checkValidity}
                    onChange={formik.handleChange}
                    onFocus={formik.handleBlur}
                    error={
                      formik.touched.gender && Boolean(formik.errors.gender)
                    }
                    helperText={formik.touched.gender && formik.errors.gender}
                    sx={{
                      "& .MuiSvgIcon-root": {
                        color: "rgba(0, 0, 0, 0.74)",
                      },
                      "& .MuiInputBase-root": {
                        backgroundColor: "#FFF",
                      },
                      "& .MuiSelect-select": {
                        border: "1px solid rgba(0, 0, 0, 0.3)",
                      },
                    }}
                  >
                    <MenuItem key={"Male"} value={"Male"}>
                      Male
                    </MenuItem>
                    <MenuItem key={"Female"} value={"Female"}>
                      Female
                    </MenuItem>
                    <MenuItem key={"No Preference"} value={"No Preference"}>
                      No Preference
                    </MenuItem>
                  </TextField>
                </div>
              </div>

              <div className="fieldRow">
                <div className="fieldHld auto">
                  <div className="label req">
                    What class is your child or what classes are your children
                    in ?
                  </div>
                  <Box>
                    <Autocomplete
                      multiple
                      options={classesOptions}
                      defaultValue={[classesOptions[0]]}
                      getOptionLabel={(option) => option.name}
                      value={classCat}
                      onChange={(e, newValue) => setClassCat(newValue)}
                      renderTags={() => null}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          placeholder="Pick Class(s) for your kids"
                        />
                      )}
                      sx={{
                        "& .MuiSvgIcon-root": {
                          color: "rgba(0, 0, 0, 0.74)",
                        },
                        "& .MuiInputBase-root": {
                          backgroundColor: "#FFF",
                          border: "1px solid rgba(0, 0, 0, 0.4)",
                        },
                      }}
                    />
                    <Box
                      mt={2}
                      sx={{
                        "& > :not(:last-child)": { mr: 1 },
                        "& > *": { mr: 1 },
                      }}
                    >
                      {classCat.map((v) => (
                        <Chip
                          key={v.name}
                          label={v.name}
                          onDelete={onClassCatDelete(v.name)}
                          sx={{
                            "&.MuiButtonBase-root.MuiChip-root": {
                              backgroundColor: "#F6FFFC",
                              border: "0.1rem solid #01C38E",
                              marginBottom: "0.3rem",
                            },
                            "& .MuiSvgIcon-root": {
                              color: "#01C38E",
                            },
                          }}
                        />
                      ))}
                    </Box>
                  </Box>
                </div>
              </div>

              <div className="fieldRow">
                <div className="fieldHld auto">
                  <div className="label req">
                    What Subjects do you need a tutor for ?
                  </div>
                  <Box>
                    <Autocomplete
                      multiple
                      options={subjectOptions}
                      //defaultValue={[classesOptions[0]]}
                      getOptionLabel={(option) => option.name}
                      value={subjectVal}
                      onChange={(e, newValue) => setSubjectVal(newValue)}
                      renderTags={() => null}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          placeholder="Pick Subjects(s) for your kids"
                        />
                      )}
                      sx={{
                        "& .MuiSvgIcon-root": {
                          color: "rgba(0, 0, 0, 0.74)",
                        },
                        "& .MuiInputBase-root": {
                          backgroundColor: "#FFF",
                          border: "1px solid rgba(0, 0, 0, 0.4)",
                        },
                      }}
                    />
                    <Box
                      mt={2}
                      sx={{
                        "& > :not(:last-child)": { mr: 1 },
                        "& > *": { mr: 1 },
                      }}
                    >
                      {subjectVal.map((v) => (
                        <Chip
                          key={v.name}
                          label={v.name}
                          onDelete={onDelete(v.name)}
                          sx={{
                            "&.MuiButtonBase-root.MuiChip-root": {
                              backgroundColor: "#F6FFFC",
                              border: "0.1rem solid #01C38E",
                              marginBottom: "0.3rem",
                            },
                            "& .MuiSvgIcon-root": {
                              color: "#01C38E",
                            },
                          }}
                        />
                      ))}
                    </Box>
                  </Box>
                  {/* <Box>
                                        <Autocomplete
                                            multiple
                                            options={subjectOptions}
                                            //defaultValue={[subjectOptions[1]]}
                                            getOptionLabel={(option) => option.name}
                                            value={subjectVal}
                                            onChange={(e, newValue) => setSubjectVal(newValue)}
                                            renderTags={() => null}
                                            renderInput={(params) => (
                                                <TextField {...params} variant="outlined" placeholder="Pick Subject(s)" />
                                            )}
                                            sx={{
                                                '& .MuiSvgIcon-root': {
                                                    color: "rgba(0, 0, 0, 0.74)"
                                                },
                                                '& .MuiInputBase-root': {
                                                    backgroundColor: '#FFF',
                                                    border: '1px solid rgba(0, 0, 0, 0.4)'
                                                },
                                            }}
                                        />
                                        <Box
                                            mt={2}
                                            sx={{
                                                '& > :not(:last-child)': { mr: 1 },
                                                '& > *': { mr: 1 },
                                            }}
                                        >
                                            {subjectVal.map((v) => (
                                                <Chip 
                                                    key={v.name} 
                                                    label={v.name} 
                                                    onDelete={onDelete(v.name)}
                                                    sx={{
                                                        '&.MuiButtonBase-root.MuiChip-root': {
                                                            backgroundColor: '#F6FFFC',
                                                            border: '0.1rem solid #01C38E',
                                                            marginBottom: '0.3rem'
                                                        },
                                                        '& .MuiSvgIcon-root': {
                                                            color: '#01C38E',
                                                        },
                                                    }}
                                                />
                                            ))}
                                        </Box>
                                    </Box> */}
                </div>
              </div>

              <div className="fieldRow">
                <div className="fieldHld auto">
                  <div className="label req">
                    What lesson days will you prefer ?
                  </div>
                  <Box>
                    <Autocomplete
                      multiple
                      options={days}
                      defaultValue={[days[1]]}
                      getOptionLabel={(option) => option.title}
                      value={lessonDays}
                      onChange={(e, newValue) => setLessonDays(newValue)}
                      renderTags={() => null}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          placeholder="Pick Days(s)"
                        />
                      )}
                      sx={{
                        "& .MuiSvgIcon-root": {
                          color: "rgba(0, 0, 0, 0.74)",
                        },
                        "& .MuiInputBase-root": {
                          backgroundColor: "#FFF",
                          border: "1px solid rgba(0, 0, 0, 0.4)",
                        },
                      }}
                    />
                    <Box
                      mt={2}
                      sx={{
                        "& > :not(:last-child)": { mr: 1 },
                        "& > *": { mr: 1 },
                      }}
                    >
                      {lessonDays.map((v) => (
                        <Chip
                          key={v.title}
                          label={v.title}
                          onDelete={onLessonDaysDelete(v.title)}
                          sx={{
                            "&.MuiButtonBase-root.MuiChip-root": {
                              backgroundColor: "#F6FFFC",
                              border: "0.1rem solid #01C38E",
                              marginBottom: "0.3rem",
                            },
                            "& .MuiSvgIcon-root": {
                              color: "#01C38E",
                            },
                          }}
                        />
                      ))}
                    </Box>
                  </Box>
                </div>
              </div>

              <div className="fieldRow">
                <div className="fieldHld lg">
                  <div className="label req">
                    Do you require Special Exam Services ?
                  </div>
                  <Box>
                    <Autocomplete
                      multiple
                      options={examOptions}
                      defaultValue={[examOptions[0]]}
                      getOptionLabel={(option) => option.name}
                      value={examServices}
                      onChange={(e, newValue) => setExamServices(newValue)}
                      renderTags={() => null}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          placeholder="Pick exam service(s) for your kids"
                        />
                      )}
                      sx={{
                        "& .MuiSvgIcon-root": {
                          color: "rgba(0, 0, 0, 0.74)",
                        },
                        "& .MuiInputBase-root": {
                          backgroundColor: "#FFF",
                          border: "1px solid rgba(0, 0, 0, 0.4)",
                        },
                      }}
                    />
                    <Box
                      mt={2}
                      sx={{
                        "& > :not(:last-child)": { mr: 1 },
                        "& > *": { mr: 1 },
                      }}
                    >
                      {examServices.map((v) => (
                        <Chip
                          key={v.name}
                          label={v.name}
                          onDelete={onExamServicesDelete(v.name)}
                          sx={{
                            "&.MuiButtonBase-root.MuiChip-root": {
                              backgroundColor: "#F6FFFC",
                              border: "0.1rem solid #01C38E",
                              marginBottom: "0.3rem",
                            },
                            "& .MuiSvgIcon-root": {
                              color: "#01C38E",
                            },
                          }}
                        />
                      ))}
                    </Box>
                  </Box>
                  {/* <TextField
                                        required
                                        fullWidth
                                        select
                                        variant="outlined"
                                        id="specialExamServices"
                                        name="specialExamServices" 
                                        value={formik.values.specialExamServices}
                                        onBlur={checkValidity}
                                        onChange={formik.handleChange}
                                        onFocus={formik.handleBlur}
                                        error={formik.touched.specialExamServices && Boolean(formik.errors.specialExamServices)}
                                        helperText={formik.touched.specialExamServices && formik.errors.specialExamServices}
                                        sx={{
                                            '& .MuiSvgIcon-root': {
                                                color: "rgba(0, 0, 0, 0.74)"
                                            },
                                            '& .MuiInputBase-root': {
                                                backgroundColor: '#FFF',
                                            },
                                            '& .MuiSelect-select': {
                                                border: '1px solid rgba(0, 0, 0, 0.3)'
                                            }
                                        }}
                                    >
                                        <MenuItem key={"Yes"} value={"Yes"}>Yes</MenuItem>
                                        <MenuItem key={"No"} value={"No"}>No</MenuItem>
                                        <MenuItem key={"None at the moment"} value={"None at the moment"}>None at the moment</MenuItem>
                                    </TextField>  */}
                </div>
              </div>

              <div className="fieldRow">
                <div className="fieldHld auto">
                  <div className="label req">
                    What lesson times will you prefer? (E.g. 2pm on Sundays)
                  </div>
                  <TextField
                    required
                    fullWidth
                    variant="outlined"
                    id="lessonTimes"
                    name="lessonTimes"
                    // value={formik.values.lessonTimes}
                    onBlur={checkValidity}
                    onChange={formik.handleChange}
                    onFocus={formik.handleBlur}
                    error={
                      formik.touched.lessonTimes &&
                      Boolean(formik.errors.lessonTimes)
                    }
                    helperText={
                      formik.touched.lessonTimes && formik.errors.lessonTimes
                    }
                    sx={{
                      "& .MuiSvgIcon-root": {
                        color: "rgba(0, 0, 0, 0.74)",
                      },
                      "& .MuiInputBase-root": {
                        backgroundColor: "#FFF",
                      },
                      "& .MuiSelect-select": {
                        border: "1px solid rgba(0, 0, 0, 0.3)",
                      },
                    }}
                  ></TextField>
                </div>
              </div>

              <div className="fieldRow">
                <div className="fieldHld auto">
                  <div className="label req">
                    Which of these best suits your goals for your child?
                  </div>
                  <Box>
                    <Autocomplete
                      multiple
                      options={goals}
                      defaultValue={[goals[0]]}
                      getOptionLabel={(option) => option.title}
                      value={lessonGoals}
                      onChange={(e, newValue) => setLessonGoals(newValue)}
                      renderTags={() => null}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          placeholder="Pick Goal(s) for your kids"
                        />
                      )}
                      sx={{
                        "& .MuiSvgIcon-root": {
                          color: "rgba(0, 0, 0, 0.74)",
                        },
                        "& .MuiInputBase-root": {
                          backgroundColor: "#FFF",
                          border: "1px solid rgba(0, 0, 0, 0.4)",
                        },
                      }}
                    />
                    <Box
                      mt={2}
                      sx={{
                        "& > :not(:last-child)": { mr: 1 },
                        "& > *": { mr: 1 },
                      }}
                    >
                      {lessonGoals.map((v) => (
                        <Chip
                          key={v.title}
                          label={v.title}
                          onDelete={onLessonGoalsDelete(v.title)}
                          sx={{
                            "&.MuiButtonBase-root.MuiChip-root": {
                              backgroundColor: "#F6FFFC",
                              border: "0.1rem solid #01C38E",
                              marginBottom: "0.3rem",
                            },
                            "& .MuiSvgIcon-root": {
                              color: "#01C38E",
                            },
                          }}
                        />
                      ))}
                    </Box>
                  </Box>
                </div>
              </div>

              <div className="fieldRow">
                <div className="fieldHld auto">
                  <div className="label">
                    Any important information you would like to share with us ?
                  </div>
                  <TextField
                    fullWidth
                    variant="outlined"
                    id="message"
                    name="message"
                    // value={formik.values.message}
                    onBlur={checkValidity}
                    onChange={formik.handleChange}
                    onFocus={formik.handleBlur}
                    error={
                      formik.touched.message && Boolean(formik.errors.message)
                    }
                    helperText={formik.touched.message && formik.errors.message}
                    sx={{
                      "& .MuiSvgIcon-root": {
                        color: "rgba(0, 0, 0, 0.74)",
                      },
                      "& .MuiInputBase-root": {
                        backgroundColor: "#FFF",
                      },
                      "& .MuiSelect-select": {
                        border: "1px solid rgba(0, 0, 0, 0.3)",
                      },
                    }}
                  ></TextField>
                </div>
              </div>

              <div className="fieldRow">
                <div className="fieldHld">
                  <div className="label">
                    Were you referred to us by someone ?, if yes please indicate
                    their name below
                  </div>
                  <TextField
                    fullWidth
                    variant="outlined"
                    id="referredBy"
                    name="referredBy"
                    // value={formik.values.referredBy}
                    onBlur={checkValidity}
                    onChange={formik.handleChange}
                    onFocus={formik.handleBlur}
                    error={
                      formik.touched.referredBy &&
                      Boolean(formik.errors.referredBy)
                    }
                    helperText={
                      formik.touched.referredBy && formik.errors.referredBy
                    }
                    sx={{
                      "& .MuiSvgIcon-root": {
                        color: "rgba(0, 0, 0, 0.74)",
                      },
                      "& .MuiInputBase-root": {
                        backgroundColor: "#FFF",
                      },
                      "& .MuiSelect-select": {
                        border: "1px solid rgba(0, 0, 0, 0.3)",
                      },
                    }}
                  ></TextField>
                </div>
              </div>

              <div className="fieldRow">
                <Button
                  onClick={submitForm}
                  className="hasIcon mainCta extra"
                  fullWidth
                  type="button"
                  variant="contained"
                >
                  Submit Form
                </Button>
                <ToastContainer
                  position="top-center"
                  autoClose={5000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                  theme="light"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubjectReqFormPage;
