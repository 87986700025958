import * as React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Landing from '../components/Landing';

const Home = () => {   

  return (
    
    <div className="page-container">
      <Header className='header bkg-pale-yellow' />
      <Landing />
      <Footer />
    </div>
    
  )
}

export default Home;