import * as React from 'react';
import { useFormik } from "formik";
import * as yup from "yup";
import { TextField, Button } from "@mui/material";


const validationSchema = yup.object({
    firstName: yup.string().required("This is a required field"),
    lastName: yup.string().required("This is a required field"),
    email: yup.string().email("Please insert a valid email").required("This field is required"),
    phoneNo: yup.string().required("This is a required field"),
    message: yup.string().required("This is a required field")
})


const ContactPage = () => {

    const formik = useFormik({
        initialValues: {
            firstName: "",
            lastName: "",
            email: "",
            phoneNo: "",
            message: "",
        },
        onSubmit: (values) => {
            // console.log(formik.values);
        },
        validationSchema: validationSchema
    });

    const [valid, setValid] = React.useState(false);
    const checkValidity = () => {
        // do what you want like on submit
        if (formik.isValid) {
            setValid(true);
        }
        else if (!formik.isValid) {
            setValid(false);
        }
    }

    return (

        <div className="page-content bkg-green-light pgx pgy">

            <div className='subPages about'>
                <div className='hdr xl mb-3 mb-lg-2 text-center'>Privacy Policy</div>

                <div className='row justify-content-center align-items-start'>
                    <div className='col-lg-10'>
                        <p>
                            Nigenius Technologies is committed to protecting your privacy. This Privacy Policy outlines how we collect, use, disclose, and safeguard your personal information in connection with our website, services, and applications.
                        </p>

                        <h5>Information we collect</h5>

                        <p className='fw-bold'>Personal Information</p>
                        <p>When you register on our platform, we may collect personal information such as your name, email address, and educational background.</p>

                        <p className='fw-bold'>Usage Data</p>
                        <p>We may collect information about your use of our services, including the resources accessed, session duration, and interaction data.</p>

                        <p className='fw-bold'>Payment Information</p>
                        <p>If you use our tutoring services, we may collect payment information such as credit card details. This data is securely processed by our payment partners, and we do not store it.</p>

                        <h5>How we use your information</h5>

                        <p className='fw-bold'>Providing Services</p>
                        <p>We use your information to deliver teaching resources, tutoring services, and support the delivery of STEAM curriculums to schools.</p>

                        <p className='fw-bold'>Communication</p>
                        <p>We may send you updates, newsletters, or information related to our services. You can opt out of these communications at any time.</p>

                        <p className='fw-bold'>Improving our services</p>
                        <p>Your feedback and usage data help us enhance and optimize our platform for better user experiences.</p>

                        <h5>Information Sharing</h5>

                        <p className='fw-bold'>Third Party Service Providers</p>
                        <p>We may share your information with third-party service providers who assist in delivering our services. These providers are obligated to maintain the confidentiality of your information.</p>

                        <p className='fw-bold'>Legal Compliance</p>
                        <p>We may disclose your information to comply with legal obligations, protect our rights, and enforce our policies.</p>

                        <h5>Data Security</h5>

                        <p className='fw-bold'>Security Measures</p>
                        <p>We implement industry-standard security measures to protect your personal information from unauthorized access, disclosure, alteration, and destruction.</p>

                        <p className='fw-bold'>Data Retention</p>
                        <p>We retain your information for as long as necessary to provide our services and comply with legal obligations.</p>

                        <h5>Your Choices</h5>
                        <p className='fw-bold'>Account Information</p>
                        <p>You can review and update your account information at any time.</p>

                        <p className='fw-bold'>Opt-Out</p>
                        <p>You can opt out of promotional communications by following the instructions in the emails or contacting us directly.</p>

                        <p className='fw-bold'>Children's Privacy</p>
                        <p>Our services are not directed to individuals under 18</p>.
                        <p>If you become aware that your child has provided us with personal information, please contact us, and we will take steps to delete such information.
                        </p>

                        <p className='fw-bold'>Contact Us</p>
                        <p>If you have questions or concerns about our Privacy Policy, please contact us at info@nigenius.ng</p>

                        <p className='fw-semibold'>Changes to this privacy policy</p>
                        <p>We reserve the right to modify this Privacy Policy at any time. Changes will be effective upon posting on our website. Please review this policy periodically for updates.</p>
                    </div>
                </div>

            </div>

        </div>

    );
};

export default ContactPage;