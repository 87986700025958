import * as React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import AboutPage from '../components/AboutPage';

const About = () => {   

  return (
    
    <div className="page-container">
      <Header className='header' />
      <AboutPage />
      <Footer />
    </div>
    
  );
};

export default About;