import * as React from 'react';

import { Button, Icon } from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

import Image2 from "../assets/img/home/image-2.png";
import Image3 from "../assets/img/home/image-3.png";
import Image4 from "../assets/img/home/service4.png";

const Management = () => {   

  return (
    
    <div className="page-content bkg-pink pgx pgy">

        <div className='subPages row justify-content-between align-items-start'>
            <div className='col-lg-6'>
                <div className='hdr xl mb-lg-3'>School Management System</div>
                <div className='only-mobile double py-3 hasBlur justify-content-between align-items-center'>
                    <img src={Image4} className='img-fluid' alt="" /> 
                </div>
                <div className='text'>
                    <p>Introducing Nebula 1.0 by Nigenius. The Nigenius/Nebula school management system is an end-to-end integrated school software that helps schools automate their most difficult administrative and academic tasks.</p>
                    <h4>Why choose the Nigenius School Management System</h4>
                    <ul>
                        <li>Flexible and Adaptable</li>
                        <li>Quick and Easy Onboarding</li>
                        <li>Desktop and Mobile friendly</li>
                        <li>Real Time Customer support</li>
                    </ul>
                </div>
                <div className='btnHld mt-4 mt-lg-5'>
                    <a className='noFormat' href='/school-management-system-demo'>
                        <Button 
                            className="hasIcon mainCta caps"
                            fullWidth 
                            type="button"
                            variant="contained"
                        >Request a demo <FontAwesomeIcon icon={faArrowRight} /></Button>
                    </a>

                    <Button 
                        className='transCta'
                        fullWidth 
                        type="button"
                        variant="outlined"
                    >How it works</Button>
                </div>
            </div>
            <div className='col-lg-5 only-desktop double hasBlur justify-content-between align-items-center'>
                <div className='md clr-pink'>
                    <img src={Image4} className='img-fluid' alt="" /> 
                </div>
            </div>
        </div>
    </div>
    
  );
};

export default Management;