import { GET_COUNTRIES, GET_COUNTRIES_ERROR } from '../actions/types';

const initialState = {
    data: null,
    loading: true,
    error: ""
}

export default function(state = initialState, action) {

    const { type, payload } = action;

    switch(type) {

        case GET_COUNTRIES:
        return {
            ...state,
            data: payload.data,
            loading: false
        }

        case GET_COUNTRIES_ERROR:
        return {
            ...state,
            error: payload,
            loading: false
        }

        default:
        return state;
    }
   
}

