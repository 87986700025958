import * as React from 'react';
import { InlineWidget } from "react-calendly";

import { Button, Icon } from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

import HeroImage1 from "../assets/img/tutors/teaching1.png";
import HeroImage2 from "../assets/img/tutors/teaching2.png";
import SmsVideo from "../assets/img/brand/smsVideo.jpg";
import Marker from "../assets/img/home/marker.svg";
import DemoArrow from "../assets/img/home/demo-arrow.svg";

import FlexibleIcon from "../assets/img/brand/flexible.svg";
import QuickIcon from "../assets/img/brand/quick.svg";
import DevicesIcon from "../assets/img/brand/devices.svg";
import RealTimeIcon from "../assets/img/brand/real-time.svg";

const SmsDemoPage = () => {   

  return (
    
    <div className="page-content bkg-pale-purple pgx pgy">

        <div className='subPages row justify-content-between align-items-start'>
            <div className='col-lg-6'>
                <div className='hdr xl mb-3 mb-lg-3'>Schedule A <span>Free</span> Demo </div>
                <div className='text'>
                    <p>You can schedule a free demo of our amazing school management system solution by picking a suitable date & time on the calendar. We are more than delighted to walk you through our product and answer any questions or concerns you might have. We can even create a custom solution just for your school.</p>
                </div>
                <img className='demoArrow' src={DemoArrow} alt="" />
                <div className='tourWrap'>
                    <div className='tourHld'>
                        <div className='image'>
                            <img className='bkg' src={FlexibleIcon} alt="" />
                        </div>
                        <div className='details'>
                            <div className='title'>Flexible & Adaptable</div>
                            <div className='text'>An online video call to walk you through our product.</div>
                        </div>
                    </div>

                    <div className='tourHld'>
                        <div className='image'>
                            <img className='bkg' src={DevicesIcon} alt="" />
                        </div>
                        <div className='details'>
                            <div className='title'>Quick & Easy Onboarding</div>
                            <div className='text'>Our team visits your school and conducts a demo for your team.</div>
                        </div>
                    </div>

                    <div className='tourHld'>
                        <div className='image'>
                            <img className='bkg' src={QuickIcon} alt="" />
                        </div>
                        <div className='details'>
                            <div className='title'>Quick & Easy Onboarding</div>
                            <div className='text'>Our team visits your school and conducts a demo for your team.</div>
                        </div>
                    </div>

                    <div className='tourHld'>
                        <div className='image'>
                            <img className='bkg' src={RealTimeIcon} alt="" />
                        </div>
                        <div className='details'>
                            <div className='title'>Real Time Customer Support</div>
                            <div className='text'>Our team visits your school and conducts a demo for your team</div>
                        </div>
                    </div>
                </div>

            </div>

            <div className='col-lg-5 mt-5 mt-lg-0 double hasBlur d-flex justify-content-between align-items-center'>
                {/* <div class="calendly-inline-widget" data-url="https://calendly.com/etiboaghogho" style="min-width:320px;height:650px;"></div> */}
                <InlineWidget 
                    styles={{
                        width: '100%',
                        height: '500px',
                    }}
                    url="https://calendly.com/nigeniussms/30min?hide_event_type_details=1" 
                />
            </div>
        </div>

        <div className='row justify-content-center align-items-center py-0 py-lg-5'>
            <div className='col-lg-12 mt-0 mt-lg-5'>
                <img className='videoHld' src={SmsVideo} alt="" />
            </div>
        </div>


        <div className='row justify-content-center align-items-center pb-5'>
            <div className='col-lg-6 mt-5 mt-lg-5'>
                <div className='hdr lg primaryClr text-center'>Pricing Plans</div>
                <div className='text text-center mt-lg-3'>
                    <p>Start for free, Pay as you grow</p>
                </div>
            </div>
            
            <div className='col-lg-12'>
                <div className='pricingWrap'>
                    <div className='pricingHld box-card clr-blue'>
                        <div className='hdr lg dark'>Starter</div>
                        <div className='text'>
                            <p>Let’s take you on a tour of our amazing all-in-one school management solution.</p>
                        </div>
                        <div className='amount'><span>₦238,056</span><span>/ Month</span></div>
                        <Button 
                            className="hasIcon mainCta caps"
                            fullWidth 
                            type="button"
                            variant="contained"
                        >Buy now <FontAwesomeIcon icon={faArrowRight} /></Button>

                        <ul className='planDetails'>
                            <li><img src={Marker} alt="" /><span>Merchants who process large volumes enjoy transaction fee discount</span></li>
                            <li><img src={Marker} alt="" /><span>Merchants who process large volumes enjoy transaction fee discount</span></li>
                            <li><img src={Marker} alt="" /><span>Merchants who process large volumes enjoy transaction fee discount</span></li>
                            <li><img src={Marker} alt="" /><span>Merchants who process large volumes enjoy transaction fee discount</span></li>
                            <li><img src={Marker} alt="" /><span>Merchants who process large volumes enjoy transaction fee discount</span></li>
                            <li><img src={Marker} alt="" /><span>Merchants who process large volumes enjoy transaction fee discount</span></li>
                            <li><img src={Marker} alt="" /><span>Merchants who process large volumes enjoy transaction fee discount</span></li>
                        </ul>
                    </div>

                    <div className='pricingHld box-card clr-pink'>
                        <div className='hdr lg dark'>Business</div>
                        <div className='text'>
                            <p>Let’s take you on a tour of our amazing all-in-one school management solution.</p>
                        </div>
                        <div className='amount'><span>₦588,056</span><span>/ Month</span></div>
                        <Button 
                            className="hasIcon mainCta caps"
                            fullWidth 
                            type="button"
                            variant="contained"
                        >Buy now <FontAwesomeIcon icon={faArrowRight} /></Button>

                        <ul className='planDetails'>
                            <li><img src={Marker} alt="" /><span>Merchants who process large volumes enjoy transaction fee discount</span></li>
                            <li><img src={Marker} alt="" /><span>Merchants who process large volumes enjoy transaction fee discount</span></li>
                            <li><img src={Marker} alt="" /><span>Merchants who process large volumes enjoy transaction fee discount</span></li>
                            <li><img src={Marker} alt="" /><span>Merchants who process large volumes enjoy transaction fee discount</span></li>
                            <li><img src={Marker} alt="" /><span>Merchants who process large volumes enjoy transaction fee discount</span></li>
                            <li><img src={Marker} alt="" /><span>Merchants who process large volumes enjoy transaction fee discount</span></li>
                            <li><img src={Marker} alt="" /><span>Merchants who process large volumes enjoy transaction fee discount</span></li>
                        </ul>
                    </div>

                    <div className='pricingHld box-card clr-purple'>
                        <div className='hdr lg dark'>Enterprise</div>
                        <div className='text'>
                            <p>Let’s take you on a tour of our amazing all-in-one school management solution.</p>
                        </div>
                        <div className='amount'><span>₦838,056</span><span>/ Month</span></div>
                        <Button 
                            className="hasIcon mainCta caps"
                            fullWidth 
                            type="button"
                            variant="contained"
                        >Buy now <FontAwesomeIcon icon={faArrowRight} /></Button>

                        <ul className='planDetails'>
                            <li><img src={Marker} alt="" /><span>Merchants who process large volumes enjoy transaction fee discount</span></li>
                            <li><img src={Marker} alt="" /><span>Merchants who process large volumes enjoy transaction fee discount</span></li>
                            <li><img src={Marker} alt="" /><span>Merchants who process large volumes enjoy transaction fee discount</span></li>
                            <li><img src={Marker} alt="" /><span>Merchants who process large volumes enjoy transaction fee discount</span></li>
                            <li><img src={Marker} alt="" /><span>Merchants who process large volumes enjoy transaction fee discount</span></li>
                            <li><img src={Marker} alt="" /><span>Merchants who process large volumes enjoy transaction fee discount</span></li>
                            <li><img src={Marker} alt="" /><span>Merchants who process large volumes enjoy transaction fee discount</span></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div className='row justify-content-center align-items-center py-5'>
            <div className='col-lg-8'>
                <div className='actionBox'>
                    <div className='hdr dark lg text-center mb-lg-3'>Book A Demo Today</div>
                    <div className='text text-center'>
                        <p>Let’s take you on a tour of our amazing all-in-one school management solution.</p>
                    </div>
                    <a className='noFormat' href='#'>
                        <Button 
                            className="hasIcon mainCta caps"
                            type="button"
                            variant="contained"
                        >Book Demo</Button>
                    </a>
                </div>
            </div>
            
        </div>
    </div>
    
  );
};

export default SmsDemoPage;
