import * as React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import CodingTutorRegFormPage from '../components/CodingTutorRegFormPage';

const CodingTutorRegForm = () => {   

  return (
    
    <div className="page-container">
        <Header className='header bkg-green-light' />
        <CodingTutorRegFormPage />
        <Footer />
    </div>
    
  );
};

export default CodingTutorRegForm;