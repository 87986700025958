import * as React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import CodingSchoolReqFormPage from '../components/CodingSchoolReqFormPage';

const CodingSchoolReqForm = () => {   

  return (
    
    <div className="page-container">
        <Header className='header bkg-green-light' />
        <CodingSchoolReqFormPage />
        <Footer />
    </div>
    
  );
};

export default CodingSchoolReqForm;