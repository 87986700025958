import * as React from 'react';
import {Routes, Route, useNavigate} from 'react-router-dom';
import Navbar from './Navbar';

import Logo from '../assets/img/brand/logo.svg';
import HomeTutors from '../assets/img/home/home-tutors-icon.svg';
import CodingIcon from '../assets/img/home/coding-icon.svg';
import TeachingIcon from '../assets/img/home/teaching-icon.svg';
import SmsIcon from '../assets/img/home/sms-icon.svg';
import TutorSignupIcon from '../assets/img/home/tutor-signup-icon.svg';

import HomeTutorsClr from '../assets/img/home/home-tutors-clr-icon.svg';
import CodingIconClr from '../assets/img/home/coding-clr-icon.svg';
import TeachingIconClr from '../assets/img/home/teaching-clr-icon.svg';
import SmsIconClr from '../assets/img/home/schools-clr-icon.svg';
import TutorSignupIconClr from '../assets/img/home/tutor-signup-clr-icon.svg';

import { Button, Popper, Modal, Box, FormControlLabel, Checkbox } from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faChevronDown } from "@fortawesome/free-solid-svg-icons";


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 650,
    //border: '1px solid #CFCFCF',
    //boxShadow: 24,
    p: 4,
};

const Header = ({ className }) => {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;


    //Modal Use State Function
    const [modalOpen, setModalOpen] = React.useState(false);
    const handleOpen = () => setModalOpen(true);
    const handleClose = () => setModalOpen(false);


    //Set Subject Coding Tutor Value
    const [subjectChecked, setSubjectChecked] = React.useState(false);
    const handleSubjectChange = (event) => {
        setSubjectChecked(event.target.checked);
        setCodingChecked(!event.target.checked);
    };

    //Set Coding Coding Tutor Value
    const [codingChecked, setCodingChecked] = React.useState(false);
    const handleCodingChange = (event) => {
        setCodingChecked(event.target.checked);
        setSubjectChecked(!event.target.checked);
    };

    const navigate = useNavigate();
    const handleSelection = () => {
        if(subjectChecked) {
            navigate('/subject-tutor-registration-form');
        }
        else if(codingChecked) {
            navigate('/coding-tutor-registration-form');
        }
    }

    return (
    
        <div className={className}>

            <div className='innerWrap'>
                <a href="/">
                    <div className='logo'>
                        <img className="logo" src={Logo} alt="" />
                    </div>
                </a>
                

                <div className='menu'>
                    <ul>
                        <li>
                            <a href="/">Home</a>
                        </li>
                        <li>
                            <a href="/about">About Us</a>
                        </li>
                        <li>
                            <span aria-describedby={id} onClick={handleClick}>Solutions <FontAwesomeIcon icon={faChevronDown} /></span>

                            <Popper
                                id={id}
                                open={open}
                                anchorEl={anchorEl}
                                sx={{
                                    zIndex: '9',
                                }}
                            >
                                <div className='dropDown'>
                                    <ul>
                                        <li>
                                            <a className='hover-orange' href="/tutors">
                                                <span>
                                                    <img src={HomeTutors} alt="" />
                                                    <img src={HomeTutorsClr} alt="" />
                                                </span>
                                                <span>Home Tutors <FontAwesomeIcon icon={faArrowRight} /></span>
                                            </a>
                                        </li>
                                        <li>
                                            <a className='hover-purple' href="/coding-robotics">
                                                <span>
                                                    <img src={CodingIcon} alt="" />
                                                    <img src={CodingIconClr} alt="" />
                                                </span>                                          
                                                <span>Coding & Robotics <FontAwesomeIcon icon={faArrowRight} /></span>
                                            </a>
                                        </li>
                                        <li>
                                            <a className='hover-pink' href="/teaching-resources">
                                                <span>
                                                    <img src={TeachingIcon} alt="" />
                                                    <img src={TeachingIconClr} alt="" />
                                                </span>  
                                                <span>Teaching Resources <FontAwesomeIcon icon={faArrowRight} /></span>
                                            </a>
                                        </li>
                                        <li>
                                            <a className='hover-blue' href="/school-management-system">
                                                <span>
                                                    <img src={SmsIcon} alt="" />
                                                    <img src={SmsIconClr} alt="" />
                                                </span>  
                                                <span>School Management System <FontAwesomeIcon icon={faArrowRight} /></span>
                                            </a>
                                        </li>
                                        <li>
                                            <a onClick={handleOpen} className='hover-green' href="#">
                                                <span>
                                                    <img src={TutorSignupIcon} alt="" />
                                                    <img src={TutorSignupIconClr} alt="" />
                                                </span>  
                                                <span>Tutor’s Sign up <FontAwesomeIcon icon={faArrowRight} /></span>
                                            </a>

                                            <Modal
                                                open={modalOpen}
                                                onClose={handleClose}
                                                aria-labelledby="modal-modal-title"
                                                aria-describedby="modal-modal-description"
                                            >
                                                <Box sx={style}>
                                                    <div onClick={handleClose} className='closeModal'>
                                                        <div className='closeBtn'></div>
                                                    </div>
                                                    <div className='modalDetails'>
                                                        <div className='hdr lg dark'>What type of tutor are you?</div>
                                                        <div className='modalAction' id="modal-modal-description">
                                                            <FormControlLabel control={<Checkbox checked={subjectChecked} onChange={handleSubjectChange}  />} label="Subject Tutor" />
                                                            <FormControlLabel control={<Checkbox checked={codingChecked} onChange={handleCodingChange} />}  label="Coding Tutor" />
                                                        </div>
                                                        <Button 
                                                            className="hasIcon"
                                                            fullWidth 
                                                            type="button"
                                                            variant="contained"
                                                            onClick={handleSelection}
                                                            sx={{
                                                                width: '100%',
                                                                fontSize: '1rem',
                                                                gap: '15px',
                                                                fontFamily: 'Poppins',
                                                                fontWeight: '700',
                                                                padding: "10px 35px",
                                                                textTransform: "capitalize",
                                                                backgroundColor: "#01C38E",
                                                                ':hover': {
                                                                    backgroundColor: '#01C38E',
                                                                    opacity: '0.8'
                                                                },
                                                            }}
                                                        >Continue to Tutor Registration <FontAwesomeIcon icon={faArrowRight} /></Button>
                                                    </div>
                                                </Box>
                                            </Modal>
                                        </li>
                                    </ul>
                                </div>
                            </Popper>
                        </li>
                        <li>
                            <a href="/contact">Contact Us</a>
                        </li>
                    </ul>
                </div>

                <div className='actions'>
                    <span>
                        <a href='https://ap.nigenius.com.ng/#/auth/login' target='_blank'>Log In</a>
                    </span>
                    <a className='noFormat' href='https://ap.nigenius.com.ng/#/auth/register' target='_blank'>
                        <Button 
                            fullWidth 
                            type="button"
                            variant="contained"
                            sx={{
                                width: 'auto',
                                fontSize: '0.8rem',
                                fontWeight: '700',
                                padding: "10px 15px",
                                backgroundColor: "#01C38E",
                                ':hover': {
                                    backgroundColor: '#01C38E',
                                    opacity: '0.8'
                                },
                            }}
                        >Create Account</Button>
                    </a>
                    
                </div>

                <div className='mobileNav'>
                    <Navbar/>
                </div>
            </div>
        </div>
        
    );
};

export default Header;