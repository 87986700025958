import * as React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import SubjectTutorFormPage from '../components/SubjectTutorFormPage';

const SubjectTutorRegForm = () => {   

  return (
    
    <div className="page-container">
        <Header className='header bkg-green-light' />
        <SubjectTutorFormPage />
        <Footer />
    </div>
    
  );
};

export default SubjectTutorRegForm;