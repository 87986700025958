import * as React from "react";
import { useState, useEffect } from "react";
import { createBrowserHistory } from "history";
import { useDispatch, useSelector } from "react-redux";
import {
  getClasses,
  getSubjects,
  getExams,
  getCountries,
  getStates,
  getCities,
  getLgas,
  sendSubjectTutorReg,
} from "../actions/formdata";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";

import { useFormik } from "formik";
import * as yup from "yup";

import BackBtn from "../assets/img/brand/back-arrow.svg";

import {
  TextField,
  MenuItem,
  Chip,
  Autocomplete,
  Checkbox,
  Box,
  Slider,
  Button,
  createFilterOptions,
  FormControlLabel,
} from "@mui/material";

import InfoIcon from "../assets/img/tutors/info-icon.svg";
import { Countries } from "../content";
import { Subjects } from "../content";
import { Exams } from "../content";
import { Classes } from "../content";
import CircularProgress from '@mui/material/CircularProgress';

const validationSchema = yup.object({
  firstName: yup.string().required("This is a required field"),
  lastName: yup.string().required("This is a required field"),
  email: yup
    .string()
    .email("Please insert a valid email")
    .required("This field is required"),
  phoneNo: yup.string().required("This is a required field"),
  //   country: yup.string().required("This is a required field"),
  //   state: yup.string().required("This is a required field"),
  //   lga: yup.string().required("This is a required field"),
  //   city: yup.string().required("This is a required field"),
  gender: yup.string().required("This is a required field"),
  address: yup.string().required("This is a required field"),
  message: yup.string(),
});

const subjects = Subjects;
const exams = Exams;
const classes = Classes;

const SubjectTutorFormPage = () => {
  //API CALLS

  const dispatch = useDispatch();
  // const stateValues = useSelector((state) => state);

  useEffect(() => {
    dispatch(getClasses());
    dispatch(getSubjects());
    dispatch(getExams());
    dispatch(getCountries());
    dispatch(getStates("NG"));
    dispatch(getCities("NG", "LA"));
    dispatch(getLgas("Lagos"));
  }, []);
  // useEffect(() => {
  //     console.log(stateValues.subjectTutorReg);
  // }, [stateValues]);

  const classes = useSelector((state) => state.classes);
  let classesOptions = [];
  if (!classes.loading) {
    //console.log(classes)
    classesOptions = classes.data.sort(
      (a, b) => parseFloat(a.orderNo) - parseFloat(b.orderNo)
    );
  }

  const subjects = useSelector((state) => state.subjects);
  let subjectOptions = [];
  if (!subjects.loading) {
    subjectOptions = subjects.data;
  }

  const exams = useSelector((state) => state.exams);
  let examOptions = [];
  if (!exams.loading) {
    examOptions = exams.data;
  }

  //const subjectOptions = useSelector((state) => state.subjects.data);

  const countryOptions = useSelector((state) => state.countries.data);

  const stateOptions = useSelector((state) => state.states.data);

  const cityOptions = useSelector((state) => state.cities.data);

  const lgaOptions = useSelector((state) => state.lgas.data);

  //API CALLS END

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNo: "",
      country: "",
      state: "",
      lga: "",
      city: "",
      gender: "",
      address: "",
      message: "",
    },
    onSubmit: (values) => {
      console.log(formik.values);
    },
    validationSchema: validationSchema,
  });

  let flagStyles = { marginRight: "10px" };

  const [valid, setValid] = React.useState(false);
  const checkValidity = () => {
    // do what you want like on submit
    if (formik.isValid) {
      setValid(true);
    } else if (!formik.isValid) {
      setValid(false);
    }
  };

  const [subjectVal, setSubjectVal] = useState([]);
  const onDelete = (title) => () => {
    setSubjectVal((subjectVal) => subjectVal.filter((v) => v.name !== title));
  };

  const [examServices, setExamServices] = useState([]);
  const onExamServicesDelete = (title) => () => {
    setExamServices((examServices) =>
      examServices.filter((v) => v.name !== title)
    );
  };

  const [classCat, setClassCat] = useState([]);
  const onClassCatDelete = (title) => () => {
    setClassCat((classCat) => classCat.filter((v) => v.name !== title));
  };

  const browserHistory = createBrowserHistory();

  const filter = createFilterOptions();
  const [countryValue, setCountryValue] = useState(null);
  const [stateValue, setStateValue] = useState(null);
  const [cityValue, setCityValue] = useState(null);
  const [lgaValue, setLgaValue] = useState(null);

  const [phone, setPhoneValue] = useState(null);
  const [loading, setLoading] = useState(false);


  const submitForm = async () => {
    //Sample alert to use in case of errors or success events
    // Swal.fire({
    //     title: 'Success',
    //     text: 'Your submission was successful',
    //     icon: 'success',
    //     confirmButtonText: 'Ok'
    // });

    let formData = {
      firstName: formik.values.firstName,
      surname: formik.values.lastName, 
      email: formik.values.email,
      phone: Number(phone),
      country: countryValue.name,
      state: stateValue.name,
      city: cityValue.name,
      lga: lgaValue ? lgaValue.title : "",
      address: formik.values.address,
      expectations: formik.values.message,
      gender: formik.values.gender,
      classes: classCat.map(function (el) {
        return el.classId;
      }),
      subjects: subjectVal.map(function (el) {
        return el.subjectId;
      }),
      exams: examServices.map(function (el) {
        return el.id;
      }),
      tutorType: "subject",
    };
    //console.log(lgaValue);
    setLoading(true);

   await dispatch(sendSubjectTutorReg(formData));
    setLoading(false);

    // formik.values.firstName = "";
    // formik.values.lastName = "";
    // formik.values.email = "";
    // setPhoneValue("");
    // setCountryValue("");
    // setStateValue("");
    // setCityValue("");
    // setLgaValue("");
    // formik.values.address = "";
    // formik.values.gender = "";
    // setClassCat([]);
    // setSubjectVal([]);
    // setExamServices([]);
    // formik.values.message = "";
  };

  return (
    <div className="page-content bkg-green-light pgx pgy">
      {/* <div className="blur1"></div>
      <div className="blur2"></div> */}

      <div onClick={browserHistory.back} className="backBtn extend">
        <img src={BackBtn} alt="" />
        <span>Go Back</span>
      </div>

      <div className="row justify-content-center align-items-center">
        <div className="col-lg-8">
          <div className="infoStrip">
            <img src={InfoIcon} alt="" />
            <span>
              If you have previously completed stage 1 registration, click the
              button
            </span>
            <a href="/verification">Continue to stage 2</a>
          </div>
        </div>
      </div>

      <div className="row formCont justify-content-center align-items-center">
        <div className="col-lg-7">
          <div className="sec-title clr-orange mb-3 mb-lg-1">Stage 1</div>
          <div className="hdr xl mb-3 mb-lg-2">
            Subject Tutor Registration Form
          </div>
          <p>
            Simply fill this form to complete the first stage of your tutor
            registration on Nigenius.
          </p>

          <div className="formWrap">
            <form onSubmit={formik.handleSubmit}>
              <div className="fieldRow">
                <div className="fieldHld">
                  <div className="label req">First Name</div>
                  <TextField
                    required
                    fullWidth
                    className="textField"
                    id="firstName"
                    name="firstName"
                    type="text"
                    //placeholder="First Name"
                    value={formik.values.firstName}
                    onBlur={checkValidity}
                    onChange={formik.handleChange}
                    onFocus={formik.handleBlur}
                    variant="outlined"
                    error={
                      formik.touched.firstName &&
                      Boolean(formik.errors.firstName)
                    }
                    helperText={
                      formik.touched.firstName && formik.errors.firstName
                    }
                    // sx={{
                    //     '& .MuiInputBase-root.MuiInput-root:before': {
                    //         borderBottom: '1px solid rgba(255, 255, 255, 0.74)'
                    //     }
                    // }}
                  />
                </div>

                <div className="fieldHld">
                  <div className="label req">Last Name</div>
                  <TextField
                    required
                    fullWidth
                    className="textField"
                    id="lastName"
                    name="lastName"
                    type="text"
                    //placeholder='Last Name'
                    value={formik.values.lastName}
                    onBlur={checkValidity}
                    onChange={formik.handleChange}
                    onFocus={formik.handleBlur}
                    variant="outlined"
                    error={
                      formik.touched.lastName && Boolean(formik.errors.lastName)
                    }
                    helperText={
                      formik.touched.lastName && formik.errors.lastName
                    }
                    // sx={{
                    //     '& .MuiInputBase-root.MuiInput-root:before': {
                    //         borderBottom: '1px solid rgba(255, 255, 255, 0.74)'
                    //     }
                    // }}
                  />
                </div>
              </div>

              <div className="fieldRow">
                <div className="fieldHld">
                  <div className="label req">Email</div>
                  <TextField
                    required
                    fullWidth
                    className="textField"
                    id="email"
                    name="email"
                    type="email"
                    //placeholder='Email'
                    value={formik.values.email}
                    onBlur={checkValidity}
                    onChange={formik.handleChange}
                    onFocus={formik.handleBlur}
                    variant="outlined"
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                </div>

                <div className="fieldHld">
                  <div className="label req">Phone Number(Whatsapp)</div>
                  <PhoneInput
                    country={"ng"}
                    value={phone}
                    onChange={(phone) => setPhoneValue(phone)}
                  />
                </div>
              </div>

              <div className="fieldRow">
                <div className="fieldHld cFull">
                  <div className="label req">Gender</div>
                  <TextField
                    required
                    fullWidth
                    select
                    variant="outlined"
                    id="gender"
                    name="gender"
                    value={formik.values.gender}
                    onBlur={checkValidity}
                    onChange={formik.handleChange}
                    onFocus={formik.handleBlur}
                    error={formik.touched.lga && Boolean(formik.errors.gender)}
                    helperText={formik.touched.lga && formik.errors.gender}
                    sx={{
                      "& .MuiSvgIcon-root": {
                        color: "rgba(0, 0, 0, 0.74)",
                      },
                      "& .MuiInputBase-root": {
                        backgroundColor: "#FFF",
                      },
                      "& .MuiSelect-select": {
                        border: "1px solid rgba(0, 0, 0, 0.3)",
                      },
                    }}
                  >
                    <MenuItem key={"Male"} value={"Male"}>
                      Male
                    </MenuItem>
                    <MenuItem key={"Female"} value={"Female"}>
                      Female
                    </MenuItem>
                    <MenuItem
                      key={"Prefer not to say"}
                      value={"Prefer not to say"}
                    >
                      Prefer not to say
                    </MenuItem>
                  </TextField>
                </div>
              </div>

              <div className="fieldRow">
                <div className="fieldHld">
                  <div className="label req">Country</div>
                  <Autocomplete
                    value={countryValue}
                    onChange={(event, newValue) => {
                      // console.log(newValue);
                      if (typeof newValue === "string") {
                        setCountryValue({
                          title: newValue,
                        });
                      } else if (newValue && newValue.inputValue) {
                        // Create a new value from the user input
                        setCountryValue({
                          title: newValue.inputValue,
                        });
                      } else {
                        setCountryValue(newValue);
                      }
                      dispatch(getStates(newValue.isoCode));
                    }}
                    filterOptions={(countryOptions, params) => {
                      const filtered = filter(countryOptions, params);

                      const { inputValue } = params;
                      // Suggest the creation of a new value
                      const isExisting = countryOptions.some(
                        (country) => inputValue === country.label
                      );
                      if (inputValue !== "" && !isExisting) {
                        filtered.push({
                          inputValue,
                          title: `Add "${inputValue}"`,
                        });
                      }
                      return filtered;
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    id="country-selection"
                    options={countryOptions}
                    getOptionLabel={(country) => {
                      // Value selected with enter, right from the input
                      if (typeof country === "string") {
                        return country;
                      }
                      // Add "xxx" option created dynamically
                      if (country.inputValue) {
                        return country.inputValue;
                      }
                      // Regular option
                      return country.name;
                    }}
                    renderOption={(props, country) => (
                      <li {...props}>{country.name}</li>
                    )}
                    sx={{ width: "100%" }}
                    freeSolo
                    renderInput={(params) => (
                      <TextField
                        variant="outlined"
                        id="country"
                        name="country"
                        {...params}
                        error={
                          formik.touched.country &&
                          Boolean(formik.errors.country)
                        }
                        helperText={
                          formik.touched.country && formik.errors.country
                        }
                        sx={{
                          "& .MuiSvgIcon-root": {
                            color: "rgba(0, 0, 0, 0.74)",
                          },
                          "& .MuiInputBase-root": {
                            backgroundColor: "#FFF",
                            border: "1px solid rgba(0, 0, 0, 0.5)",
                          },
                          "& .MuiSelect-select": {
                            border: "1px solid rgba(0, 0, 0, 0.3)",
                          },
                        }}
                      />
                    )}
                  />
                </div>
              </div>

              <div className="fieldRow">
                <div className="fieldHld">
                  <div className="label req">State of Residence</div>
                  <Autocomplete
                    value={stateValue}
                    onChange={(event, newValue) => {
                      // console.log(newValue);
                      if (typeof newValue === "string") {
                        setStateValue({
                          title: newValue,
                        });
                      } else if (newValue && newValue.inputValue) {
                        // Create a new value from the user input
                        setStateValue({
                          title: newValue.inputValue,
                        });
                      } else {
                        setStateValue(newValue);
                      }
                      dispatch(
                        getCities(countryValue.isoCode, newValue.isoCode)
                      );
                      cityOptions.push({
                        name: "Other",
                        stateCode: "",
                        countryCode: "",
                      });
                      if (countryValue.isoCode == "NG") {
                        if (
                          newValue.name == "Abuja Federal Capital Territory"
                        ) {
                          dispatch(getLgas("Federal Capital Territory"));
                        } else {
                          dispatch(getLgas(newValue.name));
                        }
                      }
                    }}
                    filterOptions={(stateOptions, params) => {
                      const filtered = filter(stateOptions, params);

                      const { inputValue } = params;
                      // Suggest the creation of a new value
                      const isExisting = stateOptions.some(
                        (state) => inputValue === state.name
                      );
                      // if (inputValue !== '' && !isExisting) {
                      //     filtered.push({
                      //         inputValue,
                      //         title: `Add "${inputValue}"`,
                      //     });
                      // }
                      return filtered;
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    id="state-selection"
                    options={stateOptions}
                    getOptionLabel={(state) => {
                      // Value selected with enter, right from the input
                      if (typeof state === "string") {
                        return state;
                      }
                      // Add "xxx" option created dynamically
                      if (state.inputValue) {
                        return state.inputValue;
                      }
                      // Regular option
                      return state.name;
                    }}
                    renderOption={(props, state) => (
                      <li {...props}>{state.name}</li>
                    )}
                    sx={{ width: "100%" }}
                    freeSolo
                    renderInput={(params) => (
                      <TextField
                        variant="outlined"
                        id="state"
                        name="state"
                        {...params}
                        error={
                          formik.touched.state && Boolean(formik.errors.state)
                        }
                        helperText={formik.touched.state && formik.errors.state}
                        sx={{
                          "& .MuiSvgIcon-root": {
                            color: "rgba(0, 0, 0, 0.74)",
                          },
                          "& .MuiInputBase-root": {
                            backgroundColor: "#FFF",
                            border: "1px solid rgba(0, 0, 0, 0.5)",
                          },
                          "& .MuiSelect-select": {
                            border: "1px solid rgba(0, 0, 0, 0.3)",
                          },
                        }}
                      />
                    )}
                  />
                </div>

                <div className="fieldHld">
                  <div className="label req">City</div>
                  <Autocomplete
                    value={cityValue}
                    onChange={(event, newValue) => {
                      // console.log(newValue);
                      if (typeof newValue === "string") {
                        setCityValue({
                          name: newValue,
                        });
                      } else if (newValue && newValue.inputValue) {
                        // Create a new value from the user input
                        setCityValue({
                          name: newValue.inputValue,
                        });
                      } else {
                        setCityValue(newValue);
                      }
                    }}
                    onBlur={(e) => {
                      setCityValue({
                        name: e.target.value,
                      });
                    }}
                    filterOptions={(cityOptions, params) => {
                      const filtered = filter(cityOptions, params);

                      const { inputValue } = params;
                      // Suggest the creation of a new value
                      // const isExisting = cityOptions.some((city) => inputValue === city.name);
                      // if (inputValue !== '' && !isExisting) {
                      //     filtered.push({
                      //         inputValue,
                      //         title: `Add "${inputValue}"`,
                      //     });
                      // }
                      return filtered;
                    }}
                    selectOnFocus
                    //clearOnBlur
                    handleHomeEndKeys
                    id="city-selection"
                    options={cityOptions}
                    getOptionLabel={(city) => {
                      // Value selected with enter, right from the input
                      if (typeof city === "string") {
                        return city;
                      }
                      // Add "xxx" option created dynamically
                      if (city.inputValue) {
                        return city.inputValue;
                      }
                      // Regular option
                      return city.name;
                    }}
                    renderOption={(props, city) => (
                      <li {...props}>{city.name}</li>
                    )}
                    sx={{ width: "100%" }}
                    freeSolo
                    renderInput={(params) => (
                      <TextField
                        variant="outlined"
                        id="city"
                        name="city"
                        {...params}
                        error={
                          formik.touched.city && Boolean(formik.errors.city)
                        }
                        helperText={formik.touched.city && formik.errors.city}
                        sx={{
                          "& .MuiSvgIcon-root": {
                            color: "rgba(0, 0, 0, 0.74)",
                          },
                          "& .MuiInputBase-root": {
                            backgroundColor: "#FFF",
                            border: "1px solid rgba(0, 0, 0, 0.5)",
                          },
                          "& .MuiSelect-select": {
                            border: "1px solid rgba(0, 0, 0, 0.3)",
                          },
                        }}
                      />
                    )}
                  />
                </div>
              </div>

              <div className="fieldRow">
                <div
                  className="fieldHld"
                  style={{
                    display: countryValue?.isoCode == "NG" ? "block" : "none",
                  }}
                >
                  <div className="label req">LGA</div>
                  <Autocomplete
                    value={lgaValue}
                    onChange={(event, newValue) => {
                      // console.log(newValue);
                      if (typeof newValue === "string") {
                        setLgaValue({
                          title: newValue,
                        });
                      } else if (newValue && newValue.inputValue) {
                        // Create a new value from the user input
                        setLgaValue({
                          title: newValue.inputValue,
                        });
                      } else {
                        setLgaValue(newValue);
                      }
                    }}
                    filterOptions={(lgaOptions, params) => {
                      const filtered = filter(lgaOptions, params);

                      const { inputValue } = params;
                      // Suggest the creation of a new value
                      const isExisting = lgaOptions.some(
                        (lga) => inputValue === lga.name
                      );
                      // if (inputValue !== '' && !isExisting) {
                      //     filtered.push({
                      //         inputValue,
                      //         title: `Add "${inputValue}"`,
                      //     });
                      // }
                      return filtered;
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    id="lga-selection"
                    options={lgaOptions}
                    getOptionLabel={(lga) => {
                      // Value selected with enter, right from the input
                      if (typeof lga === "string") {
                        return lga;
                      }
                      // Add "xxx" option created dynamically
                      if (lga.inputValue) {
                        return lga.inputValue;
                      }
                      // Regular option
                      return lga.title;
                    }}
                    renderOption={(props, lga) => <li {...props}>{lga}</li>}
                    sx={{ width: "100%" }}
                    freeSolo
                    renderInput={(params) => (
                      <TextField
                        variant="outlined"
                        id="lga"
                        name="lga"
                        {...params}
                        error={formik.touched.lga && Boolean(formik.errors.lga)}
                        helperText={formik.touched.lga && formik.errors.lga}
                        sx={{
                          "& .MuiSvgIcon-root": {
                            color: "rgba(0, 0, 0, 0.74)",
                          },
                          "& .MuiInputBase-root": {
                            backgroundColor: "#FFF",
                            border: "1px solid rgba(0, 0, 0, 0.5)",
                          },
                          "& .MuiSelect-select": {
                            border: "1px solid rgba(0, 0, 0, 0.3)",
                          },
                        }}
                      />
                    )}
                  />
                </div>
              </div>

              <div className="fieldRow">
                <div className="fieldHld">
                  <div className="label req">Address</div>
                  <TextField
                    required
                    fullWidth
                    className="textField"
                    id="address"
                    name="address"
                    type="text"
                    value={formik.values.address}
                    onBlur={checkValidity}
                    onChange={formik.handleChange}
                    onFocus={formik.handleBlur}
                    variant="outlined"
                    error={
                      formik.touched.address && Boolean(formik.errors.address)
                    }
                    helperText={formik.touched.address && formik.errors.address}
                    // sx={{
                    //     '& .MuiInputBase-root.MuiInput-root:before': {
                    //         borderBottom: '1px solid rgba(255, 255, 255, 0.74)'
                    //     }
                    // }}
                  />
                </div>
              </div>

              <div className="fieldRow">
                <div className="fieldHld auto">
                  <div className="label req">Subjects you can teach</div>
                  <Box>
                    <Autocomplete
                      multiple
                      options={subjectOptions}
                      //defaultValue={[classesOptions[0]]}
                      getOptionLabel={(option) => option.name}
                      value={subjectVal}
                      onChange={(e, newValue) => setSubjectVal(newValue)}
                      renderTags={() => null}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          placeholder="Pick Subjects(s) for your kids"
                        />
                      )}
                      sx={{
                        "& .MuiSvgIcon-root": {
                          color: "rgba(0, 0, 0, 0.74)",
                        },
                        "& .MuiInputBase-root": {
                          backgroundColor: "#FFF",
                          border: "1px solid rgba(0, 0, 0, 0.4)",
                        },
                      }}
                    />
                    <Box
                      mt={2}
                      sx={{
                        "& > :not(:last-child)": { mr: 1 },
                        "& > *": { mr: 1 },
                      }}
                    >
                      {subjectVal.map((v) => (
                        <Chip
                          key={v.name}
                          label={v.name}
                          onDelete={onDelete(v.name)}
                          sx={{
                            "&.MuiButtonBase-root.MuiChip-root": {
                              backgroundColor: "#F6FFFC",
                              border: "0.1rem solid #01C38E",
                              marginBottom: "0.3rem",
                            },
                            "& .MuiSvgIcon-root": {
                              color: "#01C38E",
                            },
                          }}
                        />
                      ))}
                    </Box>
                  </Box>
                </div>
              </div>

              <div className="fieldRow">
                <div className="fieldHld auto">
                  <div className="label req">
                    Which Examinations or Skills can you prepare students for
                  </div>
                  <Box>
                    <Autocomplete
                      multiple
                      options={examOptions}
                      defaultValue={[examOptions[0]]}
                      getOptionLabel={(option) => option.name}
                      value={examServices}
                      onChange={(e, newValue) => setExamServices(newValue)}
                      renderTags={() => null}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          placeholder="Pick exam service(s) for your kids"
                        />
                      )}
                      sx={{
                        "& .MuiSvgIcon-root": {
                          color: "rgba(0, 0, 0, 0.74)",
                        },
                        "& .MuiInputBase-root": {
                          backgroundColor: "#FFF",
                          border: "1px solid rgba(0, 0, 0, 0.4)",
                        },
                      }}
                    />
                    <Box
                      mt={2}
                      sx={{
                        "& > :not(:last-child)": { mr: 1 },
                        "& > *": { mr: 1 },
                      }}
                    >
                      {examServices.map((v) => (
                        <Chip
                          key={v.name}
                          label={v.name}
                          onDelete={onExamServicesDelete(v.name)}
                          sx={{
                            "&.MuiButtonBase-root.MuiChip-root": {
                              backgroundColor: "#F6FFFC",
                              border: "0.1rem solid #01C38E",
                              marginBottom: "0.3rem",
                            },
                            "& .MuiSvgIcon-root": {
                              color: "#01C38E",
                            },
                          }}
                        />
                      ))}
                    </Box>
                  </Box>
                </div>
              </div>

              <div className="fieldRow">
                <div className="fieldHld auto">
                  <div className="label req">Class Category</div>
                  <Box>
                    <Autocomplete
                      multiple
                      options={classesOptions}
                      defaultValue={[classesOptions[0]]}
                      getOptionLabel={(option) => option.name}
                      value={classCat}
                      onChange={(e, newValue) => setClassCat(newValue)}
                      renderTags={() => null}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          placeholder="Pick Class(s) for your kids"
                        />
                      )}
                      sx={{
                        "& .MuiSvgIcon-root": {
                          color: "rgba(0, 0, 0, 0.74)",
                        },
                        "& .MuiInputBase-root": {
                          backgroundColor: "#FFF",
                          border: "1px solid rgba(0, 0, 0, 0.4)",
                        },
                      }}
                    />
                    <Box
                      mt={2}
                      sx={{
                        "& > :not(:last-child)": { mr: 1 },
                        "& > *": { mr: 1 },
                      }}
                    >
                      {classCat.map((v) => (
                        <Chip
                          key={v.name}
                          label={v.name}
                          onDelete={onClassCatDelete(v.name)}
                          sx={{
                            "&.MuiButtonBase-root.MuiChip-root": {
                              backgroundColor: "#F6FFFC",
                              border: "0.1rem solid #01C38E",
                              marginBottom: "0.3rem",
                            },
                            "& .MuiSvgIcon-root": {
                              color: "#01C38E",
                            },
                          }}
                        />
                      ))}
                    </Box>
                  </Box>
                </div>
              </div>

              <div className="fieldRow">
                <div className="fieldHld lg">
                  <div className="label">
                    What are your expectations from signing up as a tutor on
                    Nigenius ?
                  </div>
                  <TextField
                    fullWidth
                    multiline
                    rows={3}
                    maxRows={7}
                    inputProps={{
                      maxLength: 200,
                    }}
                    className="textField"
                    id="message"
                    name="message"
                    type="text"
                    placeholder="Enter Message"
                    value={formik.values.message}
                    onBlur={checkValidity}
                    onChange={formik.handleChange}
                    onFocus={formik.handleBlur}
                    variant="outlined"
                    error={
                      formik.touched.message && Boolean(formik.errors.message)
                    }
                    helperText={
                      formik.touched.message
                        ? formik.errors.message
                        : "Max Word Limit: 200 words"
                    }
                    sx={{
                      "& .MuiInputBase-root": {
                        backgroundColor: "#FFF",
                        padding: "0px",
                        //border: '1px solid rgba(0, 0, 0, 0.3)'
                      },
                    }}
                  />
                </div>
              </div>

              <div className="fieldRow">
                {/* <Button
                  onClick={submitForm}
                  className="hasIcon mainCta extra"
                  fullWidth
                  type="submit"
                  variant="contained"
                >
                  Continue to Stage 2 Form
                </Button> */}
                <Button
                  onClick={submitForm}
                  className="hasIcon mainCta extra"
                  fullWidth 
                  type="submit"
                  variant="contained"
                  disabled={loading} // Disable the button while loading
                >
                 {loading ? <CircularProgress size={24} color='primary' /> : 'Continue to Stage 2 Form'}
                </Button>
                <ToastContainer
                  position="top-center"
                  autoClose={5000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                  theme="light"
                />
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="modal">
        <div className="closeBtn"></div>
        <div className="modalContainer">
          <div className="title">What type of tutor are you?</div>
          <div className="body">
            <FormControlLabel
              control={<Checkbox defaultChecked />}
              label="Subject Tutor"
            />
          </div>
          <div className="action"></div>
        </div>
      </div>
    </div>
  );
};

export default SubjectTutorFormPage;
