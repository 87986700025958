import { GET_SINGLE_TUTOR, GET_SINGLE_TUTOR_ERROR } from '../actions/types';

const initialState = {
    data: null,
    loading: true,
    error: ""
}

export default function(state = initialState, action) {

    const { type, payload } = action;

    switch(type) {

        case GET_SINGLE_TUTOR:
        return {
            ...state,
            data: payload.data,
            loading: false
        }

        case GET_SINGLE_TUTOR_ERROR:
        return {
            ...state,
            error: payload.error,
            loading: false
        }

        default:
        return state;
    }
   
}

