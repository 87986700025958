import * as React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import CheckVerificationPage from '../components/CheckVerificationPage';

const CheckVerification = () => {   

  return (
    
    <div className="page-container">
      {/* <Header className='header bkg-green-light' /> */}
      <CheckVerificationPage />
      {/* <Footer /> */}
    </div>
    
  );
};

export default CheckVerification;