import * as React from 'react';

import { Button, Icon } from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';

import HeroImage1 from "../assets/img/about/aboutHero.png";
import Kelechi from "../assets/img/about/kelechi.jpg";
import Uju from "../assets/img/about/uju.jpg";
import Samson from "../assets/img/about/samson.jpg";

import MissionIcon from "../assets/img/about/mission.svg";
import VisionIcon from "../assets/img/about/vision.svg";

const AboutPage = () => {   

  return (
    
    <div className="page-content pgx pgy">

        <div className='subPages about row justify-content-between align-items-start'>
            <div className='hdr xl mb-4 mb-lg-4'>Get to know us</div>
            <div className='row justify-content-between align-items-start'>
                <div className='col-lg-7 mt-5 mt-lg-0'>
                    <div className='row justify-content-between mt-lg-3'>
                        <div className='col-lg-6 pe-lg-5'>
                            <img className='coyIcon' src={VisionIcon} alt="" />
                            <div className='text mt-3 mt-lg-4'>
                                <h4 className='hdr sm text-uppercase'>Our Vision</h4>
                                <p>To reverse Africa’s massive education gap which threatens its economic growth and peace, by providing access to equitable and quality teaching resources.</p>
                            </div>
                        </div>
                        <div className='col-lg-6 mt-3 mt-lg-0 ps-lg-5'>
                            <img className='coyIcon' src={MissionIcon} alt="" />
                            <div className='text mt-3 mt-lg-4'>
                                <h4 className='hdr sm text-uppercase'>Our Mission</h4>
                                <p>To apply innovation and technology in building solutions that deliver quality education resources to Africa’s young population.</p>
                            </div>
                        </div>
                    </div>
                    <div className='text mt-4 mt-lg-5'>
                        <h4 className='hdr sm text-uppercase'>We will work towards</h4>
                        <ul>
                            <li>The best and most current quality teaching content available for teachers, students, and parents.</li>
                            <li>Building an intelligent virtual assistant that can help teachers anywhere in the world deliver quality teaching resources.</li>
                            <li>Leverage technology to connect parents to professional home tutors for their children.</li>
                        </ul>
                    </div>
                    <div className='btnHld mt-4 mt-lg-5'>
                        <a className='noFormat' href='https://nigenius.com.ng/assets/Subjects.pdf' target='_blank'>
                            <Button 
                                className="hasIcon mainCta"
                                fullWidth 
                                type="button"
                                variant="contained"
                                sx={{
                                    textTransform: "uppercase",
                                }}
                            >View our Subject Directory <FontAwesomeIcon icon={faArrowRight} /></Button>
                        </a>
                        

                    </div>
                </div>
                <div className='col-lg-4 single hasBlur d-flex justify-content-between align-items-center'>
                    <div className='box-card md clr-pink text-right'>
                        <img className='bkg' src={HeroImage1} alt="" />
                    </div>
                </div>
            </div>
            
        </div>

        <div className='row justify-content-center align-items-center pgy'>
            <div className='col-lg-6 mt-lg-5'>
                <div className='hdr lg dark text-center'>Our Co founders</div>
                <div className='text text-center mt-lg-3'>
                    <p>With over 10 years industry experience, our team is best suited to find the perfect tutors for your kids</p>
                </div>
            </div>
            
            <div className='col-lg-10 teamWrap'>
                <div className='teamHld'>
                    <div className='cont'>
                        <img className='bkg' src={Uju} alt="" />
                        <div className='details'>
                            <div className='name'>Obianuju Uchenna</div>
                            <div className='role'>Co-founder/CFO</div>
                            <div className='social'>
                                <a href="">
                                    <Icon>
                                        <FontAwesomeIcon icon={faLinkedin} />
                                    </Icon>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='teamHld'>
                    <div className='cont'>
                        <img className='bkg' src={Kelechi} alt="" />
                        <div className='details'>
                            <div className='name'>Kelechi Uchenna</div>
                            <div className='role'>Co-founder/CEO</div>
                            <div className='social'>
                                <a href="">
                                    <Icon>
                                        <FontAwesomeIcon icon={faLinkedin} />
                                    </Icon>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='teamHld'>
                    <div className='cont'>
                        <img className='bkg' src={Samson} alt="" />
                        <div className='details'>
                            <div className='name'>Samson Odele</div>
                            <div className='role'>Co-founder/CTO</div>
                            <div className='social'>
                                <a href="">
                                    <Icon>
                                        <FontAwesomeIcon icon={faLinkedin} />
                                    </Icon>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
  );
};

export default AboutPage;