import * as React from 'react';
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { verifyCode, sendEmailForCode } from '../actions/formdata';
//import TutorAccordion from './tutorFilter/TutorAccordion';

import { useFormik } from "formik";
import * as yup from "yup";

import Logo from '../assets/img/brand/logo.svg';


const validationSchema = yup.object({
    email: yup.string().email("Please insert a valid email").required("This field is required"),
    verifyCode: yup.string().required("This field is required"),
})

const style = {
    width: '100%',
    display: 'inline-block',
};


const CheckVerificationPage = () => {

    const params = useParams();
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const verifyStatus = useSelector((state) => state.verifyCode);
    // console.log(verifyStatus);

    useEffect(() => {
        // console.log(params);
        if(params != "") {
            dispatch(verifyCode(params.codeId)).then(() => {
                // console.log(verifyStatus);
                if (verifyStatus.data != null) {
                    if (verifyStatus.data.data.type == 'coding') {
                        navigate("/coding-tutor-registration-form-stage-2");
                    }
                    else if (verifyStatus.data.data.type == 'subject') {
                        navigate("/subject-tutor-registration-form-stage-2");
                    }                 
                }
            })
        }
        
    }, [verifyStatus]);


    const formik = useFormik({
        initialValues: {
            email: "",
        },
        validationSchema: validationSchema
    });

    const [valid, setValid] = React.useState(false);
    const checkValidity = () => {
        // do what you want like on submit
        if (formik.isValid) {
            setValid(true);
        }
        else if(!formik.isValid) {
            setValid(false);
        }     
    }

    // const submitForm = () => {
    //     let formData = {
    //         email: formik.values.email,
    //     }
    //     //console.log(lgaValue);

    //     dispatch(sendEmailForCode(formData));
        
    //     formik.values.email = "";
    // }


    return (
        
        <div className="page-content pgx pgy">

            <div className='row pulseWrap d-flex justify-content-center align-items-center'>
                <img className="pulseAnimation" src={Logo} alt="" />
            </div>

        </div>
        
    );
};

export default CheckVerificationPage;