import * as React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import TutorsPage from '../components/TutorsPage';

const Tutor = () => {   

  return (
    
    <div className="page-container">
      <Header className='header bkg-pale-yellow' />
      <TutorsPage />
      <Footer />
    </div>
    
  );
};

export default Tutor;