import React from 'react'
import Carousel from 'react-grid-carousel'

const Testimonials = () => {
  return (
    <Carousel cols={3} rows={1} gap={10} loop>
      <Carousel.Item>
        <div className='testimonialHld'>
            <div className='content'>“I am so glad I chose Nigenius tutoring service for my four kids. The Instructors are warming, receptive, detailed, professional; and extremely patient. They are always willing to answer any questions and very flexible with scheduling tutoring sessions; which gives room for the graphical progression of each child's performance to be explained."</div>
            <div className='source'>
                <div className='name'>Parent Feedback on Tutor Ajayi</div>
                <div className='cat'>Mrs Oluwaseni</div>
            </div>
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div className='testimonialHld'>
            <div className='content'>“I joined Nigenius when I was desperately looking for tutors for my child. I was sceptical at first because I was unsure what to expect particularly given the way things happen in Nigeria. The first difference I observed was speaking to the owner. He was professional and determined to find a tutor that I was happy with as well not just whoever he was able to find."</div>
            <div className='source'>
                <div className='name'>Parent Feedback on Tutor Oge</div>
                <div className='cat'>Mr Ayo</div>
            </div>
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div className='testimonialHld'>
            <div className='content'>“I like the efficiency and consistency of Nigenius tutors. The teachers have their curriculum, are always on time and know how to handle these young kids. They are patient, flexible and knowledgeable. I like how we are given reports every month on the kids progress and can also see the improvements in their school education.”</div>
            <div className='source'>
                <div className='name'>Parent Feedback on Tutor Funke</div>
                <div className='cat'>Mrs Jade</div>
            </div>
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div className='testimonialHld'>
            <div className='content'>“I'd highly recommend Nigenius to any parent wanting to provide academic support for their child(ren) Mr Kelechi and the tutors will work with you to get to the best outcome for your child(ren). Oh did I add, you also get a progress report which you can discuss with the tutors and work out read of focus for your child etc. What are you waiting for to sign up ?"</div>
            <div className='source'>
                <div className='name'>Parent Feedback on Tutor Oge</div>
                <div className='cat'>Mr Ayo</div>
            </div>
        </div>
      </Carousel.Item>
      
      <Carousel.Item>
        <div className='testimonialHld'>
            <div className='content'>“Nigenius tutors are sensitive to the academic needs of each child and plans their daily worksheets accordingly. Also, the assigned Instructors are usually very patient and encouraging during classes. I will highly recommend Nigenius tutoring service to friends and family.”</div>
            <div className='source'>
                <div className='name'>Parent Feedback on Tutor Grace</div>
                <div className='cat'>Mrs Oluwaseni</div>
            </div>
        </div>
      </Carousel.Item>

      <Carousel.Item>
        <div className='testimonialHld'>
            <div className='content'>“Peter is doing a commendable job. He's taking my feedback on areas of concentration and has shown real patience in his approach."</div>
            <div className='source'>
                <div className='name'>Parent Feedback on Tutor Peter</div>
                <div className='cat'>Mr Yakunat</div>
            </div>
        </div>
      </Carousel.Item>

    </Carousel>
  )
}

export default Testimonials