import * as React from 'react';

import Header from '../components/Header';
import Footer from '../components/Footer';
import Management from '../components/Management';

const Sms = () => {   

  return (
    
    <div className="page-container">
      <Header className='header bkg-pink' />
      <Management />
      <Footer />
    </div>
    
  );
};

export default Sms;