import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import {
  GET_CLASSES,
  GET_CLASSES_ERROR,
  GET_SUBJECTS,
  GET_SUBJECTS_ERROR,
  GET_COUNTRIES,
  GET_COUNTRIES_ERROR,
  GET_CODING_LANGUAGES,
  GET_CODING_LANGUAGES_ERROR,
  GET_STATES,
  GET_STATES_ERROR,
  GET_CITIES,
  GET_CITIES_ERROR,
  GET_LGAS,
  GET_LGAS_ERROR,
  POST_CODING_TUTOR_REQ,
  POST_CODING_TUTOR_REQ_ERROR,
  POST_SCHOOL_CODING_REQ,
  POST_SCHOOL_CODING_REQ_ERROR,
  GET_EXAMS,
  GET_EXAMS_ERROR,
  POST_SUBJECT_TUTOR_REQ,
  POST_SUBJECT_TUTOR_REQ_ERROR,
  POST_CODING_TUTOR_REG,
  POST_CODING_TUTOR_REG_ERROR,
  POST_SUBJECT_TUTOR_REG,
  POST_SUBJECT_TUTOR_REG_ERROR,
  POST_EMAIL_FOR_CODE,
  POST_EMAIL_FOR_CODE_ERROR,
  POST_CODE,
  POST_CODE_ERROR,
  POST_TUTOR_FINAL_REG,
  POST_TUTOR_FINAL_REG_ERROR,
  GET_TUTORS,
  GET_TUTORS_ERROR,
  SEARCH_TUTORS,
  SEARCH_TUTORS_ERROR,
  GET_SINGLE_TUTOR,
  GET_SINGLE_TUTOR_ERROR,
  GET_GALLARY,
  GET_GALLARY_ERROR,
  GET_STATS,
  GET_STATS_ERROR,
} from "./types";

let baseUrl = process.env.REACT_APP_BASE_API_URL;

export const getClasses = () => async (dispatch) => {
  try {
    const res = await axios.get(`${baseUrl}/classes`);
    dispatch({
      type: GET_CLASSES,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_CLASSES_ERROR,
      payload: err,
    });
  }
};

export const getSubjects = () => async (dispatch) => {
  try {
    const res = await axios.get(`${baseUrl}/subjects`);
    dispatch({
      type: GET_SUBJECTS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_SUBJECTS_ERROR,
      payload: err,
    });
  }
};

export const getExams = () => async (dispatch) => {
  try {
    const res = await axios.get(`${baseUrl}/exams`);
    dispatch({
      type: GET_EXAMS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_EXAMS_ERROR,
      payload: err,
    });
  }
};

export const getCodingLanguages = () => async (dispatch) => {
  try {
    const res = await axios.get(`${baseUrl}/coding-classes`);
    dispatch({
      type: GET_CODING_LANGUAGES,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_CODING_LANGUAGES_ERROR,
      payload: err,
    });
  }
};

export const getCountries = () => async (dispatch) => {
  try {
    const res = await axios.get(`${baseUrl}/countries`);
    dispatch({
      type: GET_COUNTRIES,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_COUNTRIES_ERROR,
      payload: err,
    });
  }
};

export const getStates = (countryCode) => async (dispatch) => {
  try {
    const res = await axios.get(`${baseUrl}/states?countryCode=${countryCode}`);
    dispatch({
      type: GET_STATES,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_STATES_ERROR,
      payload: err,
    });
  }
};

export const getCities = (countryCode, stateCode) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${baseUrl}/cities?countryCode=${countryCode}&stateCode=${stateCode}`
    );
    dispatch({
      type: GET_CITIES,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_CITIES_ERROR,
      payload: err,
    });
  }
};

export const getLgas = (state) => async (dispatch) => {
  try {
    const res = await axios.get(`${baseUrl}/lgas?state=${state}`);
    dispatch({
      type: GET_LGAS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_LGAS_ERROR,
      payload: err,
    });
  }
};

export const sendCodingTutorReq = (body) => async (dispatch) => {
  Swal.fire({
    title: "Request Submitting",
    text: "Your coding tutor request is submiting",
    icon: "info",
    allowOutsideClick: false,
    allowEscapeKey: true,
    allowEnterClick: false,
    showConfirmButton: false,
  });


  const config = {
    headers: {
      "content-type": "application/json",
    },
  };
  // const body = JSON.stringify({
  //     phoneNo: phoneNo
  //  });

  try {
    const res = await axios.post(
      `${baseUrl}/forms/tutor-request`,
      body,
      config
    );
    console.log(res);
    dispatch({
      type: POST_CODING_TUTOR_REQ,
      payload: res.data,
    });
    // console.log(res);
    if (res.data.statusCode == 201) {

      // toast.success(res.data.msg);
      // alert("Your submission was successful");
      Swal.fire({
        title: "Success",
        text: "Your submission was successful",
        icon: "success",
        confirmButtonText: "Ok",
      });
    } else if (res.data.statusCode == 400) {

      // toast.error("An error occured. Please try again!");
      // alert("An error occured. Please try again");
      Swal.fire({
        title: "Error!",
        text: "An error occured. Please try again",
        icon: "error",
        confirmButtonText: "Ok",
      });
    }
  } catch (err) {
    dispatch({
      type: POST_CODING_TUTOR_REQ_ERROR,
      payload: err,
    });
  }
};

export const sendCodingTutorReg = (body) => async (dispatch) => {
  //console.log('jejeheh')
  const config = {
    headers: {
      "content-type": "application/json",
    },
  };
  // const body = JSON.stringify({
  //     phoneNo: phoneNo
  //  });

  try {
    const res = await axios.post(
      `${baseUrl}/forms/tutor-stage-one`,
      body,
      config
    );
    dispatch({
      type: POST_CODING_TUTOR_REG,
      payload: res.data,
    });
    console.log(res);
    if (res.data.statusCode == 201) {

      // toast.success("Your submission was successful");
      //alert("Your submission was successful");
      Swal.fire({
        title: "Success",
        text: "Your submission was successful",
        icon: "success",
        confirmButtonText: "Ok",
      });
      let routedLink = res.data.data.link;
      window.location.href = `${routedLink}`;
    } else if (res.data.statusCode == 400) {
      // toast.error("An error occured. Please try again!");
      // alert("An error occured. Please try again");
      Swal.fire({
        title: "Error!",
        text: "An error occured. Please try again",
        icon: "error",
        confirmButtonText: "Ok",
      });
    }
  } catch (err) {
    Swal.fire({
      title: "Error!",
      text: err.response.data.msg,
      icon: "error",
      confirmButtonText: "Ok",
    });
    dispatch({
      type: POST_CODING_TUTOR_REG_ERROR,
      payload: err,
    });
  }
};

export const sendSchoolCodingReq = (body) => async (dispatch) => {
  //console.log('jejeheh')
  const config = {
    headers: {
      "content-type": "application/json",
    },
  };
  try {
    const res = await axios.post(
      `${baseUrl}/forms/tutor-request`,
      body,
      config
    );
    dispatch({
      type: POST_SCHOOL_CODING_REQ,
      payload: res.data,
    });
    // console.log(res);
    if (res.data != null) {
      // toast.success("Your submission was successful");
      //alert("Your submission was successful");
      Swal.fire({
        title: "Success",
        text: "Your submission was successful",
        icon: "success",
        confirmButtonText: "Ok",
      });
    } else {
      // toast.error("An error occured. Please try again!");
      // alert("An error occured. Please try again");
      Swal.fire({
        title: "Error!",
        text: "An error occured. Please try again",
        icon: "error",
        confirmButtonText: "Ok",
      });
    }
  } catch (err) {
    dispatch({
      type: POST_SCHOOL_CODING_REQ_ERROR,
      payload: err,
    });
  }
};

export const sendSubjectTutorReq = (body) => async (dispatch) => {

  Swal.fire({
    title: "Request Submitting",
    text: "Your subject tutor request is submiting",
    icon: "info",
    allowOutsideClick: false,
    allowEscapeKey: true,
    allowEnterClick: false,
    showConfirmButton: false,
  });

  const config = {
    headers: {
      "content-type": "application/json",
    },
  };
  try {
    const res = await axios.post(
      `${baseUrl}/forms/tutor-request`,
      body,
      config
    );
    dispatch({
      type: POST_SUBJECT_TUTOR_REQ,
      payload: res.data,
    });

    if (res.data != null) {
      //toast.success("Your submission was successful");
      //alert("Your submission was successful");
      Swal.fire({
        title: "Success",
        text: "Your submission was successful",
        icon: "success",
        confirmButtonText: "Ok",
      });
    } else {
      // toast.error("An error occured. Please try again!");
      //alert("An error occured. Please try again");
      Swal.fire({
        title: "Error!",
        text: "An error occured. Please try again",
        icon: "error",
        confirmButtonText: "Ok",
      });
    }
  } catch (err) {
    dispatch({
      type: POST_SUBJECT_TUTOR_REQ_ERROR,
      payload: err,
    });
  }
};

export const sendSubjectTutorReg = (body) => async (dispatch) => {
  //console.log('jejeheh')
  //const navigate = useNavigate();

  const config = {
    headers: {
      "content-type": "application/json",
    },
  };

  try {
    const res = await axios.post(
      `${baseUrl}/forms/tutor-stage-one`,
      body,
      config
    );
    dispatch({
      type: POST_SUBJECT_TUTOR_REG,
      payload: res.data,
    });
    console.log(res.data);
    if (res.data.statusCode == 201) {
      // toast.success("Your submission was successful");
      //alert("Your submission was successful");
      Swal.fire({
        title: "Success",
        text: "Your submission was successful",
        icon: "success",
        confirmButtonText: "Ok",
      });
      let routedLink = res.data.data.link;
      window.location.href = `${routedLink}`;
    } else if (res.data.statusCode == 400) {
      // toast.error("An error occured. Please try again!");
      //alert("An error occured. Please try again");
      Swal.fire({
        title: "Error!",
        text: res.data.data.message,
        icon: "error",
        confirmButtonText: "Ok",
      });
    }
  } catch (err) {
    console.log(err.response)
    Swal.fire({
      title: "Error!",
      text: err.response.data.msg,
      icon: "error",
      confirmButtonText: "Ok",
    });
    dispatch({
      type: POST_SUBJECT_TUTOR_REG_ERROR,
      payload: err,
    });
  }
};

export const sendEmailForCode = (body) => async (dispatch) => {
  //console.log('jejeheh')
  const config = {
    headers: {
      "content-type": "application/json",
    },
  };
  // const body = JSON.stringify({
  //     phoneNo: phoneNo
  //  });

  try {
    const res = await axios.post(`${baseUrl}/forms/send-code`, body, config);
    dispatch({
      type: POST_EMAIL_FOR_CODE,
      payload: res.data,
    });
    // console.log(res);
    if (res.data.statusCode == 201) {
      // toast.success(res.data.msg);
      //alert("Your submission was successful and a registration link has been sent to your email.");
      Swal.fire({
        title: "Success",
        text: "Your email submission was successful and a registration link has been sent to your email.",
        icon: "success",
        confirmButtonText: "Ok",
      });
    } else {
      // toast.error("An error occured. Please try again!");
      // alert("An error occured. Please try again");
      Swal.fire({
        title: "Error!",
        text: "An error occured. Please try again",
        icon: "error",
        confirmButtonText: "Ok",
      });
    }
  } catch (err) {
    if (err) {
      // toast.error(err.response.data.msg.message);
      // alert(err.response.data.msg.message);
      Swal.fire({
        title: "Error!",
        text: err.response.data.msg.message,
        icon: "error",
        confirmButtonText: "Ok",
      });
    }
    dispatch({
      type: POST_EMAIL_FOR_CODE_ERROR,
      payload: err,
    });
  }
};

export const verifyCode = (code) => async (dispatch) => {
  //console.log('jejeheh')
  const config = {
    headers: {
      "content-type": "application/json",
    },
  };

  try {
    const res = await axios.post(
      `${baseUrl}/forms/verify-code/${code}`,
      config
    );
    // console.log(res.data);
    dispatch({
      type: POST_CODE,
      payload: res.data,
      // response: res
    });
  } catch (err) {
    Swal.fire({
      title: "Error!",
      text: err.response.data.msg,
      icon: "error",
      confirmButtonText: "Ok",
    });
    dispatch({
      type: POST_CODE_ERROR,
      payload: err,
    });
  }
};

export const sendTutorFinalReg = (body) => async (dispatch) => {
  //console.log('jejeheh')
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };

  try {
    const res = await axios.post(
      `${baseUrl}/forms/tutor-stage-two`,
      body,
      config
    );
    dispatch({
      type: POST_TUTOR_FINAL_REG,
      payload: res.data,
    });
    console.log({ res });
    if (res.data != null) {
      // toast.success('Your submission was successful');
      // alert("Your submission was successful");
      Swal.fire({
        title: "Success",
        text: res.data.msg,
        icon: "success",
        confirmButtonText: "Ok",
      });
    } else {
      // toast.error("An error occured. Please try again!");
      // alert("An error occured. Please try again");
      Swal.fire({
        title: "Error!",
        text: "An error occured. Please try again",
        icon: "error",
        confirmButtonText: "Ok",
      });
    }
  } catch (err) {

    if (err) {
      Swal.fire({
        title: "Error!",
        text: err.message,
        icon: "error",
        confirmButtonText: "Ok",
      });
    }
    dispatch({
      type: POST_TUTOR_FINAL_REG_ERROR,
      payload: err,
    });
  }
};

export const getTutors = (parameter) => async (dispatch) => {
  try {
    let res;
    if (parameter == "none") {
      res = await axios.get(`${baseUrl}/tutor-gallery`);
    } else {
      res = await axios.get(`${baseUrl}/tutor-gallery?search=${parameter}`);
    }
    dispatch({
      type: GET_TUTORS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_TUTORS_ERROR,
      payload: err,
    });
  }
};

export const getTutorsPage = (page) => async (dispatch) => {
  try {
    let res = await axios.get(`${baseUrl}/tutor-gallery?page=${page}`);

    dispatch({
      type: GET_TUTORS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_TUTORS_ERROR,
      payload: err,
    });
  }
};

export const getSingleTutor = (tutorId) => async (dispatch) => {
  try {
    const res = await axios.get(`${baseUrl}/tutor-gallery/${tutorId}`);
    dispatch({
      type: GET_SINGLE_TUTOR,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_SINGLE_TUTOR_ERROR,
      payload: err,
    });
  }
};

export const searchTutors = (subjectVal, classVal) => async (dispatch) => {
  //console.log(subjectVal);
  //console.log(classVal);

  try {
    let res;
    if (subjectVal && classVal.length < 1) {
      res = await axios.get(
        `${baseUrl}/tutor-gallery?subjectName=${subjectVal}`
      );
    } else if (classVal && subjectVal.length < 1) {
      res = await axios.get(`${baseUrl}/tutor-gallery?className=${classVal}`);
    } else if (subjectVal && classVal) {
      res = await axios.get(
        `${baseUrl}/tutor-gallery?subjectName=${subjectVal}&className=${classVal}`
      );
    }

    //console.log(res);

    dispatch({
      type: SEARCH_TUTORS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: SEARCH_TUTORS_ERROR,
      payload: err,
    });
  }
};

export const getCodingGallery = () => async (dispatch) => {
  try {
    const res = await axios.get(`${baseUrl}/cr/logos`);
    dispatch({
      type: GET_GALLARY,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_GALLARY_ERROR,
      payload: err,
    });
  }
};

export const getNigeniusStats = () => async (dispatch) => {
  try {
    const res = await axios.get(`${baseUrl}/cr/logos`);
    dispatch({
      type: GET_STATS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_STATS_ERROR,
      payload: err,
    });
  }
};
