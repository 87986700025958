import * as React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
//import shortid from "https://cdn.skypack.dev/shortid@2.2.16";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useParams, useNavigate } from "react-router-dom";
import { sendTutorFinalReg } from "../actions/formdata";

import { useFormik, FormikProvider } from "formik";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import Upload from "../assets/img/tutors/upload.svg";
import Camera from "../assets/img/tutors/camera-icon.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt } from "@fortawesome/free-solid-svg-icons";
import CircularProgress from '@mui/material/CircularProgress';

import {
  TextField,
  MenuItem,
  Checkbox,
  Button,
  FormControlLabel,
} from "@mui/material";

import { Subjects } from "../content";
import { Exams } from "../content";
import { Classes } from "../content";

const validationSchema = yup.object({
  facebookUrl: yup.string(),
  instagramUrl: yup.string(),
  identification: yup.string().required("This is a required field"),
  currentlyEmployed: yup.string().required("This is a required field"),
  teachingExperience: yup.string().required("This is a required field"),
  homeTutoringExperience: yup.string().required("This is a required field"),
  personalLaptop: yup.string().required("This is a required field"),
  message: yup.string().required("This is a required field"),
  referee1FirstName: yup.string().required("This is a required field"),
  referee1LastName: yup.string().required("This is a required field"),
  referee1Address: yup.string().required("This is a required field"),
  referee1Email: yup
    .string()
    .email("Please insert a valid email")
    .required("This field is required"),
  referee2FirstName: yup.string().required("This is a required field"),
  referee2LastName: yup.string().required("This is a required field"),
  referee2Address: yup.string().required("This is a required field"),
  referee2Email: yup
    .string()
    .email("Please insert a valid email")
    .required("This field is required"),
});

const subjects = Subjects;
const exams = Exams;
const classes = Classes;

const SubjectTutorForm2Page = () => {
  const params = useParams();
  // console.log(params);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const verifyStatus = useSelector((state) => state.verifyCode);
  console.log(verifyStatus);
  const [loader, setLoading] = useState(false);

  useEffect(() => {
    if (verifyStatus.data == null) {

      navigate("/subject-tutor-registration-form");
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      facebookUrl: "",
      instagramUrl: "",
      currentlyEmployed: "",
      teachingExperience: "",
      identification: "",
      homeTutoringExperience: "",
      personalLaptop: "",
      message: "",
      referee1FirstName: "",
      referee1LastName: "",
      referee1Address: "",
      referee1Email: "",
      referee1Phone: "",
      referee2FirstName: "",
      referee2LastName: "",
      referee2Address: "",
      referee2Email: "",
      referee2Phone: "",
    },
    onSubmit: (values) => {
      console.log(formik.values);
    },
    validationSchema: validationSchema,
  });

  let flagStyles = { marginRight: "10px" };

  const [valid, setValid] = React.useState(false);

  const checkValidity = () => {
    // do what you want like on submit
    if (formik.isValid) {
      setValid(true);
    } else if (!formik.isValid) {
      setValid(false);
    }
  };

  const [subjectVal, setSubjectVal] = useState([]);
  const onDelete = (title) => () => {
    setSubjectVal((subjectVal) => subjectVal.filter((v) => v.title !== title));
  };

  const [examExpertise, setExamExpertise] = useState([]);
  const onExamExpertiseDelete = (title) => () => {
    setExamExpertise((examExpertise) =>
      examExpertise.filter((v) => v.title !== title)
    );
  };

  const [classCat, setClassCat] = useState([]);
  const onClassCatDelete = (title) => () => {
    setClassCat((classCat) => classCat.filter((v) => v.title !== title));
  };

  const filesizes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  //Image Upload
  const ref = React.useRef(null);
  const [src, setSrc] = useState("");
  const [imgValue, setImgValue] = useState(null);
  const onChange = (event) => {
    setImgValue(event.target.files[0]);
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e) => {
        setSrc(e.target.result);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  //ID Upload
  const refId = React.useRef(null);
  const [srcId, setSrcId] = useState([]);
  const [idValue, setIdValue] = useState(null);
  const onScrIdChange = (e) => {
    console.log(e.target.files[0]);
    setIdValue(e.target.files[0]);
    // setSrcId(e.target.files[0]);

    // let docs;
    // for (let i = 0; i < e.target.files.length; i++) {
    //     docs = (e.target.files[i]);
    //     let reader = new FileReader();
    //     let file = e.target.files[i];
    //     reader.onloadend = () => {
    //         setSrcId((preValue) =>
    //              [
    //                 {
    //                     //id: shortid.generate(),
    //                     filename: e.target.files[i].name,
    //                     filetype: e.target.files[i].type,
    //                     fileimage: reader.result,
    //                     datetime: e.target.files[i].lastModifiedDate.toLocaleString('en-IN'),
    //                     filesize: filesizes(e.target.files[i].size)
    //                 }
    //             ]
    //         );
    //     }
    //     if (e.target.files[i]) {
    //         reader.readAsDataURL(file);
    //     }
    // }
    // setIdValue(e.target.files[0]); // Assuming you only need the first file
    let files = e.target.files;

    // Loop through each file
    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      let reader = new FileReader();

      // Callback function for when file loading is complete
      reader.onload = () => {
        // Update state with file information
        // setSrcId((prevValue) => [
        //     ...prevValue,
        //     {
        //         filename: file.name,
        //         filetype: file.type,
        //         fileimage: reader.result,
        //         datetime: file.lastModifiedDate.toLocaleString('en-IN'),
        //         filesize: filesizes(file.size) // Assuming filesizes is defined elsewhere
        //     }
        // ]);
      };

      // Read file as Data URL
      if (file) {
        reader.readAsDataURL(file);
      }
    }
  };

  //CV Upload
  const refCv = React.useRef(null);
  const [srcCv, setSrcCv] = useState([]);
  const [cvValue, setCvValue] = useState(null);
  const onScrCvChange = (e) => {
    setCvValue(e.target.files[0]);
    // let docs;
    // for (let i = 0; i < e.target.files.length; i++) {
    //     docs = (e.target.files[i]);
    //     let reader = new FileReader();
    //     let file = e.target.files[i];
    //     reader.onloadend = () => {
    //         setSrcCv((preValue) => {
    //             return [
    //                 {
    //                     //id: shortid.generate(),
    //                     filename: e.target.files[i].name,
    //                     filetype: e.target.files[i].type,
    //                     fileimage: reader.result,
    //                     datetime: e.target.files[i].lastModifiedDate.toLocaleString('en-IN'),
    //                     filesize: filesizes(e.target.files[i].size)
    //                 }
    //             ]
    //         });
    //     }
    //     if (e.target.files[i]) {
    //         reader.readAsDataURL(file);
    //     }
    // }
  };

  const [refPhone1, setRefPhone1] = useState(null);
  const [refPhone2, setRefPhone2] = useState(null);

  // Referee Addition
  // const [refIndexes, setRefIndexes] = useState([]);
  // const [refCounter, setRefCounter] = useState(0);

  // const addRef = () => {
  //     setRefIndexes(prevIndexes => [...prevIndexes, refCounter]);
  //     setRefCounter(prevCounter => prevCounter + 1);
  // };

  // const removeRef = index => () => {
  //     setRefIndexes(prevIndexes => [...prevIndexes.filter(item => item !== index)]);
  //     setRefCounter(prevCounter => prevCounter - 1);
  // };

  const submitForm = (e) => {
    e.preventDefault()


    console.log({loader})

    let referees = [
      {
        userId: Number(verifyStatus.data.data.userId),
        name:
          formik.values.referee1FirstName +
          " " +
          formik.values.referee1LastName,
        email: formik.values.referee1Email,
        address: formik.values.referee1Address,
        phone: Number(refPhone1),
      },
      {
        userId: Number(verifyStatus.data.data.userId),
        name:
          formik.values.referee2FirstName +
          " " +
          formik.values.referee2LastName,
        email: formik.values.referee2Email,
        address: formik.values.referee2Address,
        phone: Number(refPhone2),
      },
    ];

    const formData = new FormData();

    formData.append("userId", Number(verifyStatus.data.data.userId));
    formData.append("facebookUrl", formik.values.facebookUrl);
    formData.append("instagramUrl", formik.values.instagramUrl);
    formData.append("employmentStatus", formik.values.currentlyEmployed);
    formData.append("yrsExperience", formik.values.teachingExperience);
    formData.append("htExperience", formik.values.homeTutoringExperience);
    formData.append("type", "subject");
    formData.append("ownComputer", formik.values.personalLaptop);
    //formData.append('referees', formik.values.referees);
    formData.append("tutor_photo", imgValue);
    formData.append("tutor_id_card", idValue);
    formData.append("tutor_cv_doc", cvValue);
    formData.append("referees", JSON.stringify(referees));

    //formik.values.referees.forEach(referee => formData.append('referees[]', referee))

    // console.log(...formData);
    // console.log(formik.values.referee1Phone);
    setLoading(true);

    dispatch(sendTutorFinalReg(formData));
    setLoading(false);

    // formik.values.facebookUrl = "";
    // formik.values.instagramUrl = "";
    // formik.values.currentlyEmployed = "";
    // formik.values.teachingExperience = "";
    // formik.values.homeTutoringExperience = "";
    // formik.values.personalLaptop = "";
    // setImgValue(null);
    // setIdValue(null);
    // setCvValue(null);
    // formik.values.referee1FirstName = "";
    // formik.values.referee1LastName = "";
    // formik.values.referee1Email = "";
    // formik.values.referee1Address = "";
    // setRefPhone1("");
    // formik.values.referee2FirstName = "";
    // formik.values.referee2LastName = "";
    // formik.values.referee2Email = "";
    // formik.values.referee2Address = "";
    // setRefPhone2("");
  };

  return (
    <div className="page-content bkg-green-light pgx pgy">
      <div className="row formCont no-pad justify-content-center align-items-center">
        <div className="col-lg-11">
          <div className="sec-title clr-orange mb-3 mb-lg-1">Stage 2</div>
          <div className="hdr xl mb-3 mb-lg-2">
            Subject Tutor Registration Form
          </div>
          <p>
            Simply fill this form to complete the second stage of your tutor
            registration on Nigenius.
          </p>

          <div className="formWrap stage2">
            <FormikProvider value={formik}>
              <form>
                <div className="fieldRow">
                  <div className="upload1">
                    <div className="label req">Image Upload</div>
                    <button onClick={() => ref.current.click()}>
                      <img src={Upload} alt="" />
                      Upload a clear headshot picture or <span>Choose</span> it
                      from your device
                    </button>
                    <input
                      className="fileInput"
                      ref={ref}
                      type="file"
                      onChange={onChange}
                      placeholder="Upload  a clear headshot picture or Choose it from your device"
                    />
                  </div>
                </div>

                <div className="fieldRow">
                  <div className="fieldHld">
                    <div className="label req">Means of Identification</div>
                    <TextField
                      required
                      fullWidth
                      select
                      variant="outlined"
                      id="identification"
                      name="identification"
                      value={formik.values.identification}
                      onBlur={checkValidity}
                      onChange={formik.handleChange}
                      onFocus={formik.handleBlur}
                      error={
                        formik.touched.identification &&
                        Boolean(formik.errors.identification)
                      }
                      helperText={
                        formik.touched.identification
                          ? formik.errors.identification
                          : "Please valid file types are: pdf, doc, jpg, jpeg, png"
                      }
                      sx={{
                        "& .MuiSvgIcon-root": {
                          color: "rgba(0, 0, 0, 0.74)",
                        },
                        "& .MuiInputBase-root": {
                          backgroundColor: "#FFF",
                        },
                        "& .MuiSelect-select": {
                          border: "1px solid rgba(0, 0, 0, 0.3)",
                        },
                      }}
                    >
                      <MenuItem
                        key={"International Passport"}
                        value={"International Passport"}
                      >
                        International Passport
                      </MenuItem>
                      <MenuItem
                        key={"Driver's License"}
                        value={"Driver's License"}
                      >
                        Driver's License
                      </MenuItem>
                      <MenuItem key={"Work ID"} value={"Work ID"}>
                        Work ID
                      </MenuItem>
                      <MenuItem
                        key={"National Identification Number (NIN)"}
                        value={"National Identification Number (NIN)"}
                      >
                        National Identification Number (NIN)
                      </MenuItem>
                    </TextField>
                  </div>
                </div>

                <div className="fieldRow">
                  <div className="fieldHld auto">
                    <div className="upload1">
                      <button onClick={() => refId.current.click()}>
                        Upload your ID +
                      </button>
                      <p class="support-text">
                        Required file format : Jpeg, PNG, PDF only
                      </p>
                      <input
                        className="fileInput"
                        ref={refId}
                        type="file"
                        onChange={onScrIdChange}
                        placeholder="Drag or Upload your ID +"
                      />
                      {/* {docImg && (<div><img src={docImg} alt="preview" width="100" hieght="100" /></div>)} */}
                      <div className="details-box mb-3">
                        {
                          // idValue &&  <div className="file-image icon"><FontAwesomeIcon icon={faFileAlt} /></div>
                          // srcId.map((data, index) => {
                          //     const { id, filename, filetype, fileimage, datetime, filesize } = data;
                          //     return (
                          //         <div className="file-atc-box" key={id}>
                          //             {
                          //                 filename.match(/.(jpg|jpeg|png|gif|svg)$/i) ?
                          //                     <div className="file-image"> <img src={fileimage} alt="" /></div> :
                          //                     <div className="file-image icon"><FontAwesomeIcon icon={faFileAlt} /></div>
                          //             }
                          //             <div className="file-detail">
                          //                 <span>{filename}</span>
                          //                 {/* <p></p>
                          //                 <p><span>Size : {filesize}</span><span className="ml-2">Modified Time : {datetime}</span></p> */}
                          //             </div>
                          //         </div>
                          //     )
                          // })
                        }

                        {idValue && (
                          <div className="file-atc-box">
                            {idValue.filename &&
                            idValue.filename.match(
                              /.(jpg|jpeg|png|gif|svg)$/i
                            ) ? (
                              <div className="file-image">
                                {" "}
                                <img src={idValue.fileimage} alt="" />
                              </div>
                            ) : (
                              <div className="file-image icon">
                                <FontAwesomeIcon icon={faFileAlt} />
                              </div>
                            )}
                            <div className="file-detail">
                              <span>{idValue.name && idValue.name}</span>
                              <p></p>
                              <p>
                                {/* <span>Size : {idValue.size && idValue.size}</span> */}
                                {/* <span className="ml-2">Modified Time : {idValue.datetime && idValue.datetime}</span> */}
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="fieldHld auto">
                    <div className="upload1">
                      <button onClick={() => refCv.current.click()}>
                        Upload your CV +
                      </button>
                      <p class="support-text">
                        Required file format : Word (doc), PDF only
                      </p>
                      <input
                        className="fileInput"
                        ref={refCv}
                        type="file"
                        onChange={onScrCvChange}
                        placeholder="Drag or Upload your ID +"
                      />
                      <div className="details-box mb-3">
                        {
                          // srcCv.map((data, index) => {
                          //     const { id, filename, filetype, fileimage, datetime, filesize } = data;
                          //     return (
                          //         <div className="file-atc-box" key={id}>
                          //             {
                          //                 filename.match(/.(jpg|jpeg|png|gif|svg)$/i) ?
                          //                     <div className="file-image"> <img src={fileimage} alt="" /></div> :
                          //                     <div className="file-image icon"><FontAwesomeIcon icon={faFileAlt} /></div>
                          //             }
                          //             <div className="file-detail">
                          //                 <span>{filename}</span>
                          //             </div>
                          //         </div>
                          //     )
                          // })
                        }
                        {cvValue && (
                          <div className="file-atc-box">
                            {cvValue.filename &&
                            cvValue.filename.match(
                              /.(jpg|jpeg|png|gif|svg)$/i
                            ) ? (
                              <div className="file-image">
                                {" "}
                                <img src={cvValue.fileimage} alt="" />
                              </div>
                            ) : (
                              <div className="file-image icon">
                                <FontAwesomeIcon icon={faFileAlt} />
                              </div>
                            )}
                            <div className="file-detail">
                              <span>{cvValue.name && cvValue.name}</span>
                              <p></p>
                              <p>
                                {/* <span>Size : {idValue.size && idValue.size}</span> */}
                                {/* <span className="ml-2">Modified Time : {idValue.datetime && idValue.datetime}</span> */}
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="fieldRow">
                  <div className="fieldHld">
                    <div className="label">Facebook Profile URL</div>
                    <TextField
                      //required
                      fullWidth
                      className="textField"
                      id="facebookUrl"
                      name="facebookUrl"
                      type="text"
                      //placeholder="First Name"
                      value={formik.values.facebookUrl}
                      onBlur={checkValidity}
                      onChange={formik.handleChange}
                      onFocus={formik.handleBlur}
                      variant="outlined"
                      error={
                        formik.touched.facebookUrl &&
                        Boolean(formik.errors.facebookUrl)
                      }
                      helperText={
                        formik.touched.facebookUrl && formik.errors.facebookUrl
                      }
                      // sx={{
                      //     '& .MuiInputBase-root.MuiInput-root:before': {
                      //         borderBottom: '1px solid rgba(255, 255, 255, 0.74)'
                      //     }
                      // }}
                    />
                  </div>
                </div>

                <div className="fieldRow">
                  <div className="fieldHld">
                    <div className="label">Instagram Profile URL</div>
                    <TextField
                      //required
                      fullWidth
                      className="textField"
                      id="instagramUrl"
                      name="instagramUrl"
                      type="text"
                      //placeholder="First Name"
                      value={formik.values.instagramUrl}
                      onBlur={checkValidity}
                      onChange={formik.handleChange}
                      onFocus={formik.handleBlur}
                      variant="outlined"
                      error={
                        formik.touched.instagramUrl &&
                        Boolean(formik.errors.instagramUrl)
                      }
                      helperText={
                        formik.touched.instagramUrl &&
                        formik.errors.instagramUrl
                      }
                      // sx={{
                      //     '& .MuiInputBase-root.MuiInput-root:before': {
                      //         borderBottom: '1px solid rgba(255, 255, 255, 0.74)'
                      //     }
                      // }}
                    />
                  </div>
                </div>

                <div className="fieldRow">
                  <div className="fieldHld">
                    <div className="label req">
                      Are you currently employed as a Teacher ?
                    </div>
                    <TextField
                      required
                      fullWidth
                      select
                      variant="outlined"
                      id="currentlyEmployed"
                      name="currentlyEmployed"
                      value={formik.values.currentlyEmployed}
                      onBlur={checkValidity}
                      onChange={formik.handleChange}
                      onFocus={formik.handleBlur}
                      error={
                        formik.touched.currentlyEmployed &&
                        Boolean(formik.errors.currentlyEmployed)
                      }
                      helperText={
                        formik.touched.currentlyEmployed &&
                        formik.errors.currentlyEmployed
                      }
                      sx={{
                        "& .MuiSvgIcon-root": {
                          color: "rgba(0, 0, 0, 0.74)",
                        },
                        "& .MuiInputBase-root": {
                          backgroundColor: "#FFF",
                        },
                        "& .MuiSelect-select": {
                          border: "1px solid rgba(0, 0, 0, 0.3)",
                        },
                      }}
                    >
                      <MenuItem key={"Yes"} value={"Yes"}>
                        Yes
                      </MenuItem>
                      <MenuItem key={"No"} value={"No"}>
                        No
                      </MenuItem>
                    </TextField>
                  </div>
                </div>

                <div className="fieldRow">
                  <div className="fieldHld">
                    <div className="label req">
                      Years Of Teaching Exprerience
                    </div>
                    <TextField
                      required
                      fullWidth
                      select
                      variant="outlined"
                      id="teachingExperience"
                      name="teachingExperience"
                      value={formik.values.teachingExperience}
                      onBlur={checkValidity}
                      onChange={formik.handleChange}
                      onFocus={formik.handleBlur}
                      error={
                        formik.touched.teachingExperience &&
                        Boolean(formik.errors.teachingExperience)
                      }
                      helperText={
                        formik.touched.teachingExperience &&
                        formik.errors.teachingExperience
                      }
                      sx={{
                        "& .MuiSvgIcon-root": {
                          color: "rgba(0, 0, 0, 0.74)",
                        },
                        "& .MuiInputBase-root": {
                          backgroundColor: "#FFF",
                        },
                        "& .MuiSelect-select": {
                          border: "1px solid rgba(0, 0, 0, 0.3)",
                        },
                      }}
                    >
                      <MenuItem key={"1-3 Years"} value={"1-3 Years"}>
                        1-3 Years
                      </MenuItem>
                      <MenuItem key={"4-6 Years"} value={"4-6 Years"}>
                        4-6 Years
                      </MenuItem>
                      <MenuItem key={"7-9 Years"} value={"7-9 Years"}>
                        7-9 Years
                      </MenuItem>
                      <MenuItem
                        key={"10 Years & Above"}
                        value={"10 Years & Above"}
                      >
                        10 Years & Above
                      </MenuItem>
                    </TextField>
                  </div>
                </div>

                <div className="fieldRow">
                  <div className="fieldHld">
                    <div className="label req">
                      Do you have a desktop or a laptop ?
                    </div>
                    <TextField
                      required
                      fullWidth
                      select
                      variant="outlined"
                      id="personalLaptop"
                      name="personalLaptop"
                      value={formik.values.personalLaptop}
                      onBlur={checkValidity}
                      onChange={formik.handleChange}
                      onFocus={formik.handleBlur}
                      error={
                        formik.touched.personalLaptop &&
                        Boolean(formik.errors.personalLaptop)
                      }
                      helperText={
                        formik.touched.personalLaptop &&
                        formik.errors.personalLaptop
                      }
                      sx={{
                        "& .MuiSvgIcon-root": {
                          color: "rgba(0, 0, 0, 0.74)",
                        },
                        "& .MuiInputBase-root": {
                          backgroundColor: "#FFF",
                        },
                        "& .MuiSelect-select": {
                          border: "1px solid rgba(0, 0, 0, 0.3)",
                        },
                      }}
                    >
                      <MenuItem key={"Yes"} value={"Yes"}>
                        Yes
                      </MenuItem>
                      <MenuItem key={"No"} value={"No"}>
                        No
                      </MenuItem>
                    </TextField>
                  </div>
                </div>

                <div className="fieldRow">
                  <div className="fieldHld">
                    <div className="label req">
                      Do you have any home tutoring experience ?
                    </div>
                    <TextField
                      required
                      fullWidth
                      select
                      variant="outlined"
                      id="homeTutoringExperience"
                      name="homeTutoringExperience"
                      value={formik.values.homeTutoringExperience}
                      onBlur={checkValidity}
                      onChange={formik.handleChange}
                      onFocus={formik.handleBlur}
                      error={
                        formik.touched.homeTutoringExperience &&
                        Boolean(formik.errors.homeTutoringExperience)
                      }
                      helperText={
                        formik.touched.homeTutoringExperience &&
                        formik.errors.homeTutoringExperience
                      }
                      sx={{
                        "& .MuiSvgIcon-root": {
                          color: "rgba(0, 0, 0, 0.74)",
                        },
                        "& .MuiInputBase-root": {
                          backgroundColor: "#FFF",
                        },
                        "& .MuiSelect-select": {
                          border: "1px solid rgba(0, 0, 0, 0.3)",
                        },
                      }}
                    >
                      <MenuItem key={"Yes"} value={"Yes"}>
                        Yes
                      </MenuItem>
                      <MenuItem key={"No"} value={"No"}>
                        No
                      </MenuItem>
                    </TextField>
                  </div>
                </div>

                <div className="fieldRow flex-column refWrap">
                  <div className="seperate">
                    <div className="refHdr">Referee 1</div>
                    <div className="fieldRow">
                      <div className="fieldHld">
                        <div className="label req">First Name</div>
                        <TextField
                          required
                          fullWidth
                          className="textField"
                          id="referee1FirstName"
                          name="referee1FirstName"
                          type="text"
                          placeholder="Referee First Name"
                          value={formik.values.referee1FirstName}
                          onBlur={checkValidity}
                          onChange={formik.handleChange}
                          onFocus={formik.handleBlur}
                          variant="outlined"
                          error={
                            formik.touched.referee1FirstName &&
                            formik.touched.referee1FirstName == ""
                          }
                          helperText={
                            formik.touched.referee1FirstName &&
                            formik.touched.referee1FirstName == ""
                          }
                        />
                      </div>
                      <div className="fieldHld">
                        <div className="label req">Last Name</div>
                        <TextField
                          required
                          fullWidth
                          className="textField"
                          id="referee1LastName"
                          name="referee1LastName"
                          type="text"
                          placeholder="Referee Last Name"
                          value={formik.values.referee1LastName}
                          onBlur={checkValidity}
                          onChange={formik.handleChange}
                          onFocus={formik.handleBlur}
                          variant="outlined"
                          error={
                            formik.touched.referee2LastName &&
                            formik.touched.referee2LastName == ""
                          }
                          helperText={
                            formik.touched.referee2LastName &&
                            formik.touched.referee2LastName == ""
                          }
                        />
                      </div>
                    </div>
                    <div className="fieldRow">
                      <div className="fieldHld">
                        <div className="label req">Address</div>
                        <TextField
                          required
                          fullWidth
                          className="textField"
                          id="referee1Address"
                          name="referee1Address"
                          type="text"
                          placeholder="Referee Address"
                          value={formik.values.referee1Address}
                          onBlur={checkValidity}
                          onChange={formik.handleChange}
                          onFocus={formik.handleBlur}
                          variant="outlined"
                          error={
                            formik.touched.referee1Address &&
                            formik.touched.referee1Address == ""
                          }
                          helperText={
                            formik.touched.referee1Address &&
                            formik.touched.referee1Address == ""
                          }
                        />
                      </div>
                    </div>
                    <div className="fieldRow">
                      <div className="fieldHld">
                        <div className="label req">Email</div>
                        <TextField
                          required
                          fullWidth
                          className="textField"
                          id="referee1Email"
                          name="referee1Email"
                          type="text"
                          placeholder="Referee Email Address"
                          value={formik.values.referee1Email}
                          onBlur={checkValidity}
                          onChange={formik.handleChange}
                          onFocus={formik.handleBlur}
                          variant="outlined"
                          error={
                            formik.touched.referee1Email &&
                            formik.touched.referee1Email == ""
                          }
                          helperText={
                            formik.touched.referee1Email &&
                            formik.touched.referee1Email == ""
                          }
                        />
                      </div>
                      <div className="fieldHld">
                        <div className="label req">Phone Number</div>
                        <PhoneInput
                          country={"ng"}
                          value={refPhone1}
                          onChange={(refPhone1) => setRefPhone1(refPhone1)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="seperate">
                    <div className="refHdr">Referee 2</div>
                    <div className="fieldRow">
                      <div className="fieldHld">
                        <div className="label req">First Name</div>
                        <TextField
                          required
                          fullWidth
                          className="textField"
                          id="referee2FirstName"
                          name="referee2FirstName"
                          type="text"
                          placeholder="Referee First Name"
                          value={formik.values.referee2FirstName}
                          onBlur={checkValidity}
                          onChange={formik.handleChange}
                          onFocus={formik.handleBlur}
                          variant="outlined"
                          error={
                            formik.touched.referee2FirstName &&
                            formik.touched.referee2FirstName == ""
                          }
                          helperText={
                            formik.touched.referee2FirstName &&
                            formik.touched.referee2FirstName == ""
                          }
                        />
                      </div>
                      <div className="fieldHld">
                        <div className="label req">Last Name</div>
                        <TextField
                          required
                          fullWidth
                          className="textField"
                          id="referee2LastName"
                          name="referee2LastName"
                          type="text"
                          placeholder="Referee Last Name"
                          value={formik.values.referee2LastName}
                          onBlur={checkValidity}
                          onChange={formik.handleChange}
                          onFocus={formik.handleBlur}
                          variant="outlined"
                          error={
                            formik.touched.referee2LastName &&
                            formik.touched.referee2LastName == ""
                          }
                          helperText={
                            formik.touched.referee2LastName &&
                            formik.touched.referee2LastName == ""
                          }
                        />
                      </div>
                    </div>
                    <div className="fieldRow">
                      <div className="fieldHld">
                        <div className="label req">Address</div>
                        <TextField
                          required
                          fullWidth
                          className="textField"
                          id="referee2Address"
                          name="referee2Address"
                          type="text"
                          placeholder="Referee Address"
                          value={formik.values.referee2Address}
                          onBlur={checkValidity}
                          onChange={formik.handleChange}
                          onFocus={formik.handleBlur}
                          variant="outlined"
                          error={
                            formik.touched.referee2Address &&
                            formik.touched.referee2Address == ""
                          }
                          helperText={
                            formik.touched.referee2Address &&
                            formik.touched.referee2Address == ""
                          }
                        />
                      </div>
                    </div>
                    <div className="fieldRow">
                      <div className="fieldHld">
                        <div className="label req">Email</div>
                        <TextField
                          required
                          fullWidth
                          className="textField"
                          id="referee2Email"
                          name="referee2Email"
                          type="text"
                          placeholder="Referee Email Address"
                          value={formik.values.referee2Email}
                          onBlur={checkValidity}
                          onChange={formik.handleChange}
                          onFocus={formik.handleBlur}
                          variant="outlined"
                          error={
                            formik.touched.referee2Email &&
                            formik.touched.referee2Email == ""
                          }
                          helperText={
                            formik.touched.referee2Email &&
                            formik.touched.referee2Email == ""
                          }
                        />
                      </div>
                      <div className="fieldHld">
                        <div className="label req">Phone Number</div>
                        <PhoneInput
                          country={"ng"}
                          value={refPhone2}
                          onChange={(refPhone2) => setRefPhone2(refPhone2)}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="fieldRow flex-column">
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Tick here if you agree with the terms and condition of our tutor contract agreement"
                  />
                  <div className="contract mt-lg-1 mt-1">
                    <a
                      href="https://drive.google.com/file/d/1DzbnyMm28JNk_tK4CgHxjWbrfika8zuw/view?usp=sharing"
                      target="_blank"
                    >
                      Download Subject Tutor Contract
                    </a>
                  </div>
                </div>

                <div className="fieldRow">
                  <Button
                    onClick={submitForm}
                    className="hasIcon mainCta extra"
                    fullWidth
                    type="submit"
                    variant="contained"
                   disabled={loader} // Disable the button while loading

                  >
                    
                 {loader ? <CircularProgress size={24} color='primary' /> : 'Submit & Complete Registration'}

                  </Button>
                  <ToastContainer
                  position="top-center"
                  autoClose={5000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                  theme="light"
                />
                </div>
              </form>
            </FormikProvider>

            <div className="imgHld">
              <img src={src} />
            </div>

            <span onClick={() => ref.current.click()} className="floatImg">
              <img src={Camera} alt="" />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubjectTutorForm2Page;
