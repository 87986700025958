import * as React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import CodingTutorRegForm2Page from '../components/CodingTutorRegForm2Page';

const CodingTutorRegForm2 = () => {   

  return (
    
    <div className="page-container">
        <Header className='header bkg-green-light' />
        <CodingTutorRegForm2Page />
        <Footer />
    </div>
    
  );
};

export default CodingTutorRegForm2;