import * as React from 'react';
import Accordion from './accordion/Accordion';
import Testimonials from './carousel/Carousel';
import UnstyledTabsCustomized from './tabs/Tabs';

import { useNavigate } from 'react-router-dom';


import { Button, Modal, Box, FormControlLabel, Checkbox } from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

import Frag1 from "../assets/img/home/frag1.svg";
import Frag2 from "../assets/img/home/frag2.svg";
import Frag3 from "../assets/img/home/frag3.svg";
import Frag4 from "../assets/img/home/frag4.svg";
import Frag5 from "../assets/img/home/frag5.svg";
import Frag6 from "../assets/img/home/frag6.svg";
import Frag7 from "../assets/img/home/frag7.svg";

import ParentsIcon from "../assets/img/home/icon-parents.svg";
import SchoolsIcon from "../assets/img/home/schools-icon.svg";
import TeachersIcon from "../assets/img/home/teachers-icon.svg";
import Image1 from "../assets/img/home/image-1.png";
import Image2 from "../assets/img/home/image-2.png";
import Image3 from "../assets/img/home/image-3.png";
import Image4 from "../assets/img/home/image-4.png";
import Image5 from "../assets/img/home/image-5.png";
import Image6 from "../assets/img/home/image-6.png";
import Image7 from "../assets/img/home/image-7.png";
import Image8 from "../assets/img/home/image-8.png";
import Image9 from "../assets/img/home/image-9.png";

import Work1 from "../assets/img/brand/queensland.svg";
import Work2 from "../assets/img/brand/smgs.svg";
import Work3 from "../assets/img/brand/cinammon.svg";
import Work4 from "../assets/img/brand/sbs.svg";
import Work5 from "../assets/img/home/wcs-logo.png";

import Mention1 from "../assets/img/brand/tony-elumelu.svg";
import Mention2 from "../assets/img/brand/british-council.svg";
import Mention3 from "../assets/img/brand/digital-startup.png";
import Mention4 from "../assets/img/brand/injini.svg";

import Support1 from "../assets/img/brand/tony-elumelu.svg";
import Support2 from "../assets/img/brand/wise-guys.svg";
import Support3 from "../assets/img/brand/aidi.svg";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 650,
  //border: '1px solid #CFCFCF',
  //boxShadow: 24,
  p: 4,
};

const Landing = () => {

  //Modal Use State Function
  const [modalOpen, setModalOpen] = React.useState(false);
  const handleOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);


  //Set Subject Coding Tutor Value
  const [subjectChecked, setSubjectChecked] = React.useState(false);
  const handleSubjectChange = (event) => {
    setSubjectChecked(event.target.checked);
    setCodingChecked(!event.target.checked);
  };

  //Set Coding Coding Tutor Value
  const [codingChecked, setCodingChecked] = React.useState(false);
  const handleCodingChange = (event) => {
    setCodingChecked(event.target.checked);
    setSubjectChecked(!event.target.checked);
  };

  const navigate = useNavigate();
  const handleSelection = () => {
    if(subjectChecked) {
      navigate('/subject-tutor-registration-form');
    }
    else if(codingChecked) {
      navigate('/coding-tutor-registration-form');
    }
  }

  return (
    
    <div className="page-content">
        
      <div className='heroSection pgy bkg-pale-yellow'>
        <div className='row justify-content-center align-items-center text-center'>
          <div className='col-lg-7 col-10'>
            <div className='hdr xl dark desktop slideTargets'>Bring out the genius in your
              <span>Kids</span>
              <span>Teaching</span>
              <span>Schools</span>
            </div>
            <div className='hdr xl dark mobile slideTargets'>
              Bring out the genius<div className='inner ps-4'>
              in your
              <span>Kids</span>
              <span>Teaching</span>
              <span>Schools</span>
            </div>
            </div>
          </div>
          <div className='col-lg-6 pt-2 pt-lg-3'>
            <div className='text md'>Nigenius is a smart web application that provides teachers with quality resources and connects them to learners for tutoring.</div>
          </div>
          <div className='col-lg-7 pt-4 pt-lg-5'>
            <Button 
              className="hasIcon mainCta"
              fullWidth 
              type="button"
              variant="contained"
              onClick={e => {
                let target = document.getElementById("tutor");
                e.preventDefault();
                target && target.scrollIntoView({ behavior: "smooth"});
              }}
              >Let's get you started <FontAwesomeIcon icon={faArrowRight} /></Button>
          </div>
        </div>

        <div className='row box-cover justify-content-center align-items-center text-center'>
          <div className='box-card md clr-blue'>
            <img src={Image3} alt="" />
          </div>
          <div className='box-card lg clr-pale-orange'>
            <img src={Image4} alt="" />
          </div>
          <div className='box-card md clr-pink'>
            <img src={Image5} alt="" />
          </div>
          <div className='box-card lg clr-purple'>
            <img src={Image6} alt="" />
          </div>
          <div className='box-card md clr-yellow'>
            <img src={Image7} alt="" />
          </div>
          <div className='box-card lg clr-dark-orange'>
            <img src={Image8} alt="" />
          </div>
        </div>

        <div className='fragments'>
          <img src={Frag1} alt="" />
          <img src={Frag2} alt="" />
          <img src={Frag6} alt="" />
        </div>

      </div>

      <div className='solutionsSection bkg-alice pgx pgy' id="tutor">
        <div className='row pb-lg-4 justify-content-center align-items-center'>
          <div className='col-lg-8 text-center'>
            <div className='sec-title clr-orange mb-4 mb-lg-4'>Our Solutions</div>
            <div className='hdr lg dark mb-lg-2'>Here’s how we’re transforming Education</div>
          </div>
          <div className='col-lg-11 solutionTarget d-flex justify-content-between mt-4'>
              <div className='targetHld d-flex align-items-center'>
                <img src={TeachersIcon} alt="" />
                <div className='details'>
                  <div className='hdr sm dark mb-lg-1'>For Teachers</div>
                  <div className='text sm'>Quality teaching resources</div>
                </div>
              </div>

              <div className='targetHld d-flex align-items-center'>
                <img src={ParentsIcon} alt="" />
                <div className='details'>
                  <div className='hdr sm dark mb-lg-1'>For Parents/Students</div>
                  <div className='text sm'>Qualified and Vetted Tutors</div>
                </div>
              </div>

              <div className='targetHld d-flex align-items-center'>
                <img src={SchoolsIcon} alt="" />
                <div className='details'>
                  <div className='hdr sm dark mb-lg-1'>For Schools</div>
                  <div className='text sm'>STEAM Tutoring & a State-of-the-Art  School Management Software.</div>
                </div>
              </div>
          </div>
        </div>

        <div className='row mt-5 pt-3 pt-lg-4 mt-lg-5'>
          <UnstyledTabsCustomized/>
        </div>

        <div className='fragments'>
          <img src={Frag3} alt="" />
          <img src={Frag6} alt="" />
          <img src={Frag4} alt="" />
          <img src={Frag7} alt="" />
          <img src={Frag7} alt="" />
        </div>
      </div>

      <div className='schoolsWorkSection justify-content-between align-items-center pgx pgy bkg-pale-blue'>
        <div className='row justify-content-center'>
          <div className='sec-title d-inline-block w-auto clr-purple mb-5 mb-lg-5 text-center'>Schools we work with</div>
        </div>
        <div className='logoWrap'>
          <div className='logoHld'>
            <img src={Work1} alt="" />
          </div>
          <div className='logoHld'>
            <img src={Work2} alt="" />
          </div>
          <div className='logoHld'>
            <img src={Work3} alt="" />
          </div>
          <div className='logoHld'>
            <img src={Work4} alt="" />
          </div>
          <div className='logoHld'>
            <img src={Work5} alt="" />
          </div>

        </div>
      </div>

      <div className='testimonialSection bkg-light-grey pgx rd pgy'>
        <div className='row justify-content-center'>
          <div className='sec-title d-inline-block w-auto clr-purple mb-4 mb-lg-5 text-center'>Testimonials</div>
        </div>
        <Testimonials/>
      </div>

      <div className='mentionsSection justify-content-between align-items-center pgx pgy bkg-light-pink'>
        <div className='row justify-content-center'>
          <div className='sec-title d-inline-block w-auto clr-primary mb-4 mb-lg-5 text-center'>Our Media Mentions</div>
        </div>
        <div className='logoWrap'>
          <div className='logoHld'>
            <a href='https://businessday.ng/interview/enterpreneur/article/the-african-smes-story-digital-skills-for-the-modern-entrepreneur/?fbclid=IwAR3qCD8bXZbd8mwgnF_lwHNLbrSksw6dBfUtdHMKgBnqwJTZZP45yZI59PA'><img src={Mention1} alt="" /></a>
          </div>
          <div className='logoHld'>
            <a href='https://www.britishcouncil.org/work/partner/ideas-change-lives'><img src={Mention2} alt="" /></a>
          </div>
          <div className='logoHld'>
            <img src={Mention3} alt="" />
          </div>
          <div className='logoHld'>
            <img src={Mention4} alt="" />
          </div>

        </div>
      </div>

      <div className='tutorSignup bkg-green-light pgx pgy'>

        <div className='row justify-content-between align-items-center'>
          <div className='col-lg-5'>
            <div className='sec-title clr-cyan mb-4 mb-lg-4'>Join Us</div>
            <div className='hdr lg dark mb-2 mb-lg-2'>Sign up as a tutor</div>
            <div className='text md'>Signing up as a tutor on NIgenius gives you the opportunity to be part of the ecosystem, gain access to world class teaching resources, tutoring opportunities and many more.</div>
            <Button 
              className="mt-lg-4 mt-4 mainCta hasIcon"
              fullWidth 
              type="button"
              variant="contained"
              onClick={handleOpen}
              >Become a Nigenius tutor <FontAwesomeIcon icon={faArrowRight} /></Button>

            <Modal
              open={modalOpen}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <div onClick={handleClose} className='closeModal'>
                  <div className='closeBtn'></div>
                </div>
                <div className='modalDetails'>
                  <div className='hdr lg dark'>What type of tutor are you?</div>
                  <div className='modalAction' id="modal-modal-description">
                    <FormControlLabel control={<Checkbox checked={subjectChecked} onChange={handleSubjectChange}  />} label="Subject Tutors" />
                    <FormControlLabel control={<Checkbox checked={codingChecked} onChange={handleCodingChange} />}  label="Coding Tutors" />
                  </div>
                  <Button 
                    className="hasIcon mainCta"
                    fullWidth 
                    type="button"
                    variant="contained"
                    onClick={handleSelection}
                  >Continue to Tutor Registration <FontAwesomeIcon icon={faArrowRight} /></Button>
                </div>
              </Box>
            </Modal>
          </div>

          <div className='col-lg-5 hasBlur mt-5 mt-lg-0 d-flex justify-content-between align-items-center'>
            <div className='box-card md clr-pink'>
              <img src={Image1} alt="" />
            </div>
            <div className='box-card lg clr-purple'>
              <img src={Image2} alt="" />
            </div>
          </div>
        </div>

        <div className='fragments'>
          <img src={Frag6} alt="" />
          <img src={Frag5} alt="" />
          <img src={Frag5} alt="" />
        </div>

      </div>

      <div className='supportedSection justify-content-between align-items-center pgx pgy bkg-pale-purple'>
        <div className='row justify-content-center'>
          <div className='sec-title d-inline-block w-auto clr-purple mb-5 mb-lg-5 text-center'>Proudly Supported By</div>
        </div>
        <div className='logoWrap'>
          <div className='logoHld'>
            <img src={Support1} alt="" />
          </div>
          <div className='logoHld'>
            <img src={Support2} alt="" />
          </div>
          <div className='logoHld'>
            <img src={Support3} alt="" />
          </div>

        </div>
      </div>

      <div className='faqSection justify-content-between align-items-center pgx pgy bkg-pale-yellow'>
        <div className='row justify-content-center'>
          <div className='sec-title d-inline-block w-auto clr-purple mb-3 mb-lg-5 text-center'>Frequently Asked Questions</div>
        </div>
        <div className='row justify-content-between align-items-center'>
          <div className='hasBlur col-lg-6'>
            <Accordion />
          </div>
          <div className='col-lg-5 mt-4 mt-lg-0 d-flex justify-content-end align-items-center'>
            <div className='box-card hasBlur lg clr-blue'>
              <img src={Image9} alt="" />
            </div>
          </div>
        </div>
      </div>
        

    </div>
    
  );
};

export default Landing;